import React, { ReactElement } from "react";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  C19ContactIcon,
  CircleQuestionSolid,
  FeverIcon,
  HomeIsolationIcon,
  LossOfSmellIcon,
  LungsIcon,
  RunnyNoseIcon,
  TravelContactIcon,
  TravelIcon,
  UserNurseIcon,
} from "../../Icons/DoHIcons";
import RecordVoiceOverSharpIcon from "@material-ui/icons/RecordVoiceOverSharp";
import { SubQuestion } from "../../../common/client";
import {Accordion, AccordionSummary,AccordionDetails}  from "@material-ui/core";


const SubQuestionListIcon = withStyles((theme) => ({
  root: {
    marginRight: "18px",
    "& :hover": {
      color: theme.palette.primary.main,
      cursor: "default",
    },
  },
}))(ListItemIcon);
const SubQuestionNestedListIcon = withStyles((theme) => ({
  root: {
    marginRight: "18px",
    fontSize: "2.5rem!important",
    "& :hover": {
      color: theme.palette.primary.main,
      cursor: "default",
    },
  },
}))(ListItemIcon);
const QuestionSubQuestionListIcon = withStyles((theme) => ({
  root: {
    marginRight: "18px",
    "& :hover": {
      color: theme.palette.secondary.main,
      cursor: "default",
    },
  },
}))(ListItemIcon);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.light,
      maxWidth: "unset",
      maxHeight: "unset",
      fontSize: "2.5rem!important",
    },
  })
);

export const SubQuestionIcon = (icon: any): ReactElement => {
  switch (icon) {
    case "breath":
      return <LungsIcon />;
    case "fever":
      return <FeverIcon />;
    case "smell":
      return <LossOfSmellIcon />;
    case "nose":
      return <RunnyNoseIcon />;
    case "contact":
      return <C19ContactIcon />;
    case "travel":
      return <TravelIcon />;
    case "travelContact":
      return <TravelContactIcon />;
    case "homeIsolation":
      return <HomeIsolationIcon />;
    case "interpreter":
      return <RecordVoiceOverSharpIcon />;
    case "UserNurseIcon":
      return <UserNurseIcon />;
    default:
      return <CircleQuestionSolid />;
  }
};

export const QuestionItemSubQuestion = (props: any) => {
  return props.subQuestions.map((sub: any, i: number) => (
    <div key={i}>
      <ListItem>
        <SubQuestionListIcon>{SubQuestionIcon(sub.icon)}</SubQuestionListIcon>
        <ListItemText
          primary={<div dangerouslySetInnerHTML={{ __html: sub.text }} />}
        />
      </ListItem>
      <Divider />
    </div>
  ));
};

export const QuestionItemNestedSubQuestion = (props: any) => {
  return props.subQuestions.map((sub: any, i: number) => (
    <div key={i}>
      <ListItem>
        <SubQuestionNestedListIcon>
          {SubQuestionIcon(sub.icon)}
        </SubQuestionNestedListIcon>
        <ListItemText
          primary={<div dangerouslySetInnerHTML={{ __html: sub.text }} />}
        />
      </ListItem>
      <Divider />
    </div>
  ));
};

export interface SubQuestionDetails {
  icon?: string;
  text: string;
}

export interface QuestionSubQuestionProps {
  subQuestions: SubQuestionDetails[];
  required?: boolean;
  helpText?: string;
}

export const QuestionSubQuestion = (props: QuestionSubQuestionProps) => {
  const theme = useTheme();

  theme.overrides = {
    ...theme.overrides,
    MuiListItem: {
      //...theme.MuiListItem,
      root: {
        backgroundColor: "#e9ebed", //theme.palette.secondary.main,
      },
      gutters: {
        padding: "0.5em 0 0.5em 1em",
      },
    },
    MuiListItemText: {
      root: {
        color: theme.palette.primary.contrastText,
      },
    },
    MuiTypography: {
      body1: {
        color: theme.palette.primary.light,
      },
    },
  };
  const classes = useStyles();

  const StyledIcon = (subQuestionIcon: ReactElement): JSX.Element | null => {
    if (subQuestionIcon)
      return React.cloneElement(subQuestionIcon as JSX.Element, {
        className: classes.icon,
      });
    return null;
  };
  return (
    <ThemeProvider theme={theme}>
      {props.subQuestions.map((sub: SubQuestionDetails, i: number) => (
        <div key={i}>
          <Divider />
          <ListItem>
            <QuestionSubQuestionListIcon>
              {StyledIcon(SubQuestionIcon(sub.icon))}
            </QuestionSubQuestionListIcon>
            <ListItemText
              primary={
                <>
                {props.helpText ? (
                  <Accordion>
                    <AccordionSummary>
                    {sub.text}
                    {props.required && (
                      <span style={{ color: theme.DoH.color.fail }}> *</span>
                    )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {props.helpText}
                    </AccordionDetails>
                  </Accordion>
                    ):
                    (
                        <>
                          {sub.text}
                          {props.required && (
                              <span style={{ color: theme.DoH.color.fail }}> *</span>
                          )}
                        </>
                    )}
                </>
              }
            />
          </ListItem>
          <Divider />
        </div>
      ))}
    </ThemeProvider>
  );
};
