import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IQuestion } from "../../../models/Question";
import { QuestionItemSlider } from "./QuestionItemSlider";
import { AuthorisationDialog } from "../dialogs/AuthorisationDeclaration";
import { QuestionnaireForwardingViewModel, QuestionValidationError, SettingsViewModel } from "../../../common/client";
import { QuestionnaireErrors } from "../Questionnaire/QuestionnaireErrors";
import {
  findNestedQuestionControl,
  lookupTopLevelQuestion,
  QuestionnaireControls,
  visitQuestions,
} from "../Questionnaire";
import { QuestionItemStepper } from "./QuestionItemStepper";
import { control } from "@react-typed-forms/core";
import { HelpMode, HelpModeContext } from "./HelpMode";
import { Box } from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";

interface IQuestionnaireProps {
  questions: IQuestion[];
  validation: QuestionValidationError[] | undefined;
  onSaveResponse: (question: IQuestion) => Promise<boolean>;
  onQuestionnaireFinalised: () => void;
  maxWidth: string;
  style?: any;
  queueId: string;
  controls: QuestionnaireControls;
  overrideSubmit?: (lastIndex: IQuestion) => Promise<boolean>;
  currentPageRef?: MutableRefObject<IQuestion | undefined>;
  stepper?: boolean;
  showHelpTextToggle: boolean;
  helpTextExpansion: boolean;
  settings?: SettingsViewModel[];
  questionnaireInfo?: QuestionnaireForwardingViewModel;
}

export interface QuestionItemNavProps {
  questions: IQuestion[];
  maxWidth?: string;
  style?: any;
  queueId: string;
  settings?: SettingsViewModel[];
  questionnaireInfo?: QuestionnaireForwardingViewModel;
  controls: QuestionnaireControls;
  currentQuestion: IQuestion;
  transitionForward: boolean;
  navigate: (index: IQuestion | undefined, forward: boolean) => void;
  invalidQuestions?: string[];
}

export const Questionnaire = ({
  currentPageRef,
  stepper,
  showHelpTextToggle,
  queueId,
  settings,
  questionnaireInfo,
  helpTextExpansion,
  controls,
  questions,
  ...props
}: IQuestionnaireProps) => {
  const [authOpen, setAuthOpen] = React.useState(false);
  const [[currentQuestion, transitionForward], setCurrentIndex] = useState([
    questions[questionnaireInfo ? 3 : 0],
    true,
  ]);

  const NavComponent = stepper ? QuestionItemStepper : QuestionItemSlider;
  const [helpModeCtrl] = useState(control(helpTextExpansion));
  const topOfPage = useRef<HTMLDivElement | null>(null);

  if (currentPageRef) {
    currentPageRef.current = currentQuestion;
  }

  const handleAuthClose = (newValue?: string) => {
    setAuthOpen(false);
    if (newValue === "submitted") {
      props.onQuestionnaireFinalised();
    }
  };

  const invalidQuestions = useMemo(() => {
    if (props.validation) {
      return Object.keys(
        props.validation.reduce((tlqs, err) => {
          const tlq = findTopLevelQuestion(err.questionId);
          if (tlq) {
            tlqs[tlq.id] = true;
          }
          return tlqs;
        }, {} as { [key: string]: boolean })
      );
    }
    return [];
  }, [props.validation, questions]);

  useEffect(() => {
    if (props.validation) {
      props.validation.forEach((err) => {
        const q = findTopLevelQuestion(err.questionId);
        if (q) {
          const tlc = controls.fields[q.id];
          if (q.id !== err.questionId) {
            const errControl = findNestedQuestionControl(
              tlc,
              q,
              err.questionId,
              err.repeaterPath
            );
            if (errControl) {
              errControl.setError(err.message);
            }
          } else {
            console.log("Found a top level");
          }
        }
      });
    }
  }, [props.validation, questions]);

  useEffect(() => {
    if (topOfPage.current && topOfPage) {
      topOfPage.current.scrollIntoView();
    }
    if (window.innerWidth < 800) {
      window.scrollTo(0, 0);
    }
  }, [currentQuestion]);

  return (
    <HelpModeContext.Provider value={helpModeCtrl}>
      <div ref={topOfPage} style={{ marginTop: "32px" }} />
      {/*{props.validation && (*/}
      {/*  <QuestionnaireErrors*/}
      {/*    errors={props.validation}*/}
      {/*    onClickError={(q) => {*/}
      {/*      const topLevelQuestion = findTopLevelQuestion(q);*/}
      {/*      if (topLevelQuestion) {*/}
      {/*        setCurrentIndex([*/}
      {/*          props.questions.indexOf(topLevelQuestion),*/}
      {/*          true,*/}
      {/*        ]);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {showHelpTextToggle && (
        <Box m={2}>
          <HelpMode />
        </Box>
      )}

      <NavComponent
        style={{}}
        questions={questions}
        controls={controls}
        queueId={queueId}
        maxWidth={props.maxWidth}
        currentQuestion={currentQuestion}
        transitionForward={transitionForward}
        navigate={navigate}
        settings={settings}
        questionnaireInfo={questionnaireInfo}
        invalidQuestions={invalidQuestions}
      />
      <AuthorisationDialog open={authOpen} onClose={handleAuthClose} />
    </HelpModeContext.Provider>
  );

  async function navigate(
    nextQuestion: IQuestion | undefined,
    forward: boolean
  ) {
    const isResponseValid = await props.onSaveResponse(currentQuestion);
    if (isResponseValid) {
      if (!nextQuestion) {
        if (props.overrideSubmit) {
          props.overrideSubmit(currentQuestion).then(setAuthOpen);
        } else {
          setAuthOpen(true);
        }
      } else {
        setCurrentIndex([nextQuestion, forward]);
      }
    }
  }

  function findTopLevelQuestion(q: string) {
    return lookupTopLevelQuestion(questions, q);
  }
};
