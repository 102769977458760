import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { getSiteLocations, setPrimaryLocation } from "../../../data/api";
import { Context } from "../../../state/Store";
import { OutcomeIdentifier, QueueChoiceVm } from "../../../common/client";
//import { ValidatedForm } from "../components/core/forms/ValidatedForm";
//import { Simulate } from "react-dom/test-utils";
//import { OutcomeIdentifier, QueueChoiceVm } from "../common/client";
//import { Questionnaire } from "../components/core/QuestionSlider/Questionnaire";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      // Thomas changed teh 60 to 100
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        width: "64px",
        height: "64px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
    image: {},
    selectField: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    selectFieldOne: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    selectFieldtwo: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    error: {
      color: "red",
      paddingLeft: "14px",
      fontSize: "0.9rem!important",
      letterSpacing: "0.0075rem",
    },
  })
);

function QuestionItemDropdown() {
    return (
        <div>
            
        </div>
    )
}


export const SiteBuildingDropdown = () => {
    const state = useContext(Context);
    const BLANKID = "00000000-0000-0000-0000-000000000000";
    const [site, setSite] = useState<string>("");
    const [allSites, setAllSites] = useState<any>([]);
    const [building, setBuilding] = useState<string>("");
    const [allLocations, setAllLocations] = useState<any>([]);
    const classes = useStyles();
    const [allBuildings, setAllBuildings] = useState<any[]>([])
    const [formErrors, setFormErrors] = useState({
        building: {
          valid: true,
          errorMessage: "",
        },
        site: {
          valid: true,
          errorMessage: "",
        },
      });

      const isFormValid = (): boolean => {
        let valid = true;
        let errors = { ...formErrors };
    
        if (building.length === 0) {
          errors.building.valid = false;
          errors.building.errorMessage = "Building is required";
          valid = false;
        } else {
          errors.building.valid = true;
          errors.building.errorMessage = "";
        }
    
        if (site.length === 0) {
          errors.site.valid = false;
          errors.site.errorMessage = "Site is required";
          valid = false;
        } else {
          errors.site.valid = true;
          errors.site.errorMessage = "";
        }
    
        setFormErrors(errors);
        return valid;
      };


      const handleSiteChange = (event: any) => {
        setSite(event.target.value as string);
        var buildings = allLocations.filter(
          (item: any) => item.outcomeParentId === (event.target.value as string)
        );
        buildings.length > 0
          ? setAllBuildings(buildings)
          : setAllBuildings([{ id: BLANKID, name: "No Buildings for this site" }]);
      };
    
      const handleBuildingChange = (event: any) => {
        setBuilding(event.target.value as string);
     
      };

      const filterQuestionnaires = (data: QueueChoiceVm[]) => {
        data = data.filter(
          (item: QueueChoiceVm) =>
            item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Covid_Questionnaire
        );
        data = data.filter(
          (item: QueueChoiceVm) =>
            item.outcomeIdentifier !==
            OutcomeIdentifier.Port_Pirie_Covid_Questionnaire
        );
        return data;
      };

      const LoadFunction = () => {
        getSiteLocations(state.state.user!.token).then((res) => {
          var loc: QueueChoiceVm[];
          var allloc = res;
          res = filterQuestionnaires(res);
            setAllLocations(res);
            setAllSites(res.filter((item: QueueChoiceVm) => item.outcomeParentId === null));
        });
      };

      useEffect(() => {
        LoadFunction()
         
      }, [])

    return (
        <>
        {allSites.length > 0 && (
            <>
        <FormControl fullWidth>
                  <div id={"site"}>
                    <InputLabel
                      className={classes.selectField}
                      error={!formErrors.site.valid}
                      id="site-label"
                    >
                      <div
                        style={{
                          left: "16px",
                          position: "absolute",
                          width: "500px",
                          top: "-7px",
                        }}
                      >
                        Site *
                      </div>
                    </InputLabel>
                    <Select
                      error={!formErrors.site.valid}
                      className={classes.selectFieldOne}
                      variant="outlined"
                      fullWidth
                      id="Site-select"
                      value={site}
                      label="Site *"
                      onChange={handleSiteChange}
                      //disabled={loading}
                    >
                      {allBuildings.length > 0 ? (
                        allSites.map((element: any) => {
                          return (
                            <MenuItem value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}
                        >
                          Site not found
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText className={classes.error}>
                      {!formErrors.site.valid
                        ? formErrors.site.errorMessage
                        : ""}
                    </FormHelperText>
                  </div>
                </FormControl>

                <FormControl fullWidth>
                  <div id={"building"}>
                    <InputLabel
                      className={classes.selectFieldOne}
                      error={!formErrors.building.valid}
                      id="building-label"
                    >
                      <div
                        style={{
                          left: "16px",
                          position: "absolute",
                          width: "500px",
                          top: "-7px",
                        }}
                      >
                        Building *
                      </div>
                    </InputLabel>
                    <Select
                      error={!formErrors.building.valid}
                      className={classes.selectFieldOne}
                      variant="outlined"
                      fullWidth
                      id="building-select"
                      value={building}
                      label="building *"
                      onChange={handleBuildingChange}
                     // disabled={loading}
                    >
                      {allBuildings.length > 0 ? (
                        allBuildings.map((element: any) => {
                          return (
                            <MenuItem value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}
                        >
                          buildings not found
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText className={classes.error}>
                      {!formErrors.building.valid
                        ? formErrors.building.errorMessage
                        : ""}
                    </FormHelperText>
                  </div>
                </FormControl>
                </>
                )}

        </>
    )
}

export default QuestionItemDropdown
