import React, { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Slide,
} from "@material-ui/core";

import nyrstar1 from "../assets/images/nyrstar1.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem!important",
      // added the center
      textAlign: "center",
      paddingTop: "70px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem!important",
      fontWeight: "600!important",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
  },
  image: {},
  selectField: {
    marginTop: "15px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!Important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!Important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  error: {
    color:"red", 
    paddingLeft: "14px", 
    fontSize:"0.9rem!important", 
    letterSpacing: "0.0075rem"
  }
}));

export const ErrorView = (props: any) => {
  const classes = useStyles();

  return (
    <Slide in={true} direction={"right"}>
      <Container
        component="main"
        maxWidth="sm"
        style={{ marginBottom: "60px", marginTop: "50px" }}
      >
        <div className={classes.paper}>
          <img className={classes.image} src={nyrstar1} alt="Site Logo" />
          <Typography className={classes.heading}>
            {props.title}
          </Typography>
            <Divider
              variant="middle"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
           <div>{props.message}</div>
        </div>
        <Divider variant="middle" style={{ marginTop: "-10px" }} />
      </Container>
    </Slide>
  );
};
