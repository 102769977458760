import React, { ChangeEvent, DragEvent, useRef } from "react";
import { styled } from "@material-ui/core/styles";

export function FileUpload({
  multiple,
  uploadFile,
  title
}: {
  uploadFile: (f: File) => void;
  title: string;
  multiple?: boolean;
}) {
  const fileRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        onChange={uploadFiles}
        multiple={multiple}
        style={{ display: "none" }}
      />
      <FileDropArea
        onClick={clickFile}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        {title}
      </FileDropArea>
    </>
  );

  function clickFile() {
    fileRef.current?.click();
  }

  function uploadFiles(e: ChangeEvent<HTMLInputElement>) {
    uploadFileList(e.target.files);
    e.target.value = "";
  }

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    uploadFileList(e.dataTransfer?.files);
  }

  function uploadFileList(files?: FileList | null) {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      uploadFile(f);
    }
  }
}

const FileDropArea = styled("div")(({ theme }) => ({
  width: "75%",
  border: "2px dashed",
  textAlign: "center",
  margin: "0 auto",
  display: "flex",
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
