import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subQuestionIcon: {
    marginRight: "18px",
    fontSize: "3em!important",
    "& :hover": {
      color: theme.palette.primary.main,
      cursor: "default",
    },
  },
  infoLink: {
    "& a": {
      textDecoration: "underline",
      color: theme.palette.primary.main + "!important",
    },
    "& a:hover": {
      textDecoration: "underline!important",
      [theme.breakpoints.up("sm")]: {
        color: theme.palette.primary.dark + "!important",
        borderBottom: "3px solid !important",
      },
    },
    "& a:visited": {
      textDecoration: "underline!important",
      color: theme.palette.primary.main + "!important",
    },
  },
  infoIcon: {
    maxWidth: "72px",
    maxHeight: "72px",
    width: "68px",
    height: "68px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "76px",
      maxHeight: "76px",
      width: "76px",
      height: "76px",
    },
  },
  w100: { width: "100%"},
  subtext: {
    marginLeft: "10px",

    maxHeight: "50px",
    fontSize: "0.6em!important",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10px",
      fontSize: "0.6em!important",
    },
  },
}));

export const Details = (props: any) => {
  const classes = useStyles();

  const renderDetails = (): any => {
    return (
      <div className={classes.w100}>
        <ListItem>
          {props.icon && (
            <ListItemIcon className={classes.subQuestionIcon}>
              {props.icon}
            </ListItemIcon>
          )}
          {props.subtext ? (
            <div>
                <ListItemText>{props.text}</ListItemText>
              <ListItemText>
                <div className={classes.subtext}>{props.subtext}</div>
              </ListItemText>
            </div>
          ) : (
            <ListItemText>{props.text}</ListItemText>
          )}
        </ListItem>

        {!props.hideDivider && <Divider />}
      </div>
    );
  };

  return renderDetails();
};
