import React, { useEffect, useState } from "react";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Dependant } from "../forms/Dependants";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
    },
    display: "flex!important",
    textAlign: "center",
    maxWidth: "660px", //Default width overriden by props.maxWidth
    margin: "auto",
  },
  btnActive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  btnStartIcon: {
    width: "35px",
    height: "35px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "rgba(0, 0, 0, 0.64)",
    display: "flex",
    justifyContent: "center",
  },
}));

export const QuestionItemCheckBox = (props: any) => {


  const [selectedCheckBox, setSelectedCheckBox] = useState<
    { id: string; text: string; selected: boolean; visible: boolean }[]
  >(props.choices);
  const classes = useStyles();

  return (
    <>
      {props.checkBoxes &&
        props.checkBoxes.map((checkBox: any, i: number) => {
          //update selectedChoices
          return (
            checkBox.visible && (
              <div key={i}>
                <ListItem
                  button
                  className={`${checkBox.selected ? classes.btnActive : ""}`}
                  onClick={() => {
                    setSelectedCheckBox(checkBox);
                    if (props.onSelected) {
                      props.onSelected(checkBox);
                    }
                  }}
                >
                  <ListItemIcon>
                    {checkBox.selected ? (
                      <CheckBoxOutlinedIcon
                        className={`${classes.btnStartIcon}  ${
                          checkBox.selected ? "selected" : ""
                        }`}
                      />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon
                        className={`${classes.btnStartIcon}  ${
                          checkBox.selected ? "selected" : ""
                        }`}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={checkBox.text} />
                </ListItem>
                <Divider />
              </div>
            )
          );
        })}
    </>
  );
};
