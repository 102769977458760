import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClosableDialogTitle } from "../Queue";
import {
  arrayControl,
  buildGroup,
  FormArray,
  useControlValue,
} from "@react-typed-forms/core";
import {
  AssignToQueueViewModel, QueueAssignements,
  QueueChoiceVm,
  QueueClient,
  QueueViewModel,
  QueueWorkItemStatus,
} from "../../../../common/client";
import { useSnackbar } from "notistack";
import { useApiClient } from "../../../../common/apiclients";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FRadioList } from "../../../../common/formComponents/FRadioList";
import { FCheckList } from "../../../../common/formComponents/FCheckList";
import {FTextField} from "../../../../common/formComponents/FTextField";
import {FCheckbox} from "../../../../common/formComponents/FCheckbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      padding: "0 !important",
      minWidth: "400px",
    },
  })
);

type assignToQueueWithEnabled = AssignToQueueViewModel & { enabled: boolean, name: string };

const FormDef = buildGroup<assignToQueueWithEnabled>()({
  outcomeIds: [],
  newQueueId: "",
  enabled: false,
  name: ""
});

const fullFormDef = buildGroup<QueueAssignements>()({
  assignToQueueVms: arrayControl(FormDef),
});

export function AssignToQueueDialog({
  onClose,
  onAssignAndClose,
  open,
  queues,
  currentQueueId,
  workItemId,
  currentQueueName,
}: {
  onClose: () => void;
  onAssignAndClose: () => void;
  queues: QueueViewModel[];
  open: boolean;
  workItemId?: string;
  currentQueueId: string;
  currentQueueName: string;
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
   // const [form] = useState(FormDef);
   // const fields = form.fields;
  

  const [form] = useState(fullFormDef);
  const fields = form.fields;
  const selectedQueueId = "";
  const client = useApiClient(QueueClient);
  const [outcomes, setOutcomes] = useState<{ [id: string]: QueueChoiceVm[] }>();

  useEffect(() => {
    if (open && queues) {
      const values = queues
        .filter((x) => x.id !== currentQueueId)
        .map((x) => ({ newQueueId: x.id, enabled: false, name: x.name,  outcomeIds: [] }));

      form.setValue({ assignToQueueVms: values });

      //form.setValue({ outcomeIds: [], newQueueId: "" }, true);
    }
  }, [open, queues]);

  useEffect(() => {
    client.getQueueOutcomes().then(setOutcomes);
  }, []);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <ClosableDialogTitle id="customized-dialog-title" onClose={onClose}>
        Assign to queue
      </ClosableDialogTitle>
      <DialogContent dividers classes={{ root: classes.dialogRoot }}>
        <Box m={2}>
          <FormArray state={fields.assignToQueueVms}>
            {(elements)=>
                elements.map((x)=> {
                  
                    return (<div>
                      <FCheckbox state={x.fields.enabled} label={x.fields.name.value}/>                      
                          {Boolean(outcomes?.[x.fields.newQueueId.value]?.length) && (
                                      <Box mx={4}>
                                        <FCheckList label="" state={x.fields.outcomeIds}>
                                          {(cp) =>
                                            outcomes![x.fields.newQueueId.value]
                                              .filter((o) => o.assignable)
                                              .map((o) => (
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      {...cp(o.id)}                                                      
                                                    />
                                                  }
                                                  label={o.name}
                                                />
                                              ))
                                          }
                                        </FCheckList>
                                      </Box>)}
                          </div>);
                 })
            }
          </FormArray>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onAssignFormSubmit(false);
            onClose();
          }}
          // disabled={checkselection()}
        >
          Assign
        </Button>
        <Button
          onClick={() => {
            onAssignFormSubmit(true);
            onAssignAndClose();
          }}
          // disabled={checkselection()}
        >
          Assign and Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  async function onAssignFormSubmit(closeWorkitem: boolean) {
        
    const formData=form.toObject().assignToQueueVms.filter(x=>x.enabled);
    
    const res=await client.assignItemToNewQueue(currentQueueId, workItemId!, { assignToQueueVms: formData })
    
    if(res!=0)
    {
      enqueueSnackbar("New work item assigned", { variant: "success" });
    }
    
  }
  
  function checkselection()
  {
    const rest= form.toObject().assignToQueueVms.filter(x=>x.enabled).length>0;
    return rest;
  }
  
}
