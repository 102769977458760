import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { CurrentStatusView } from "./CurrentStatusView";
import { PersonKind, User } from "../state/State.model";
import {
  CheckInClient,
  FormDataClient,
  CheckInRequestViewModel,
  QueueClient,
} from "../common/client";
import { useApiClient } from "../common/apiclients";
import { Context } from "../state/Store";
import { UpdateProfile } from "./UpdateProfile";
import { useSnackbar } from "notistack";
import { ErrorView } from "./ErrorView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const CurrentStatus = () => {
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<string | null>(null);
  const [user, setUser] = useState(state.user as User);
  const classes = useStyles();
  const [locationData, setLocationData] = useState<string>("");
  const [checkout, setCheckout] = useState<boolean>(false);
  const locationClient = useApiClient(QueueClient);
  const checkInClient = useApiClient(CheckInClient);
  const [updateProfile, setUpdateProfile] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [checkInTime, setCheckInTime] = useState<Date>();
  const [checkOutTime, setCheckOutTime] = useState<Date>();
  const [errorView, setErrorView] = useState<boolean>(false);
  const [errorTile, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (!location && user.firstName.length > 0) checkCurrentStatus();
  }, []);

  async function checkCurrentStatus() {
    checkInClient
      .getStatus()
      .then((response: any) => {
        if (response.error === 0) {
          let location = ""
          setLocationData(response.location[0].id);
          if (response.location[2]){
            location = `${response.location[2].name} > ${response.location[1].name} > ${response.location[0].name}`;
           } else {
            location = `${response.location[1].name} > ${response.location[0].name}`;
           }
          setLocation(location);
          setCheckInTime(new Date(Date.parse(response.checkInTime)));
          setCheckOutTime(new Date(Date.parse(response.checkInTime)));
          response.checkOutTime ? setCheckout(true) : setCheckout(false);
          setLoading(false);
        } else {
          if (response.error === 1) {
            setErrorTitle("Check In Error");
            setErrorMessage("Sorry, no previous check in was found");
            setErrorView(true);
            setLoading(false);
          }
        }
      })
      .catch((error: any) => {
        setErrorTitle("Unknown Error");
        setErrorMessage("Sorry, an unknown error has occurred");
        setErrorView(true);
        setLoading(false);
      });
  }

  const checkoutUser = () => {
    checkInClient.checkout(locationData).then((res: any) => {
      setCheckOutTime(new Date(Date.parse(res.checkOutTime)));
      setCheckout(true);
    });
  };

  const saveUserProfile = (newValues: any) => {
    checkInClient.update(newValues).then((res) => {
      if (res.firstName !== "") {
        dispatch({
          type: "SET_USERINFO",
          payload: {
            ...state.user,
            firstName: res.firstName,
            lastName: res.lastName,
            contactNumber: res.contactNumber,
            department: res.department,
          },
        });

        setUpdateProfile(false);
        enqueueSnackbar("Details Updated", { variant: "success" });
      } else {
        setUpdateProfile(false);
        enqueueSnackbar("Error updating details", { variant: "error" });
      }
    });
  };

  const cancelUpdateProfile = () => {
    setUpdateProfile(false);
  };

  return (
    <>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!loading && updateProfile && !errorView && (
        <UpdateProfile
          newValues={saveUserProfile}
          onCancel={cancelUpdateProfile}
        />
      )}

      {!loading && !updateProfile && !errorView && (
        <CurrentStatusView
          user={{ ...state.user, locationHistory: [""] }}
          location={location}
          onCheckout={checkoutUser}
          checkout={checkout}
          checkInTime={checkInTime}
          checkOutTime={checkOutTime}
          updateProfile={setUpdateProfile}
        />
      )}

      {errorView && <ErrorView title={errorTile} message={errorMessage} />}
    </>
  );
};
