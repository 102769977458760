import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

export const useSignalR = (url: string, token?: string, eventHandler?: (conn: HubConnection) => void) => {
    
    useEffect(() => {
        if( !token?.length ) {
            return;
        }

        const conn = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => token
            })
            .withAutomaticReconnect()
            .build();

           conn.start()
        .then(result => {
            if(eventHandler) {
                eventHandler(conn);
            }
        });

    }, []);

}