import React, { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ValidationTextField } from "../components/muiCore/TextField";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  DepartmentIdentifier,
  QuestionnaireIndex,
  TeamViewModel,
  UserClient,
  QueueClient,
  OutcomeIdentifier,
  SiteIdentifier
} from "../common/client";
import { Context } from "../state/Store";
import { useApiClient } from "../common/apiclients";
import { ValidatedForm } from "../components/core/forms/ValidatedForm";
import { PublicQuestionnaire } from "./Questionnaire";
import nyrstar1 from "../assets/images/nyrstar1.jpg";
import { checkForUser } from "../data/api";
import { useCookies } from "react-cookie";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import PrimaryLocation from "./PrimaryLocation";
import { Questionnaire } from "../components/core/QuestionSlider/Questionnaire";
import { getuid } from "process";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    // Thomas changed teh 60 to 100
    width: "100px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "64px",
      height: "64px",
    },
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem!important",
      // added the center
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem!important",
      fontWeight: "600!important",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
  },
  image: {},
  selectField: {
    marginTop: "15px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  error: {
    color: "red",
    paddingLeft: "14px",
    fontSize: "0.9rem!important",
    letterSpacing: "0.0075rem",
  },
}));

export const Registration = (props: any) => {
  const classes = useStyles();
  const locationData = window.location.href.slice(-36).includes("pplication") || window.location.href.slice(-36).includes("nyrstar.com") ? "F90DF3A5-136F-4F2D-E361-08D999A6E6BE" : window.location.href.slice(-36);
  const userClient = useApiClient(UserClient);
  const queueClient = useApiClient(QueueClient);
  const { state, dispatch } = useContext(Context);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [cbxRememberMe, setCbxRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState("");
  const [response, setresponse] = useState<any>([]);
  const [token, setToken] = useState<string>("");
  const [questionnaireCompleted, setQuestionnaireCompleted] =
    useState<string>();
  const [questionnaire, setQuestionnaire] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["Token", "CookieConsent"]);
  const [primaryLocation, setPrimaryLocation] = useState<boolean>(false);
  const [site, setSite] = useState<number>(0);
  const [visitorOrContractor, setVisitorOrContractor] = useState<number>(0);
  const [formErrors, setFormErrors] = useState({
    mobile: {
      valid: true,
      errorMessage: "",
    },
    firstName: {
      valid: true,
      errorMessage: "",
    },
    lastName: {
      valid: true,
      errorMessage: "",
    },
    department: {
      valid: true,
      errorMessage: "",
    },
  });

  const handleCBXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCbxRememberMe(event.target.checked);
  };

  const handleDepartmentChange = (event: any) => {
    setDepartment(event.target.value as string);
  };

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (!firstName) {
      errors.firstName.valid = false;
      errors.firstName.errorMessage = "First name can not be blank";
      valid = false;
    } else {
      errors.firstName.valid = true;
      errors.firstName.errorMessage = "";
    }

    if (!lastName) {
      errors.lastName.valid = false;
      errors.lastName.errorMessage = "Last name can not be blank";
      valid = false;
    } else {
      errors.lastName.valid = true;
      errors.lastName.errorMessage = "";
    }

    if (!contactNumber) {
      errors.mobile.valid = false;
      errors.mobile.errorMessage = "Contact number can not be blank";
      valid = false;
    } else {
      errors.mobile.valid = true;
      errors.mobile.errorMessage = "";

      if (!/^[0][0-9]{9}$|^[1300][0-9]{9}$/.test(contactNumber)) {
        errors.mobile.valid = false;
        errors.mobile.errorMessage =
          "Please provide valid contact number 10 digits with no spaces - eg. 0400123456 or 0362291643";
        valid = false;
      }
    }

    if (!department) {
      errors.department.valid = false;
      errors.department.errorMessage = "Department name can not be blank";
      valid = false;
    } else {
      errors.department.valid = true;
      errors.department.errorMessage = "";
    }

    setFormErrors(errors);
    return valid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      userClient
        .authGate({
          firstName: firstName,
          lastName: lastName,
          contactNumber: contactNumber,
          kind: 1,
          department: department,
        })
        .then((d) => {
          setresponse(d);
          registerResponse(d);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.departments &&
      props.departments.length > 0 &&
      departmentList.length === 0
    ) {
      userClient.getSite(locationData).then((d) => {

        if (d.outcomeIdentifier === OutcomeIdentifier.Port_Pirie_Site){
          let list = props.departments.filter((x:TeamViewModel) => x.siteIdentifier === SiteIdentifier.Port_Pirie);
          list = list.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier !== DepartmentIdentifier.Port_Pirie_Visitor
          );
          list = list.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier !== DepartmentIdentifier.Port_Pirie_Contractor
          );
          let visitor = props.departments.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier === DepartmentIdentifier.Port_Pirie_Visitor
          );
          let contractor = props.departments.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier === DepartmentIdentifier.Port_Pirie_Contractor
          );
          list.sort((a: TeamViewModel, b: TeamViewModel) =>
            a.name!.localeCompare(b.name!)
          );
          let temp = [visitor[0], contractor[0], ...list];
          setDepartmentList(temp);

        } else if (d.outcomeIdentifier === OutcomeIdentifier.Hobart_Site){
          let list = props.departments.filter((x:TeamViewModel) => x.siteIdentifier === SiteIdentifier.Hobart);
          list = list.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier !== DepartmentIdentifier.Hobart_Visitor
          );
          list = list.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier !== DepartmentIdentifier.Hobart_Contractor
          );
          let visitor = props.departments.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier === DepartmentIdentifier.Hobart_Visitor
          );
          let contractor = props.departments.filter(
            (x: TeamViewModel) =>
            x.departmentIdentifier === DepartmentIdentifier.Hobart_Contractor
          );
          list.sort((a: TeamViewModel, b: TeamViewModel) =>
            a.name!.localeCompare(b.name!)
          );
          let temp = [visitor[0], contractor[0], ...list];
          //Only Visitor in first version
          console.log(visitor)
          setDepartmentList(visitor);

        }
       
      })
     
    }
  }, [props.departments]);

  const registerResponse = (response: any) => {
    const usersDepartment = departmentList.filter(
      (x: any) => x.id === department
    );

    if (
      usersDepartment[0].departmentIdentifier ===
        DepartmentIdentifier.Port_Pirie_Visitor ||
      usersDepartment[0].departmentIdentifier ===
        DepartmentIdentifier.Port_Pirie_Contractor
    ) {
      setVisitorOrContractor(
        usersDepartment[0].departmentIdentifier === DepartmentIdentifier.Port_Pirie_Visitor
          ? 1
          : 2
      );
      checkForUser(response.token).then((res) => {

        if (res.error === 1) {
          setToken(response.token);
          setQuestionnaire(true);
        } else {
          if (
            new Date(res.checkInTime!).getTime() <
            new Date().getTime() - 24 * Number(response.covidTimer) * 60 * 1000
          ) {
            setToken(response.token);
            setQuestionnaire(true);
          } else {
            if (response.user.primaryLocation === null) {
              showPrimaryLocation();
            } else {
              registerUser(response.user.primaryLocation, response);
            }
          }
        }
      });
    } else if ( usersDepartment[0].departmentIdentifier ===
      DepartmentIdentifier.Hobart_Visitor) {
        setVisitorOrContractor(1)
        setSite(1)
        checkForUser(response.token).then((res) => {
          if (res.error === 1) {
            setToken(response.token);
            setQuestionnaire(true);
          } else {      
              if (response.user.primaryLocation === null) {
                showPrimaryLocation();
              } else {
                registerUser(response.user.primaryLocation, response);
              }         
          }
        });
    } else {
      if (response.user.primaryLocation === null) {
        showPrimaryLocation();
      } else {
        registerUser(response.user.primaryLocation, response);
      }
    }
  };

  const showPrimaryLocation = () => {
    setPrimaryLocation(true);
    //  registerUser();
  };

  useEffect(() => {
    if (questionnaireCompleted) {
      setQuestionnaire(false);
      if (response.user.primaryLocation === null) {
        showPrimaryLocation();
      } else {
        registerUser(response.user.primaryLocation, response);
      }
    }
  }, [questionnaireCompleted]);

  const registerUser = (locationId: string, res: any) => {
    var data: any;
    if (res === undefined) {
      data = response;
    } else {
      data = res;
    }

    dispatch({
      type: "SET_USERINFO",
      payload: {
        ...state.user,
        ...data.user,
        token: data.token,
        rememberMe: cbxRememberMe,
        department: department,
        covidQuestionnaire: questionnaireCompleted,
        primaryLocation: locationId,
      },
    });
    if (getCookieConsentValue() === "true" && cbxRememberMe) {
      setCookie("Token", response.token, {
        path: "/",
        secure: true,
        sameSite: "strict",
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      });
    }
  };

  return (
    <>
      {!questionnaire && primaryLocation && (
        <PrimaryLocation
          location={locationData}
          onSavePrimaryLocation={registerUser}
          userDetails={response}
          visitorOrContractor={visitorOrContractor}
        />
      )}

      {questionnaire && !primaryLocation && (
        <PublicQuestionnaire
          token={token}
          completed={(completed: string) =>
            setQuestionnaireCompleted(completed)
          }
          questionnaireIndex={site === 0 ? QuestionnaireIndex.Port_Pirie_Covid_Survey : QuestionnaireIndex.Hobart_Covid_Survey}
        />
      )}

      {!questionnaire && !primaryLocation &&  departmentList.length > 0 && (
        <Container
          component="main"
          maxWidth="sm"
          style={{ marginBottom: "60px", marginTop: "50px" }}
        >
          <div className={classes.paper}>
            <img className={classes.image} src={nyrstar1} alt="Site Logo" />
            <Typography className={classes.heading}>User Details</Typography>
            <ValidatedForm>
              <ValidationTextField
                error={!formErrors.firstName.valid}
                helperText={formErrors.firstName.errorMessage}
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
                required
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                }}
                variant="outlined"
                disabled={loading}
              />
              <ValidationTextField
                error={!formErrors.lastName.valid}
                helperText={formErrors.lastName.errorMessage}
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                required
                onChange={(e: any) => {
                  setLastName(e!.target.value);
                }}
                variant="outlined"
                validation={{ required: true }}
                disabled={loading}
              />
              <ValidationTextField
                variant="outlined"
                error={!formErrors.mobile.valid}
                helperText={
                  !formErrors.mobile.valid ? formErrors.mobile.errorMessage : ""
                }
                margin="normal"
                fullWidth
                id="mobile"
                label="Phone number (mobile preferred)"
                name="mobileNumber"
                required
                onChange={(e: any) => {
                  setContactNumber(e.target.value);
                }}
                type={"tel"}
                disabled={loading}
              />
              <FormControl fullWidth>
                <InputLabel
                  className={classes.selectField}
                  error={!formErrors.department.valid}
                  id="demo-simple-select-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-6px",
                    }}
                  >
                    Your Department *
                  </div>
                </InputLabel>
                <Select
                  error={!formErrors.department.valid}
                  className={classes.selectField}
                  variant="outlined"
                  fullWidth
                  value={department}
                  label="Your Department *"
                  onChange={handleDepartmentChange}
                  disabled={loading}
                >
                  {props.departments &&
                  props.departments.length > 0 &&
                  departmentList &&
                  departmentList.length > 0 ? (
                    departmentList.map((element: TeamViewModel) => {
                      return (
                        <MenuItem value={element.id}>{element.name}</MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                      Departments not found
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className={classes.error}>
                  {!formErrors.department.valid
                    ? formErrors.department.errorMessage
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={cbxRememberMe}
                    onChange={handleCBXChange}
                  />
                }
                label="Remember me"
              />
              <br />
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setLoading(true);
                  onSubmit();
                }}
              >
                {loading && <CircularProgress style={{ color: "white" }} />}
                {!loading && "Next"}
              </Button>
            </ValidatedForm>
          </div>
          <Divider variant="middle" />
        </Container>
      )}
    </>
  );
};
