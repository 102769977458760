import React, { useEffect, useMemo, useRef, useState } from "react";
import { Slide, SlideProps } from "@material-ui/core";
import { Question } from "./QuestionItem";
import {
  QuestionAdminViewModel,
  QuestionChoice,
  QuestionChoiceViewModel,
} from "../../../common/client";
import { QuestionItemNavProps } from "./Questionnaire";
import { questionVisibleForQueue } from "../Questionnaire";

export const QuestionItemSlider = (props: QuestionItemNavProps) => {
  const { questions: qs, controls } = props;
  const questions = useMemo(
    () => qs.filter((q) => questionVisibleForQueue(q, props.queueId)),
    [qs, props.queueId]
  );
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] =
    useState<SlideProps["direction"]>("left");
  const [question, setQuestion] = useState(props.currentQuestion);
  const backRef = useRef<{ [id: string]: QuestionAdminViewModel }>({});

  useEffect(() => {
    if (question !== props.currentQuestion) {
      const toDir = (b: boolean) => (b ? "left" : "right");
      const goingLeft = !props.transitionForward;
      setSlideDirection(toDir(goingLeft));
      setSlideIn(false);

      setTimeout(() => {
        setQuestion(props.currentQuestion);
        setSlideDirection(toDir(!goingLeft));
        setSlideIn(true);
      }, 300);
    }
  }, [question, props.currentQuestion]);

  function getPreviousQuestion() {
    return backRef.current[question.id];
  }
  function getNextQuestion(selectedChoice: QuestionChoiceViewModel) {
    let nextQuestion: QuestionAdminViewModel | undefined = undefined;
    if (question.responseMappings) {
      if (selectedChoice.id) {
        //find nextQuestionId of question response mappings matching choice Id
        const mapping = question.responseMappings.find(
          (m) => m.choiceId === selectedChoice.id
        );
        const nextQuestionId = mapping?.nextQuestionId;
        if (nextQuestionId) {
          //get question index matching these id
          nextQuestion = questions.find(
            (question) => question.id === nextQuestionId
          );
          if (nextQuestion) {
            backRef.current[nextQuestionId] = question;
            return nextQuestion;
          }
        }
      }
    }

    return nextQuestion;
  }
  const onChoiceSelected = (direction: "left" | "right") => {
    const selectedChoiceId = controls.fields[question.id].fields.choiceId.value;
    const nextQuestion =
      direction === "left"
        ? getPreviousQuestion()
        : getNextQuestion(
            question.choices.find((c) => c.id === selectedChoiceId)!
          );

    props.navigate(nextQuestion, direction === "right");
  };
  return (
    <>
      <Slide in={slideIn} direction={slideDirection}>
        <div
          style={{
            maxWidth: props.maxWidth ?? "100%",
            ...props.style,
            margin: "auto",
            
          }}
        >
          <Question
            settings={props.settings}
            questionnaireInfo={props.questionnaireInfo}
            queueId={props.queueId}
            readonly={false}
            question={question}
            control={props.controls.fields[question.id]}
            currentIndex={questions.indexOf(question)}
            onBack={() => {
              onChoiceSelected("left");
            }}
            onResult={() => {
              onChoiceSelected("right");
            }}
          />
        </div>
      </Slide>
    </>
  );
};
