import { UserPermissions } from "./../../state/State.model";

export const hasPermission = (
  user: any | null,
  permission: UserPermissions
): boolean => {
  if (!user) {
    return false;
  }

  if (user.roles?.length == 0) {
    return false;
  }

  for (const role of user.roles) {
    const p = role.permissions;

    if ((p & UserPermissions.SuperUser) > 0) {
      return true;
    }
    // check permissions other than admin
    else if (p.toString() == permission.toString()) {
      return true;
    }
    // else if(( p & permission ) >= permission)
    // {
    //     return true;
    // }
  }

  return false;
};
