import { QueueChoiceVm, TeamViewModel } from "../common/client";

export enum QueueWorkItem {
  New,
  OnHold,
  InProgress,
  Closed,
}
export enum UserPermissions {
  SuperUser = 0x1,
  /// <summary>
  /// provides edit access for all roles & users
  /// </summary>
  ModifyRoles = 0x2,
  ModifyLocations = 0x4,
  ModifyQuestions = 0x8,
  ModifyQueueMember = 0x10,
  ContactTracer = 0x200,
  IgnoreLocationConstraints = 0x20,
  IgnoreAudienceConstraints = 0x40,
  ModifyTeams = 0x80,
  ViewQuestionnaire = 0x100,
  ProcessApplications = 0x16,
  UploadManualCheckins = 0x32,
}

export interface BasicRole {
  id: string;
  name: string;
  permissions: UserPermissions;
}
export enum PersonKind {
  None,
  Visitor,
  Patient,
  Staff,
  Quarantine,
  QuarantineWorkerStaff,
  HotLineStaff,
  Public,
  CentralHubStaff,
  PHEOCStaff,
}

export interface Credentials {
  firstName: string;
  lastName: string;
  contactNumber: string;
  dateOfBirth?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  gpName?: string;
  gpPractice?: string;
  emailAddress?: string;
  sex?: string;
  //lastLocation: { id: string, name: string }[] | null;
  //metadataQuestions:any;
  kind: PersonKind | number | null;
}
export interface Person {
  id: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  currentAudience: {
    id: string;
    name: string;
  };
}
export interface User extends Credentials {
  covidQuestionnaire: string | null;
  personaType: PersonKind;
  loggedIn: boolean;
  token: string;
  roles: BasicRole[];
  locationHistory: Location[];
  id: string;
  rememberMe: boolean;
  department: string;
  departments: TeamViewModel[];
  primaryLocation: string;
  locations: QueueChoiceVm[];
}

export default interface StateModel {
  user: User | null;
}

export interface AuthContext {
  user: User | null;
  signIn?: (
    firstName: string,
    lastName: string,
    contactNumber: string,
    dob: string,
    address: string
  ) => any;
  signOut?: () => void;
}

export enum Outcome {
  Normal,
  Invalid,
  Fail,
  Exemption,
  Conditional,
}

export interface QueueItem {
  id: string;
  name: string;
  questoinChoiceResult: number;
}
