import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { debug } from "console";
import { endOfToday, startOfYesterday } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { SelectableStatisticCard } from "../../../components/muiCore/Card";
import { CalendarsDateRangePicker } from "../../../components/muiCore/DateRangePicker";
import { AppBar } from "../../../components/muiCore/Paper";
import { HubConnection } from "@microsoft/signalr";
import {
	getAllQueueMetrics,
	getQueueMetrics,
	getQueues,
} from "../../../data/api";
import { QueueWorkItem } from "../../../state/State.model";
import { Context } from "../../../state/Store";
import { useSignalR } from "../../utils/signalr";

interface IDateRange {
	after: Date;
	before: Date;
}

const SummaryView = () => {
	const { state } = useContext(Context);

	const [isDatePickerExpanded, setIsDatePickerExpanded] =
		useState<boolean>(false);
	const [allMetric, setAllMetric] = useState<any[]>([]);
	const [receivedSignal, setReceivedSignal] = useState<boolean>(false);

	const [searchFilters, setSearchFilters] = useState<IDateRange>({
		after: startOfYesterday(),
		before: endOfToday(),
	});

	const AllQueueWorkItems = [
		QueueWorkItem.New,
		QueueWorkItem.OnHold,
		QueueWorkItem.InProgress,
		QueueWorkItem.Closed,
	];

	let MetricCategories = AllQueueWorkItems.map((wi) => QueueWorkItem[wi]);

	const refreshMetrics = () => {
		getAllQueueMetrics(
			searchFilters.before,
			searchFilters.after,
			state!.user!.token!
		).then((r: any) => setAllMetric(r));
	};

	useEffect(() => {
		refreshMetrics();
	}, [searchFilters]);

	useEffect(() => {
		if (receivedSignal) {
			refreshMetrics();
			setReceivedSignal(false);
		}
	}, [receivedSignal]);

	useSignalR("/api/hub/queue", state.user?.token, (conn: HubConnection) => {
		conn.on("queueitem-updated", (workItem: any) => {
			setReceivedSignal(true);
		});
	});

	const onDatePickerChangeHandler = (range: any) => {
		if (
			searchFilters &&
			(searchFilters?.after !== range[0] || searchFilters?.before !== range[1])
		) {
			setSearchFilters({
				before: range[1],
				after: range[0],
			});
		}
	};

	return (
		<>
			<AppBar elevation={3}>
				<div style={{ marginLeft: "25px" }}>
					<CalendarsDateRangePicker
						onOpen={() => setIsDatePickerExpanded(true)}
						onClose={() => setIsDatePickerExpanded(false)}
						isDatePickerExpanded={isDatePickerExpanded}
						onRangeChange={onDatePickerChangeHandler}
					/>
				</div>
			</AppBar>
			{allMetric.map((m, i) => (
				<>
					<div style={{ marginBottom: "10px" }}>{m.name}</div>
					<Divider />
					<div style={{ marginTop: "15px" }}>
						<MetricView metrics={m} MetricCategories={MetricCategories} />
					</div>
				</>
			))}
		</>
	);
};

const MetricView = (props: any) => {
	const { metrics, MetricCategories } = props;

	return (
		<Grid
			container
			direction="row"
			justify="space-between"
			alignItems="flex-start"
			spacing={4}
			style={{ marginBottom: "10px" }}
		>
			{metrics &&
				MetricCategories.map((category: any, key: number) => (
					<Grid item key={key}>
						<SelectableStatisticCard
							title={
								<Typography variant={"body1"}>
									{category || "Unknown"}
								</Typography>
							}
							value={
								<Typography variant={"h1"} className={category}>
									{metrics.queueWorkItems
										?.filter((metric: any) => metric.status == key)
										.map((m: any) => m.count)[0] || 0}
								</Typography>
							}
						/>
					</Grid>
				))}
		</Grid>
	);
};

export default SummaryView;
