import React, { useContext, useEffect, useState } from "react";
//import {Dependant} from "../../../data/Dependant";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Questionnaire } from "../components/core/QuestionSlider/Questionnaire";
import {
  AnswersState,
  makeQuestionnaireControl,
  QuestionnaireControls,
  visitQuestions,
  autoSelectChoice,
  parseAnswers,
} from "../components/core/Questionnaire";
import {
  QuestionAdminViewModel,
  QuestionChoiceViewModel,
  QuestionnaireAndResponsesVm,
  QuestionnaireClient,
  QuestionnaireForwardingViewModel,
  QuestionnaireResponseViewModel,
  QuestionResponseVm,
} from "../common/client";
import { TokenContext, useApiClient } from "../common/apiclients";
import { Context } from "../state/Store";
import { useHistory, useLocation } from "react-router";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { Message } from "../components/core/dialogs/Message";
import nyrstar1 from "../assets/images/nyrstar1.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
    paperCenter: {
      marginTop: theme.spacing(40),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {},
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
  })
);

export function PublicQuestionnaire(props: any) {
  const { state } = useContext(Context);

  return (
    <TokenContext.Provider
      value={props.token ? props.token : state.user?.token}
    >
      <InnerQuestionnaire
        completed={props.completed}
        questionnaireIndex={props.questionnaireIndex}
        showStepper={props.showStepper}
        settings={props.settings}
        existingQuestionnaire={props.existingQuestionnaire}
      />
    </TokenContext.Provider>
  );
}

function InnerQuestionnaire(props: any) {
  const classes = useStyles();
  // const region = new URLSearchParams(useLocation().search).get("region");
  const [questionsAndControls, setQuestionsAndControls] = useState<{
    questions: QuestionAdminViewModel[];
    controls: QuestionnaireControls;
  }>();
  const [questionnaireResponseId, setQuestionnaireResponseId] =
    useState<string>();
  const questionnaireClient = useApiClient(QuestionnaireClient);
  const history = useHistory();
  const [messageOpen, setMessageOpen] = useState(false);
  const [sendingResults, setSendingResults] = useState<boolean>(false);
  const [questionnaireInfo, setQuestionnaireInfo] =
    useState<QuestionnaireForwardingViewModel>();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [questionnaireAlreadyCompleted, setQuestionnaireAlreadyCompleted] =
    useState<boolean>(false);
  const [questionnaireSaveError, setQuestionnaireSaveError] =
    useState<boolean>(false);

  async function handleQuestionnaireFinalisation() {
    setSendingResults(true);
    await finalise();
  }

  async function finalise() {
    const result = await questionnaireClient.finaliseQuestionnaire(
      questionnaireResponseId!
    );

    if (result !== null) {
      switch (result.status) {
        case 0:
          props.completed("0");
          break;
        case 1:
          props.completed(result!.queueWorkItem!.outcomes![0].name!); 
          break;

        default:
          history.push("/");
          break;
      }
    } else {
      setQuestionnaireSaveError(true);
    }
  }

  async function handleQuestionnaireResponse(question: QuestionAdminViewModel) {
    if (questionsAndControls) {
      const controls = questionsAndControls.controls;
      if (controls.dirty) {
        const questionId = question.id;
        const { choiceId: _choiceId, value } =
          controls.fields[questionId].toObject();
        const choiceId = _choiceId ?? autoSelectChoice(question);

        if (choiceId) {
          const valueAndChoice = {
            choiceId: choiceId,
            value: value ? JSON.stringify(value) : undefined,
          };
          await questionnaireClient.addQuestionChoice(
            questionnaireResponseId!,
            questionId,
            valueAndChoice
          );
        }
      }
    }
    return true;
  }

  async function startQuestionnaire() {
    if (props.existingQuestionnaire === undefined) {
      const questions = await questionnaireClient.getQuestions(
        props.questionnaireIndex
      );
      const controls = makeQuestionnaireControl(questions.questions);
      setQuestionsAndControls({ questions: questions.questions, controls });
      setQuestionnaireResponseId(questions.questionnaireResponseId);
    } else {
      setQuestionIndex(3);
      questionnaireClient
        .getQuestionnaireData(props.existingQuestionnaire)
        .then(async (result: QuestionnaireForwardingViewModel) => {

          if (result.urlString === null) {
            setQuestionnaireAlreadyCompleted(true);
          } else {
            setQuestionnaireInfo(result);
            const questions = await questionnaireClient.getExistingQuestions(
              result.questionnaireId!
            );

            const controls = makeQuestionnaireControl(
              questions.questions,
              parseAnswers(questions.responses)
            );
            setQuestionsAndControls({
              questions: questions.questions,
              controls,
            });
            setQuestionnaireResponseId(questions.questionnaireresponseId);
          }
        });
    }
  }

  useEffect(() => {
    startQuestionnaire();
  }, []);

  return (
    <>
      {questionsAndControls &&
        !sendingResults &&
        !questionnaireAlreadyCompleted && (
          <>
            <Questionnaire
              questions={questionsAndControls.questions}
              validation={undefined}
              maxWidth={"700px"}
              controls={questionsAndControls.controls}
              onQuestionnaireFinalised={handleQuestionnaireFinalisation}
              onSaveResponse={handleQuestionnaireResponse}
              style={{}}
              // overrideSubmit={checkHighRisk}
              stepper={props.showStepper}
              settings={props.settings}
              questionnaireInfo={questionnaireInfo}
              queueId={""}
              showHelpTextToggle={false}
              helpTextExpansion={false}
            />
            <Message
              open={messageOpen}
              title={"Returned From High Risk Area"}
              text={
                "Do not use this form if you have recently returned from a high risk area, or if you are a close contact of a confirmed COVID-19 case. Please ring the Tasmanian Public Health Hotline on 1800 671 738."
              }
              onClose={handleMessageClose}
            />
          </>
        )}

      {!questionsAndControls && !questionnaireAlreadyCompleted && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {questionnaireAlreadyCompleted && (
        <div className={classes.paperCenter}>
          <img className={classes.image} src={nyrstar1} alt="Site Logo" />
          <Typography className={classes.heading}>
            Unable to retrieve questionnaire
          </Typography>
          <Typography>
            Either questionnaire is already completed or does not exist
          </Typography>
          <Typography>
            Please contact your host at nyrstar to start a new questionnaire
          </Typography>
        </div>
      )}

      {questionnaireSaveError && (
        <div className={classes.paperCenter}>
          <img className={classes.image} src={nyrstar1} alt="Site Logo" />
          <Typography className={classes.heading}>
            Issue when saving questionnaire.
          </Typography>
          <Typography>
            {" "}
            Please make sure you are filling out the correct sections
          </Typography>
          <Typography></Typography>
        </div>
      )}
    </>
  );

  async function handleMessageClose() {
    history.push("/");
  }

  async function checkHighRisk(qIndex: number) {
    if (!questionsAndControls) return true;
    const selQuestion = questionsAndControls.questions[qIndex];
    if (selQuestion && selQuestion.questionKey === "HighRisk") {
      setMessageOpen(true);
      return false;
    }
    return true;
  }

  async function setRegionResponse(
    controls: QuestionnaireControls,
    responseId: string,
    questions: QuestionAdminViewModel[]
  ) {
    let p: Promise<any> | undefined;
    visitQuestions(questions, (q) => {
      //if (q.questionKey === "Region") {
      const choice = q.choices.find((c) => c.text.toLowerCase()); // === region);
      if (choice) {
        p = questionnaireClient.addQuestionChoice(responseId, q.id, {
          choiceId: choice.id,
        });
        const preFill: AnswersState = {};
        preFill[q.id] = { choiceId: choice.id, value: undefined };
        controls.setValue(preFill);
      }
      return false;
      // }
      // return true;
    });
    if (p) await p;
  }
}
