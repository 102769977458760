import React, {forwardRef, useImperativeHandle} from "react";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDrawer from "@material-ui/core/Drawer";
import {AppBar, Dialog, IconButton, Toolbar, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";



const StyledDrawer = withStyles((theme:Theme)=>({
    root:{

    },
    paper:{
        width: '60%',
        overflowX: 'hidden', /* Hide horizontal scrollbar */
        display:'flow-root!important'
    },
    paperAnchorDockedRight:{
        borderLeft:0
    }
}))(MuiDrawer);

export const Drawer = forwardRef ((props:any, ref) => {

    const [drawerState, setDrawerState] = React.useState(false)
    
    const handleClose = () => {
        setDrawerState(false);
    };

    useImperativeHandle(ref,()=>({
        openDrawer:() => {
            setDrawerState(true)
        },
        closeDrawer:() => {
            setDrawerState(false)
        }
    }))

    return (
        <div >
            <StyledDrawer
                variant={props.variant}
                anchor={props.anchor}
                open={drawerState}
                onClose={(e, reason)=> {
                    props.onClose(e, reason);
                }}>
                <AppBar style={{position:'relative'}}>
                    {props.showToolBar && <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        {props.title && <Typography variant="h4">
                            {props.title}
                        </Typography>}
                    </Toolbar>}
                </AppBar>
                {props.children}
            </StyledDrawer>
        </div>
    );
})