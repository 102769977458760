import React, { ReactNode, useContext } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Slide,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import Moment from "react-moment";
import { PersonKind, User } from "../state/State.model";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  dayPassStyles,
} from "../styles/common";
import { Details } from "../components/core/Templates/Details";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  MapMarkerAltSolid,
  MapPinSolid,
  StreetViewSolid,
} from "../components/Icons/DoHIcons";
import { ClassSharp } from "@material-ui/icons";
import clsx from "clsx";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { format } from "date-fns";
import { Context } from "../state/Store";

const StyledTypography = withStyles((theme) => ({
  body1: {
    fontSize: "1.5rem!important",
    textAlign: "center",
  },
}))(Typography);

export const Finalisation = (props: any) => {
  const { state, dispatch } = useContext(Context);
  let classes = dayPassStyles();
  let user = state.user as User;
  const [expanded, setExpanded] = React.useState<string | false>();
  const primaryLocation = props.primaryLocation;
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const [dependantExpanded, setDependantExpanded] = React.useState<
    string | false
  >();
  const handleChangeDependant =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setDependantExpanded(newExpanded ? panel : false);
    };
  const header = () => {
    return props.questionnairePassResult &&
      state.user?.covidQuestionnaire !== "Fail" ? (
      <>
        <CheckCircleOutlineIcon className={classes.icon} />
        <Typography className={classes.cardHeaderText}>
          {!props.checkout
            ? user.firstName + " " + user.lastName + ", checked in"
            : user.firstName + " " + user.lastName + ", checked out"}
        </Typography>
      </>
    ) : (
      <>
        <HighlightOffIcon className={classes.icon} />
        <Typography className={classes.cardHeaderText}>
          {!props.checkout
            ? user.firstName + " " + user.lastName + ", Denied entry"
            : user.firstName + " " + user.lastName + ", Denied entry"}
        </Typography>
      </>
    );
  };

  let date = (
    <Typography>
      {!props.checkout && props.checkInTime
        ? format(new Date(props.checkInTime), "EEE HH:mm, dd-MM-yyyy")
        : format(new Date(props.checkOutTime), "EEE HH:mm, dd-MM-yyyy")}
    </Typography>
  );
  const result = (): JSX.Element => {
    return props.questionnairePassResult &&
      state.user?.covidQuestionnaire !== "Fail" ? (
      <>
        <StyledTypography>THANK YOU</StyledTypography>
        {!props.checkout ? (
          <StyledTypography>PLEASE PROCEED</StyledTypography>
        ) : (
          <StyledTypography>FOR CHECKING OUT</StyledTypography>
        )}
      </>
    ) : (
      <>
        <StyledTypography>
          Do not enter the site and inform your supervisor. You should get
          tested for COVID-19.
        </StyledTypography>
      </>
    );
  };

  const addLocationAsPrimary = () => {
    if (!primaryLocation){
      props.setPrimaryLocation(true);
    }
  }

  let lastLocation = props.location; 

  return (
    <Slide in={true} direction={"left"}>
      <div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List>
              <ListItem className={clsx(classes.cardHeader)}>
                {header()}
              </ListItem>
              <Details text={date} icon={<ScheduleOutlinedIcon />} />
       
              <Details 
              text={lastLocation} 
              icon={<StreetViewSolid/>} 
              subtext={primaryLocation ? "This is your primary location" : ""} 
              />
            
              <Details text={result()} hideDivider={true} />
            </List>
          </CardContent>
        </Card>

        {!props.checkout && (
          <>
            <Box
              className={classes.box}
              style={{ maxWidth: `${props.maxWidth}px` }}
            >
              <ButtonGroup variant={"contained"} fullWidth>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    props.onCheckout();
                  }}
                >
                  Checkout
                </Button>
              </ButtonGroup>
            </Box>
            <Divider
              variant="middle"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
          </>
        )}

        <Box
          className={classes.box}
          style={{ maxWidth: `${props.maxWidth}px` }}
        >
          <ButtonGroup variant={"contained"} fullWidth>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                props.updateProfile(true);
              }}
            >
              Update Details
            </Button>
          </ButtonGroup>
        </Box>
      </div>
    </Slide>
  );
};
