import {DateRangeDelimiter, DateRangePicker, DateRange} from "@material-ui/pickers";
import {CalendarTextField, ValidationTextField} from "./TextField"
import {CircularProgress, Typography} from "@material-ui/core";
import React, {useEffect} from "react";
import {
    addDays, endOfToday, startOfToday, formatISO, startOfDay, endOfDay, isValid, startOfYesterday
} from "date-fns";
import {withStyles} from "@material-ui/core/styles";
import { setSyntheticLeadingComments } from "typescript";


const StyledDateRangePicker = withStyles((theme) => ({
    root: {
        
        '&.MuiPickersSlideTransition-root':{
            overflowX:'visible',
        }
    }
}))(DateRangePicker);

export const CalendarsDateRangePicker = (props: any) => {
    const today = new Date()
    const yesterday = addDays(today, -1)
    const [open, setOpen] = React.useState<any>(undefined);
    const {disabled, isDatePickerExpanded, onClose, onOpen} = props

    const [value, setValue] = React.useState<DateRange<Date>>([startOfToday(), endOfToday()]);

    useEffect(() => {
        props.onRangeChange([formatISO(startOfDay(value[0]!)), formatISO(endOfDay(value[1]!))])
    }, [value])

    const AttributeController = isDatePickerExpanded === true ? {
        loading: disabled
    } : {
        disabled
    }



    return (
        <StyledDateRangePicker
            renderLoading={() => <CircularProgress />}
            onClose={onClose}
            onOpen={onOpen}
            //open={open}
            {...AttributeController}
            calendars={1}
            value={value}
            allowSameDateSelection={true}
            onChange={(newValue: any) => {

                if(newValue.length === 2 && isValid(newValue[0]) && isValid(newValue[1]))
                {
                    setValue([startOfDay(newValue[0]), endOfDay(newValue[1])])
                   if (newValue[0].toString() === newValue[1].toString())
                   {
                      setOpen(false);
                   }

                }

            }}
            renderInput={(startProps, endProps) => (
                <div style={{alignItems: 'center', display: 'flex', marginTop: '15px'}}>
                    <CalendarTextField {...startProps} />
                    <DateRangeDelimiter> <Typography variant={'caption'}>TO</Typography>
                    </DateRangeDelimiter>
                    <CalendarTextField {...endProps} />
                </div>
            )}
        />
    );
}