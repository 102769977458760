import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  Divider,
  Fade,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MuiCardContent from "@material-ui/core/CardContent";
import { QuestionHeader } from "./QuestionItemHeader";
import { QuestionItemSubQuestion } from "./QuestionItemSubQuestion";
import { QuestionItemRadioButton } from "./QuestionItemRadioButton";
import { QuestionItemCheckBox } from "./QuestionItemCheckbox";
import { RadioButtonsWithAdditionalInfoTextField } from "./RadioButtonWithAdditionalInfoTextField";
import { QuestionItemYesNoButtons } from "./QuestionItemYesNoButtons";
import { Dependant } from "../forms/Dependants";
import {
  QuestionItemChoiceButton,
  QuestionItemNextSkipButton,
} from "./QuestionItemNextSkipButton";
import { IQuestion } from "../../../models/Question";
import {
  BaseControl,
  FormControl,
  GroupControl,
  useControlValue,
} from "@react-typed-forms/core";
import { NestedQuestions, RepeaterQuestion } from "./RenderNestedQuestion";
import {
  QuestionAdminViewModel,
  QuestionChoice,
  QuestionnaireForwardingViewModel,
  QuestionnaireResponseViewModel,
  QuestionType,
  SettingsViewModel,
  SubQuestion,
} from "../../../common/client";
import { createControl, TopLevelControl } from "../Questionnaire";
import { CommonBTN } from "../Buttons/CommonBTN";

export const QuestionCard = withStyles((theme) => ({
  root: {
    border: "none",
    borderRadius: 0,
    boxShadow: " 0 0 8px 0 rgba(0,0,0,0.3)",
    backgroundColor: "#35353",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      marginBottom: "70px",
    },
  },
}))(Card);

export const QuestionCardContent = withStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      margin: "unset",
    },
    "&:last-child": {
      paddingBottom: "unset",
    },
    "& .MuiList-root": {
      padding: "0px!important",
    },
  },
}))(MuiCardContent);
const QuestionContent = withStyles((theme) => ({}))(List);

interface QuestionItemProps {
  question: QuestionAdminViewModel;
  type: QuestionType;
  subQuestions: SubQuestion[] | undefined;
  control: TopLevelControl;
  queueId: string;
  onResult: () => void;
  readonly: boolean;
  settings?: SettingsViewModel[];
  questionnaireInfo?: QuestionnaireForwardingViewModel;
}

const QuestionItem = (props: QuestionItemProps): any => {
  const controls = props.control.fields.value;
  const selectedItem = useControlValue(props.control.fields.choiceId);
  const items = props.question.choices;
  const question = props.question;

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  //const questionTextFields = useRef<any>([]);

  function validateAndChoose(choice: QuestionChoice) {
    props.control.fields.choiceId.setValue(choice.id);
    controls.setTouched(true);
    controls.validate();

    if (controls.valid) {
      props.onResult();
    }
  }

  const QuestionTypeRender = () => {
    switch (props.question.type) {
      case QuestionType.Repeater:
        return (
          <RepeaterQuestion
            nestedQuestions={question.nestedQuestions!}
            parentQuestion={question}
            control={controls as any}
            hidden={false}
            readonly={props.readonly}
          >
            <QuestionItemChoiceButton
              choices={items!}
              onSelected={(selected) => {
                validateAndChoose(selected);
              }}
            />
          </RepeaterQuestion>
        );
      case QuestionType.RadioButtons:
        return (
          <>
          <List
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontWeight: 600,
                    }}
                  >
                    {props.question.helpText?.split("\n").map((text, index) => (
                      <>
                        <ListItem> {text}</ListItem> <Divider />
                      </>
                    ))}
                  </List>
        
          <QuestionItemRadioButton
            radioButtons={items}
            selected={selectedItem}
            readonly={props.readonly}
            onSelected={(selected) => {
              validateAndChoose(selected);
            }}
          />
          </>
        );
        case QuestionType.RadioButtonsWithLocationHelper:
          const locationHelper = () => {window.open("https://dpipwe-au.maps.arcgis.com/apps/webappviewer/index.html?id=817a00ca5f03473ab4ea4202b8f8cd12&extent=15704808.9205%2C-5427061.6209%2C17015856.8297%2C-4803946.9663%2C102100", "_blank");}
          return (
            <>
            {!props.readonly && (
              <>
           <CommonBTN
              maxWidth={"maxWidth"}
              BtnText={"Interstate LGA Helper"}
              onClick={() => locationHelper()}
        /> 
        {/* {
            items.length > MIN_SEARCH_LIMIT && 
            <ChoiceFilter
                placeholder={'Search Location...'}
                value={searchQuery}
                onChange={(e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    //setLoading(true)
                  
                    setSearchQuery(e.target.value)
                }}
            />
        } */}
        </>
        )}
            <QuestionItemRadioButton
            radioButtons={question.choices}
            selected={selectedItem}
            readonly={props.readonly}
            onSelected={(selected) => {
              validateAndChoose(selected);
            }}
          />
            </>
          );
      case QuestionType.RadioButtonsWithAddInfo:
        return (
          <RadioButtonsWithAdditionalInfoTextField
            radioButtons={items}
            onSelected={(selected: any) => {
              validateAndChoose(selected);
            }}
          />
        );
      case QuestionType.HasDependants:
        return (
          <>
            <Dependant />
            <QuestionItemCheckBox
              checkBoxes={items}
              onSelected={(selected: any) => {
                validateAndChoose(selected);
              }}
            />
            <QuestionItemNextSkipButton
              skipQuestion={
                items!.filter((i: any) => i.selected && i.visible).length > 0
              }
              onClick={() => {
                // validateAndChoose(selected)
              }}
            />
          </>
        );
      case QuestionType.YesNo:
        return (
          <>
            {props.subQuestions && (
              <QuestionItemSubQuestion
                subQuestions={props.question.subQuestions}
              />
            )}
            {isMobileDevice ? (
              <Fade in={true} timeout={1000}>
                <div>
                {props.question.helpText &&(
                  <List
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontWeight: 600,
                    }}
                  >
                    {props.question.helpText?.split("\n").map((text, index) => (
                      <>
                        <ListItem style={{bottom: '0',}}> {text}</ListItem> <Divider />
                      </>
                    ))}
                  </List>
                  )}
                  <div style={{bottom: '0',position: 'fixed', width: '100%'}}>
                  <QuestionItemYesNoButtons
                    buttons={items}
                    selected={props.control.fields.choiceId.value}
                    readonly={props.readonly}
                    onSelected={validateAndChoose}
                  />
                  </div>
                </div>
              </Fade>
            ) : (
              <div>
                <List
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontWeight: 600,
                  }}
                >
                  {props.question.helpText?.split("\n").map((text, index) => (
                    <>
                      <ListItem> {text}</ListItem> <Divider />
                    </>
                  ))}
                </List>
                <QuestionItemYesNoButtons
                  buttons={items}
                  selected={props.control.fields.choiceId.value}
                  readonly={props.readonly}
                  onSelected={validateAndChoose}
                />
              </div>
            )}
          </>
        );
      case QuestionType.Grouped:
        return (
          <NestedQuestions
            readonly={props.readonly}
            nestedQuestions={question.nestedQuestions!}
            control={controls as GroupControl<{ [id: string]: BaseControl }>}
            key={props.question.id}
            hidden={false}
            settings={props.settings}
            questionnaireInfo={props.questionnaireInfo}
          >
            {!props.readonly && (
              <QuestionItemChoiceButton
                choices={items!}
                onSelected={validateAndChoose}
              />
            )}
          </NestedQuestions>
        );
    }
  };

  return <QuestionContent>{QuestionTypeRender()}</QuestionContent>;
};

interface QuestionProps {
  question: IQuestion;
  currentIndex: number;
  onBack: () => void;
  onResult: () => void;
  readonly: boolean;
  queueId: string;
  control: TopLevelControl;
  settings?: SettingsViewModel[];
  questionnaireInfo?: QuestionnaireForwardingViewModel;
}

export const Question = (props: QuestionProps) => {
  return (
    <QuestionCard>
      <QuestionCardContent>
        <QuestionHeader
          text={props.question.heading}
          index={props.currentIndex}
          onBack={() => {
            props.onBack();
          }}
        />
        <QuestionItem
          key={props.question.id}
          readonly={props.readonly}
          question={props.question}
          queueId={props.queueId}
          control={props.control}
          type={props.question.type}
          subQuestions={props.question.subQuestions}
          onResult={props.onResult}
          settings={props.settings}
          questionnaireInfo={props.questionnaireInfo}
        />
      </QuestionCardContent>
    </QuestionCard>
  );
};
