import { useSnackbar } from "notistack";
import React, { useState, useContext, useEffect } from "react";
import { Manager } from "../../../../components/core/Manager/Manager";
import {
    PersonsClient,
    PersonSearchQueryViewModel,
  TeamClient,
  TeamViewModel,
  UserTeamViewModel,
} from "../../../../common/client";
import { useApiClient } from "../../../../common/apiclients";
import { Context } from "../../../../state/Store";

const TeamManager = () => {
  const { state } = useContext(Context);
  const personsClient = useApiClient(PersonsClient);
  const [teams, setTeams] = useState<TeamViewModel[]>([]);
  const [currentTeam, setCurrentTeam] = useState<any>();
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const teamClient = useApiClient(TeamClient);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [teamId, setTeamId] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    teamClient.getAllTeams().then((x) => setTeams(x));
  }, []);

  const teamDataMapper = (roles: any[]) => {
    return roles.map((x: any) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  };

  async function onRemove() {
    if (selectedItems.length > 0) {
      const userteam: UserTeamViewModel[] = selectedItems.map((x: any) => {
        let temp: UserTeamViewModel = {
          personId: x,
          teamId: teamId,
          canManageTeamMembers: false,
          canViewTeam: false,
        };
        return temp;
      });

      await teamClient
        .removeUsersFromTeam(userteam)
        .then(() => {
          enqueueSnackbar("Person(s) successfully removed", {
            variant: "success",
          });
        })
        .catch((err) => {
          enqueueSnackbar("Error occourd while removed person(s)", {
            variant: "error",
          });
        });

      setTimeout(() => {}, 500);
      teamClient.getUsersForTeam(teamId).then((response) => {
        setCurrentItems(response);
      });
      setSelectedItems([]);
    }
  }

  const onResultSelectHandler = (selectedId: string) => {
    selectedItems.find((x: any) => x == selectedId)
      ? setSelectedItems(selectedItems.filter((x) => x !== selectedId))
      : setSelectedItems([...selectedItems, selectedId]);
  };

  const onCheckBoxCheckedHandler = (node: any[]) => {
    setSelectedItems([]);
    if (node[0]) {
      setTeamId(node[0]);
      teamClient.getUsersForTeam(node[0]).then((response) => {
        setCurrentItems(response);
      });
    } else {
      setTeamId("");
      setCurrentItems([]);
    }
  };

  async function onAddMemberHandler(members: any[]) {
      
    const userTeams: UserTeamViewModel[] = members.map((x: any) => {
      let temp: UserTeamViewModel = {
        personId: x.id,
        teamId: teamId,
        canManageTeamMembers: false,
        canViewTeam: false,
      };
      return temp;
    });

    await teamClient
      .addUsersToTeam(userTeams)
      .then(() => {
        enqueueSnackbar("Person(s) successfully added", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Error occourd while adding person(s)", {
          variant: "error",
        });
      });

    setTimeout(() => {}, 500);
    teamClient.getUsersForTeam(teamId).then((response) => {
      setCurrentItems(response);
    });
  }

  const matchQuery = (currentArr: any[]) => {
    const matcher = (name: string, query: string) => {
      if (!query || query === "") return true;

      // remove space in name
      const newName = name.replace(/\s/g, "").toLowerCase();
      const newQuery = query.replace(/\s/g, "").toLowerCase();
      return newName.includes(newQuery);
    };

    return currentArr.filter((x: any) => matcher(x.firstName + x.lastName, searchQuery));
  };

  function onItemSearch(searchString: string): Promise<any> {
    const person: PersonSearchQueryViewModel = {
      query: searchString,
      accountType: undefined,
    };

    return personsClient.searchPersons(person);
  }

  return (
    <Manager
      name="Departments"
      treeData={teamDataMapper(
        teams.sort((a, b) => a.name!.localeCompare(b.name!))
      )}
      onCheckBoxChecked={onCheckBoxCheckedHandler}
      currentItems={matchQuery(currentItems)}
      onResultSelect={onResultSelectHandler}
      selectedItems={selectedItems}
      onSearchBarSearch={setSearchQuery}
      onAdd={onAddMemberHandler}
      onRemove={onRemove}
      onItemSearch={onItemSearch}
      disableToolBar={!(teamId.length > 0)}
    />
  );
};

export default TeamManager;
