import {
  Backdrop,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import nyrstar1 from "../assets/images/nyrstar1.jpg";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../state/Store";
import { PublicQuestionnaire } from "../views/Questionnaire";
import {
  QuestionnaireIndex,
  SettingsViewModel,
  SettingsClient,
} from "../common/client";
import { useApiClient } from "../common/apiclients";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
    paperCenter: {
      marginTop: theme.spacing(40),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {},
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
  })
);

const VisitorAppliactionHost = () => {
  const { state } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [completed, setCompleted] = useState<string>("");
  const settingsClient = useApiClient(SettingsClient);
  const [settings, setSettings] = useState<SettingsViewModel[]>([]);
  const showStepper = window.innerWidth > 699 ? true : false;

  const preLoadData = () => {
    settingsClient.getAllSettings().then((res) => {
      setSettings(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    preLoadData();
  }, []);

  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);

  return (
    <div>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!loading && !completed && (
        <PublicQuestionnaire
          token={state.user?.token}
          questionnaireIndex={QuestionnaireIndex.Site_Entry_Application}
          completed={(completed: string) => setCompleted(completed)}
          showStepper={showStepper}
          settings={settings}
        />
      )}

      {!loading && completed.length > 1 && (
        <div className={classes.paperCenter}>
          <img className={classes.image} src={nyrstar1} alt="Site Logo" />
          <Typography className={classes.heading}>
            Thank you for submitting the Application
          </Typography>
          <Typography>
            Your application is now waiting for the visitor to complete their
            part.
          </Typography>
          <Typography>
            You will be notified by email of the approval result.
          </Typography>
        </div>
      )}

      {!loading && completed.length == 1 && (
        <div className={classes.paperCenter}>
          <img className={classes.image} src={nyrstar1} alt="Site Logo" />
          <Typography className={classes.heading}>
            Error submitting the Application
          </Typography>
          <Typography>A email is incorrect.</Typography>
        </div>
      )}
    </div>
  );
};

export default VisitorAppliactionHost;
