import React, { ReactNode, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Slide,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import Moment from "react-moment";
import { PersonKind, User } from "../state/State.model";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  dayPassStyles,
} from "../styles/common";
import { Details } from "../components/core/Templates/Details";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  MapMarkerAltSolid,
  MapPinSolid,
  StreetViewSolid,
} from "../components/Icons/DoHIcons";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Context } from "../state/Store";
import { format } from "date-fns";

const StyledTypography = withStyles((theme) => ({
  body1: {
    fontSize: "1.5rem!important",
    textAlign: "center",
  },
}))(Typography);

export const CurrentStatusView = (props: any) => {
  const { state, dispatch } = useContext(Context);
  let classes = dayPassStyles();
  let user = state.user as User;

  const [expanded, setExpanded] = React.useState<string | false>();
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const userOffset = new Date().getTimezoneOffset()*60*1000;

  const header = () => {
    return (
      <>
        <CheckCircleOutlineIcon className={classes.icon} />
        <Typography className={classes.cardHeaderText}>
          {!props.checkout
            ? user.firstName + " " + user.lastName + ", checked in at"
            : user.firstName + " " + user.lastName + ", checked out at"}
        </Typography>
      </>
    );
  };
  let date = (
    <Typography>
      {!props.checkout ? format(new Date(props.checkInTime - userOffset), 'EEE HH:mm, dd-MM-yyyy')   :  format(new Date(props.checkOutTime - userOffset), 'EEE HH:mm, dd-MM-yyyy')}
    </Typography>
  );
  const result = (): JSX.Element => {
    return (
      <>
        <StyledTypography>THANK YOU</StyledTypography>
        {!props.checkout ? (
          <StyledTypography>PLEASE PROCEED</StyledTypography>
        ) : (
          <StyledTypography>FOR CHECKING OUT</StyledTypography>
        )}
      </>
    );
  };
  let lastLocation = props.location;

  return (
    <Slide in={true} direction={"left"}>
      <div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List>
              <ListItem className={clsx(classes.cardHeader)}>
                {header()}
              </ListItem>
              <Details text={date} icon={<ScheduleOutlinedIcon />} />
              <Details text={lastLocation} icon={<StreetViewSolid />} />

              {props.user?.locationHistory?.length! > 1 && (
                <Accordion
                  square
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>
                      {`${expanded === "panel1" ? "Hide" : "Show"}`} Location
                      History
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {props.user?.locationHistory?.map(
                      (s: any, index: number) => (
                        <Details text={s} icon={<StreetViewSolid />} />
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              <Details text={result()} hideDivider={true} />
            </List>
          </CardContent>
        </Card>

        {!props.checkout && (
          <>
            <Box
              className={classes.box}
              style={{ maxWidth: `${props.maxWidth}px` }}
            >
              <ButtonGroup variant={"contained"} fullWidth>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    props.onCheckout();
                  }}
                >
                  Checkout
                </Button>
              </ButtonGroup>
            </Box>
            <Divider
              variant="middle"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
          </>
        )}

        <Box
          className={classes.box}
          style={{ maxWidth: `${props.maxWidth}px` }}
        >
          <ButtonGroup variant={"contained"} fullWidth>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                props.updateProfile(true);
              }}
            >
              Update Details
            </Button>
          </ButtonGroup>
        </Box>
      </div>
    </Slide>
  );
};
