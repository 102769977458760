import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "./state/Store";
import { PublicQuestionnaire } from "./views/Questionnaire";
import {
  QuestionnaireIndex,
  SettingsViewModel,
  SettingsClient,
  TeamViewModel,
  UserClient,
  DepartmentIdentifier,
} from "./common/client";
import { TokenContext, useApiClient } from "./common/apiclients";
import { ValidationTextField } from "./components/muiCore/TextField";
import { ValidatedForm } from "./components/core/forms/ValidatedForm";
import nyrstar1 from "./assets/images/nyrstar1.jpg";
import { getAllSettings } from "./data/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    paperCenter: {
      marginTop: theme.spacing(40),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      // Thomas changed teh 60 to 100
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        width: "64px",
        height: "64px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
    image: {},
    selectField: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    error: {
      color: "red",
      paddingLeft: "14px",
      fontSize: "0.9rem!important",
      letterSpacing: "0.0075rem",
    },
  })
);

const VisitorQuestionnaire = (props: any) => {
  const URL = window.location.href.split("/");
  const URLData = URL[URL.length - 1];
  const { state } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [token, setToken] = useState<string>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [departmentList, setDepartmentList] = useState<TeamViewModel[]>([]);
  const userClient = useApiClient(UserClient);
  const [questionnaire, setQuestionnaire] = useState<boolean>(false);
  const [completed, setCompleted] = useState<string>("");
  const settingsClient = useApiClient(SettingsClient);
  const [department, setDepartment] = useState("");
  const [response, setresponse] = useState<any>([]);
  const [settings, setSettings] = useState<SettingsViewModel[]>([]);
  const showStepper = window.innerWidth > 699 ? true : false;
  const [registered, setRegistered] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState({
    mobile: {
      valid: true,
      errorMessage: "",
    },
    firstName: {
      valid: true,
      errorMessage: "",
    },
    lastName: {
      valid: true,
      errorMessage: "",
    },
    department: {
      valid: true,
      errorMessage: "",
    },
  });

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (!firstName) {
      errors.firstName.valid = false;
      errors.firstName.errorMessage = "First name can not be blank";
      valid = false;
    } else {
      errors.firstName.valid = true;
      errors.firstName.errorMessage = "";
    }

    if (!lastName) {
      errors.lastName.valid = false;
      errors.lastName.errorMessage = "Last name can not be blank";
      valid = false;
    } else {
      errors.lastName.valid = true;
      errors.lastName.errorMessage = "";
    }

    if (!contactNumber) {
      errors.mobile.valid = false;
      errors.mobile.errorMessage = "Contact number can not be blank";
      valid = false;
    } else {
      errors.mobile.valid = true;
      errors.mobile.errorMessage = "";

      if (!/^[0][0-9]{9}$|^[1300][0-9]{9}$/.test(contactNumber)) {
        errors.mobile.valid = false;
        errors.mobile.errorMessage =
          "Please provide valid contact number 10 digits with no spaces - eg. 0400123456 or 0362291643";
        valid = false;
      }
    }

    // if (!department) {
    //   errors.department.valid = false;
    //   errors.department.errorMessage = "Department name can not be blank";
    //   valid = false;
    // } else {
    //   errors.department.valid = true;
    //   errors.department.errorMessage = "";
    // }

    setFormErrors(errors);
    return valid;
  };
  const handleDepartmentChange = (event: any) => {
    setDepartment(event.target.value as string);
  };

  const onSubmit = () => {
    if (isFormValid()) {
      userClient
        .authGate({
          firstName: firstName,
          lastName: lastName,
          contactNumber: contactNumber,
          kind: 1,
          department: departmentList[0].id,
        })
        .then((d) => {
          setresponse(d);
          registerResponse(d);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const registerResponse = (response: any) => {
    setToken(response.token);
    getAllSettings(response.token).then((settings:SettingsViewModel[])=>{
      setSettings(settings);
      setLoading(false)
      setRegistered(true)
      setQuestionnaire(true);
    });
  };

  const fetchQuestionaireData = () => {
    return (
      <TokenContext.Provider value={response.token}>
              {settingsClient.getAllSettings().then((res) => {
       setSettings(res);
     })}
      </TokenContext.Provider> 
    )
  }
  

  const preLoadData = () => {
   
     userClient.getAllTeams().then((res) => {
       //currently all are port pirie visitors as hobart has no application
      let list = res.filter(
        (x: TeamViewModel) =>
          x.departmentIdentifier !== DepartmentIdentifier.Port_Pirie_Visitor
      );
      setDepartmentList(list);
      setLoading(false);
    });
    
  };

  useEffect(() => {
      preLoadData();
  }, []);

  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);

  return (
    <div>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!loading && !completed && registered && token.length > 0 &&(
        <TokenContext.Provider value={token}>
        <PublicQuestionnaire
          token={token}
          questionnaireIndex={QuestionnaireIndex.Site_Entry_Application}
          completed={(completed: string) => setCompleted(completed)}
          showStepper={showStepper}
          settings={settings}
          existingQuestionnaire={URLData}
        />
        </TokenContext.Provider>
      )}

      {!loading && completed && registered && (
        <div className={classes.paperCenter} >
        <img className={classes.image} src={nyrstar1} alt="Site Logo" />
        <Typography className={classes.heading}>Thank you for submitting the Application</Typography>
        <Typography >Your application is now waiting for approval. You will be contacted by email once it is approved.</Typography>
        </div>
      )}

      {!loading && !questionnaire && !registered && (
        <Container
          component="main"
          maxWidth="sm"
          style={{ marginBottom: "60px", marginTop: "50px" }}
        >
          <div className={classes.paper}>
            <img className={classes.image} src={nyrstar1} alt="Site Logo" />
            <Typography className={classes.heading}>Visitor Details</Typography>
            <ValidatedForm>
              <ValidationTextField
                error={!formErrors.firstName.valid}
                helperText={formErrors.firstName.errorMessage}
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
                required
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                }}
                variant="outlined"
                disabled={loading}
              />
              <ValidationTextField
                error={!formErrors.lastName.valid}
                helperText={formErrors.lastName.errorMessage}
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                required
                onChange={(e: any) => {
                  setLastName(e!.target.value);
                }}
                variant="outlined"
                validation={{ required: true }}
                disabled={loading}
              />
              <ValidationTextField
                variant="outlined"
                error={!formErrors.mobile.valid}
                helperText={
                  !formErrors.mobile.valid ? formErrors.mobile.errorMessage : ""
                }
                margin="normal"
                fullWidth
                id="mobile"
                label="Phone number (mobile preferred)"
                name="mobileNumber"
                required
                onChange={(e: any) => {
                  setContactNumber(e.target.value);
                }}
                type={"tel"}
                disabled={loading}
              />
              {/* <FormControl fullWidth>
                <InputLabel
                  className={classes.selectField}
                  error={!formErrors.department.valid}
                  id="demo-simple-select-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-6px",
                    }}
                  >
                    Your Department *
                  </div>
                </InputLabel>
                <Select
                  error={!formErrors.department.valid}
                  className={classes.selectField}
                  variant="outlined"
                  fullWidth
                  value={department}
                  label="Your Department *"
                  onChange={handleDepartmentChange}
                  disabled={loading}
                >
                  {departmentList.length > 0 ? (
                    departmentList.map((element: TeamViewModel) => {
  
                      return (
                        <MenuItem value={element.id}>{element.name}</MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                      Departments not found
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className={classes.error}>
                  {!formErrors.department.valid
                    ? formErrors.department.errorMessage
                    : ""}
                </FormHelperText>
              </FormControl> */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={cbxRememberMe}
                    onChange={handleCBXChange}
                  />
                }
                label="Remember me"
              /> */}
              <br />
              <Button
              style={{marginTop: "20px"}}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setLoading(true);
                  onSubmit();
                }}
              >
                {loading && <CircularProgress style={{ color: "white" }} />}
                {!loading && "Next"}
              </Button>
            </ValidatedForm>
          </div>
          <Divider variant="middle" />
        </Container>
      )}
    </div>
  );
};

export default VisitorQuestionnaire;
