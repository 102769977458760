import { useSnackbar } from "notistack";
import React, { useState, useContext, useEffect } from "react";
import { Manager } from "../../../../components/core/Manager/Manager";
import {
  PersonsClient,
  PersonSearchQueryViewModel,
  TeamClient,
  TeamViewModel,
  UserTeamViewModel,
  QueueClient,
  QueueChoiceVm,
  UserInfoViewModel,
} from "../../../../common/client";
import { useApiClient } from "../../../../common/apiclients";
import { Context } from "../../../../state/Store";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

const PrimaryLocationManager = () => {
  const { state } = useContext(Context);
  const personsClient = useApiClient(PersonsClient);
  const [locations, setLocations] = useState<QueueChoiceVm[]>([]);
  const [currentTeam, setCurrentTeam] = useState<any>();
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const queueClient = useApiClient(QueueClient);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [locationId, setLocationId] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    queueClient.getSiteLocations().then((x) => {
      x = x.filter((y) => y.name !== "Covid Questionnaire");
      setLocations(x);
    });
  }, []);

  const teamDataMapper = (locations: any[]) => {
    let sites = locations.filter(
      (site: QueueChoiceVm) => site.outcomeParentId === null
    );
    sites.forEach((site) => {
      let buildings = locations.filter(
        (bulding: QueueChoiceVm) => bulding.outcomeParentId === site.id
      );
      site.children = buildings;
      buildings.forEach((building: QueueChoiceVm) => {
        building.children = locations.filter(
          (room: QueueChoiceVm) => room.outcomeParentId === building.id
        );
      });
    });

    return sites.map((o: QueueChoiceVm) => ({
      name: o.name,
      label: o.name,
      value: o.id,
      children:
        o.children!.length > 0
          ? o.children!.map((a: QueueChoiceVm) => ({
              name: a.name,
              label: a.name,
              value: a.id,
              children:
                a.children!.length > 0
                  ? a.children!.map((a: QueueChoiceVm) => ({
                      name: a.name,
                      label: a.name,
                      value: a.id,
                      children: a.children,
                    }))
                  : null,
            }))
          : null,
    }));
  };

  async function onRemove() {
      const ids = selectedItems;
    if (selectedItems.length > 0) {
      const usersId: UserInfoViewModel[] = selectedItems.map((x: any) => {
        let temp: UserInfoViewModel = {
            id: x.id,
            firstName: x.firstName,
            lastName: x.lastName,
            contactNumber: x.contactNumber,
            primaryLocation: locationId,
            department: x.department,
        };
        return temp;
      });

        await personsClient
          .removeUsersFromPrimaryLocation(ids)
          .then(() => {
            enqueueSnackbar("Person(s) successfully removed", {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar("Error occourd while removed person(s)", {
              variant: "error",
            });
          });

        setTimeout(() => {}, 500);
        personsClient.getUsersForPrimaryLocation(locationId).then((response) => {
          setCurrentItems(response);
        });
     setSelectedItems([]);
   }
  }

  const onResultSelectHandler = (selectedId: string) => {
    selectedItems.find((x: any) => x == selectedId)
      ? setSelectedItems(selectedItems.filter((x) => x !== selectedId))
      : setSelectedItems([...selectedItems, selectedId]);
  };

  const onCheckBoxCheckedHandler = (node: any[]) => {
    setSelectedItems([]);
    if (node[0]) {
      setLocationId(node[0]);
      personsClient.getUsersForPrimaryLocation(node[0]).then((response) => {
        setCurrentItems(response);
      });
    } else {
      setLocationId("");
      setCurrentItems([]);
    }
  };

  async function onAddMemberHandler(members: any[]) {
    const userLocations: UserInfoViewModel[] = members.map((x: any) => {
      let temp: UserInfoViewModel = {
        id: x.id,
        firstName: x.firstName,
        lastName: x.lastName,
        contactNumber: x.contactNumber,
        primaryLocation: locationId,
        department: x.department,
      };
      return temp;
    });

    await personsClient
      .addUsersToPrimaryLocation(userLocations)
      .then(() => {
        enqueueSnackbar("Person(s) successfully added", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Error occourd while adding person(s)", {
          variant: "error",
        });
      });

    setTimeout(() => {}, 500);
    personsClient.getUsersForPrimaryLocation(locationId).then((response) => {
      setCurrentItems(response);
    });
  }

  const matchQuery = (currentArr: any[]) => {
    const matcher = (name: string, query: string) => {
      if (!query || query === "") return true;

      // remove space in name
      const newName = name.replace(/\s/g, "").toLowerCase();
      const newQuery = query.replace(/\s/g, "").toLowerCase();
      return newName.includes(newQuery);
    };

    return currentArr.filter((x: any) =>
      matcher(x.firstName + x.lastName, searchQuery)
    );
  };

  function onItemSearch(searchString: string): Promise<any> {
    const person: PersonSearchQueryViewModel = {
      query: searchString,
      accountType: undefined,
    };

    return personsClient.searchPersons(person);
  }

  return (
    <Manager
      name="Locations"
      treeData={teamDataMapper(locations)}
      onCheckBoxChecked={onCheckBoxCheckedHandler}
      currentItems={matchQuery(currentItems)}
      onResultSelect={onResultSelectHandler}
      selectedItems={selectedItems}
      onSearchBarSearch={setSearchQuery}
      onAdd={onAddMemberHandler}
      onRemove={onRemove}
      onItemSearch={onItemSearch}
      disableToolBar={!(locationId.length > 0)}
    />
  );
};

export default PrimaryLocationManager;
