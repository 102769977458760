import React, {useEffect} from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {ExclamationSolid, UserSolid} from "../../Icons/DoHIcons";
import {MessageCard} from "../../muiCore/Card";
import {Divider} from "@material-ui/core";
import {OutcomeDetailsListItem} from "../../muiCore/ListItem";
import {OutcomeList} from "../../muiCore/List";

import {InformationCard} from "../../muiCore/Card";
import {useMediaQuery,useTheme} from "@material-ui/core";



const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            // Thomas changed it light to dark
            backgroundColor:theme.palette.primary.dark,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            //color: theme.palette.grey[500],
            // Thomas changed the main to contrastText
            color: theme.palette.primary.contrastText,
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {/* Thomas changed h5 to h4 */}
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const Message = (props:any) => {
    
    const { onSubmit, onClose, value: valueProp, open, ...other } = props;
    //const [open, setOpen] = React.useState(false);

    // Thomas 06/24/21 text for dynamic
    const informationOption = ["This option is free", "7:30am to 2:30pm daily"]

    const handleClose = () => {
        onClose();
    };
    const handleCancel = () => {
        onClose();
    };
    // Thomas ADDed
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        
        <div>
            {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
            {/*    Open dialog*/}
            {/*</Button>*/}
            <Dialog fullScreen={isMobile} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title ?? 'No Booking Required'}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {props.text ?? 'If you want a test at the Burnie or East Devonport clinics, please go to the testing site as these sites do not take bookings.'}
                    </Typography>

                    {props.children}
                </DialogContent>

                {/*Thomas 06/23/21*/}
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
