import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cx from "clsx";
import {
  QuestionChoice,
  QuestionChoiceViewModel,
} from "../../../common/client";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import { FormControl, useControlValue } from "@react-typed-forms/core";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
      margin: "unset",
      maxWidth: "unset!important",
    },
    display: "flex!important",
    textAlign: "center",
  },
  btnActive: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  btnStartIcon: {
    width: "35px",
    height: "35px",
  },
  w50: {
    width: "50%",
  },
}));

export const QuestionItemNestedYesNoButtons = ({
  buttons,
  control,
  readonly,
  ...props
}: {
  buttons: QuestionChoiceViewModel[] | undefined;
  control: FormControl<string | undefined>;
  readonly?: boolean;
  maxWidth?: string;
  hideCheckbox?: boolean;
}) => {
  const classes = useStyles();
  const selectedChoice = useControlValue(control);

  return (
    <ButtonGroup variant={"contained"} fullWidth>
      {buttons &&
        buttons.map((button: QuestionChoiceViewModel, i: number) => {
          const selected = button.id === selectedChoice;
          return (
            <ListItem
              button
              className={classes.w50}
              disabled={readonly}
              onClick={() => {
                control.setValue(
                  selectedChoice === button.id ? undefined : button.id
                );
              }}
            >
              <ListItemIcon>
                {selected ? (
                  <RadioButtonCheckedOutlinedIcon
                    className={`${classes.btnStartIcon}  ${
                      selected ? "selected" : ""
                    }`}
                  />
                ) : (
                  <RadioButtonUncheckedOutlinedIcon
                    className={`${classes.btnStartIcon}  ${
                      selected ? "selected" : ""
                    }`}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={button.text} />
            </ListItem>
            // <Divider />
          );
        })}
    </ButtonGroup>
  );
};

export const QuestionItemYesNoButtons = (props: {
  buttons: QuestionChoiceViewModel[] | undefined;
  onSelected: (selected: QuestionChoiceViewModel) => void;
  selected?: string;
  readonly?: boolean;
  maxWidth?: string;
  hideCheckbox?: boolean;
}) => {
  const classes = useStyles();
  const [selectedButton, setSelectedButton] =
    useState<QuestionChoiceViewModel>();

  useEffect(() => {
    if (selectedButton && props.onSelected) {
      props.onSelected(selectedButton);
    }
  }, [selectedButton]);

  return (
    <ButtonGroup variant={"contained"} fullWidth>
      {props.buttons &&
        props.buttons.map((button: QuestionChoiceViewModel, i: number) => {
          const selected = props.selected
            ? props.selected === button.id
            : (button as any).selected;
          return (
            <Button
              className={`${selected ? classes.btnActive : ""}`}
              disabled={props.readonly}
              onClick={() => {
                setSelectedButton(button);
              }}
              startIcon={
                !props.hideCheckbox ? (
                  selected ? (
                    <CheckBoxOutlinedIcon className={classes.btnStartIcon} />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      className={classes.btnStartIcon}
                    />
                  )
                ) : (
                  <></>
                )
              }
            >
              <Typography variant={"body2"}>{button.text}</Typography>
            </Button>
          );
        })}
    </ButtonGroup>
  );
};
