import React, { useEffect, useContext, useState } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router";
import { RegionSelect } from "./RegionSelect";
import { Registration } from "./Registration";
import { PublicQuestionnaire } from "./Questionnaire";
import { Finalisation } from "./Finalisation";
import Dashboard from "../modules/admin/views/Dashboard";
import { QRLanding } from "./QRLanding";
import { CurrentStatus } from "./CurrentStatus";
import { AccessDenied } from "./AccessDenied";
import { InterstateVisitorQuestionnaire } from "./InterstateVisitorQuestionnaire";
import VisitorApplicatonHost from "./VisitorAppliactionHost";
import VisitorQuestionnaire from "./../VisitorQuestionnaire";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <CurrentStatus />
      </Route>
      <Route exact path="/QR/:id">
        <QRLanding />
      </Route>
      <Route exact path="/registration">
        <Registration />
      </Route>
      <Route path="/questionnaire">
        <PublicQuestionnaire />
      </Route>
      <Route exact path="/questionnaire/finalisation">
        <Finalisation />
      </Route>
      <Route exact path="/admin">
        <Dashboard />
      </Route>
      <Route exact path="/accessdenied">
        <AccessDenied />
      </Route>
      <Route exact path="/i/id">
        <InterstateVisitorQuestionnaire />
      </Route>
      <Route exact path="/Visitor_Application_Host_Form">
        <VisitorApplicatonHost />
      </Route>
      {/* <Route exact path="/VisitorQuestionnaire/:id">
        <VisitorQuestionnaire />
      </Route> */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
