import React, { useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "./App.css";
import { Backdrop, CircularProgress, useTheme } from "@material-ui/core";
import { Routes } from "./views/Routes";
import { Context } from "./state/Store";
import { getUserInfo } from "./data/api";
import { useSnackbar } from "notistack";
import { Registration } from "./views/Registration";
import { TokenContext, useApiClient } from "./common/apiclients";
import { UserClient, TeamViewModel } from "./common/client";
import { useCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    cookieConsent: {
    backgroundColor: theme.palette.primary.main, 
    color: "black"
    },
  })
);

function App() {
  const { state, dispatch } = useContext(Context);
  const classess = useStyles();
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem("Token")
  );
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [register, setRegister] = useState<boolean>(false);
  const userClient = useApiClient(UserClient);
  const [teams, setTeams] = useState<TeamViewModel[]>();
  const [cookies, setCookie] = useCookies(["Token","CookieConsent"]);
  const [iOS, setiOS] = useState<boolean>(false);
  const [cookieAuthorised, setCookieAuthorised] = useState<boolean>(
    cookies.CookieConsent ? true : false
  );
  const theme = useTheme();

  useEffect(() => {
    iOSCheck();
  }, []);

  useEffect(() => {
    if (authToken) {
      getUserInfo(authToken)
        .then((d) => {
          dispatch({
            type: "SET_USERINFO",
            payload: {
              ...state.user,
              firstName: d.firstName,
              lastName: d.lastName,
              contactNumber: d.contactNumber,
              id: d.id,
              token: authToken,
              rememberMe: state.user?.rememberMe,
              department: d.department,
              departments: d.departments,
              primaryLocation: d.primaryLocation,
              locations: d.locations,
            },
          });
          if (iOS && cookies.CookieConsent === true) {
            setCookie("Token", authToken, {
              path: "/",
              secure: true,
              sameSite: "strict",
              expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setAuthToken(null);
        });
    } else {
      if (iOS) {
        if (cookies.Token) {
          setAuthToken(cookies.Token);
        } else {
          userClient.getAllTeams().then((res) => {
            setTeams(res);
          });
          setRegister(true);
          setLoading(false);
        }
      } else {
        userClient.getAllTeams().then((res) => {
          setTeams(res);
        });
        setRegister(true);
        setLoading(false);
      }
    }
  }, [authToken]);

  useEffect(() => {
    setRegister(false);
    setAuthToken(state.user!.token);
  }, [state.user]);

  const iOSCheck = () => {
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    ) {
      <CookieConsent
        onAccept={() => {
          setCookieAuthorised(true);
        }}
        enableDeclineButton
        onDecline={() => {
          setCookieAuthorised(false);
        }}
      >
        This website uses cookies to prefill user details only.
      </CookieConsent>;
      setiOS(true);
    }
  };

  return (
    <div className={"site-container"}>
      <main id={"main-content"}>
        <div className="content">
          {iOS && !cookieAuthorised && (
            <>
            <CookieConsent
              onAccept={() => {
                setCookieAuthorised(true);
              }}
              buttonText={"Accept"}
              buttonStyle={{backgroundColor: theme.palette.primary.main, color:theme.palette.secondary.main}}
              enableDeclineButton
              onDecline={() => {
                setCookieAuthorised(false);
              }}
              declineButtonText={"Decline"}
              declineButtonStyle={{backgroundColor: theme.palette.primary.main,color:theme.palette.secondary.main}}
            >
              This website uses cookies to pre-fill user details. Please accept cookies to allow a single sign-in experience.
            </CookieConsent>
            </>
          )}
          {loading && (
            <Backdrop className={classess.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {authToken && !loading && state.user!.id && (
            <TokenContext.Provider value={authToken}>
              <Routes />
            </TokenContext.Provider>
          )}
          {register && <Registration departments={teams} />}
        </div>
      </main>
    </div>
  );
}

export default App;
