import React, {ReactElement, useState, useEffect, CSSProperties} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
//Thomas Xu 
import Hidden from '@material-ui/core/Hidden';
import {useMediaQuery} from "@material-ui/core";
//end of added
import CardMedia from "@material-ui/core/CardMedia";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {
    CarSideSolidInformation,
    DollarSignSolid,
    ClockSolid,
    AngleRight,
    AngleLeft,
    PlayCircle,
    PauseCircle
} from "../Icons/DoHIcons";

import {Avatar, Box, CardContent, Grid, Icon, List, Slide, Slider, Typography, useTheme} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";
import {StyledContainer} from "./Container";
import InfoIcon from '@material-ui/icons/Info';
import CallIcon from '@material-ui/icons/Call';
import {OutcomeDetailsListItem} from "./ListItem";
import {OutcomeList} from "./List";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            icon: {
                color: theme.palette.primary.contrastText,
                maxWidth: 'unset',
                maxHeight: 'unset',
                fontSize: '5rem!important',
                //margin: '10px!important',
                [theme.breakpoints.down("sm")]: {
                    pointerEvents: 'none'
                },
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
            },
            contentIcon: {
                color: theme.palette.primary.light,
                maxWidth: 'unset',
                maxHeight: 'unset',
                fontSize: theme.typography.body2.fontSize,
                margin: '0 5px 0 0px!important',
                [theme.breakpoints.down("sm")]: {
                    pointerEvents: 'none'
                },
            },
            disabled: {
                "& h1": {
                    color: "gray!important",
                },
                "& p": {
                    color: "gray!important",
                },
            },
            alert: {
                '&.info': {
                    boxShadow: `${theme.DoH.color.info} 0px 1px 3px 0px, ${theme.DoH.color.info} 0px 0px 0px 1px`,
                },
                '&.error': {
                    boxShadow: `${theme.DoH.color.error} 0px 1px 3px 0px, ${theme.DoH.color.error} 0px 0px 0px 1px`,
                }
                ,
                '&.warning': {
                    boxShadow: `${theme.DoH.color.warning} 0px 1px 3px 0px, ${theme.DoH.color.warning} 0px 0px 0px 1px`,
                },
                cursor: 'default',
                '&:hover': {
                    boxShadow: '#2196f3 0px 1px 3px 0px, #2196f3 0px 0px 0px 1px',
                },
                '&.noHover': {
                    pointerEvents: 'none'
                }
            },
            alertBox: {
                '&.info': {
                    backgroundColor: theme.DoH.color.info + '!important',
                },
                '&.error': {
                    backgroundColor: theme.DoH.color.error + '!important',
                },
                '&.warning': {
                    backgroundColor: theme.DoH.color.warning + '!important',
                },
                '&.conditional': {
                    backgroundColor: theme.DoH.color.conditional + '!important',
                },
                '&.New': {
                    backgroundColor: theme.DoH.color.warning
                },
                '&.InProgress': {
                    backgroundColor: theme.DoH.color.exemption
                },
                '&.OnHold': {
                    backgroundColor: theme.DoH.color.fail
                },
                '&.Closed': {
                    backgroundColor: theme.DoH.color.pass
                },
            },
            clickable: {
                cursor: 'pointer'
            },
            messageIcon: {
                color: theme.palette.primary.contrastText,
                maxWidth: 'unset',
                maxHeight: 'unset',
                fontSize: '3.5rem!important',
                //margin: '25px!important',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '2rem!important',
                }
            },
            options: {
                display: 'flex',
                alignItems: 'center',
                // paddingLeft: theme.spacing(1),
                // paddingBottom: theme.spacing(1),
                marginTop: theme.spacing(4),
                //color:theme.palette.primary,
                [theme.breakpoints.down('sm')]: {
                    alignItems: 'center',
                    marginTop: theme.spacing(2),//todo use theme.spacing
                    display: 'grid',
                    // Thomas modified 
                    // justifyContent: 'center',
                    alignContent: 'center',
                }
            },
            messageAvatar: {
                width: '5rem',
                height: '5rem',
                // thomas added
                [theme.breakpoints.down('sm')]: {
                    width: '3rem',
                    height: '3rem',
                    position: 'absolute',
                    top: '6%',
                },
                [theme.breakpoints.down('md')]: {
                    marginLeft: theme.spacing(1)
                },
            },
            informationAvatar: {
                width: '3rem',
                height: '3rem',
                // Thomas Commoned out
                // marginLeft:'30px',
                //Thomas 28/06/21
                marginTop: '5px',
                // Thomas change the color from theme
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    marginLeft: theme.spacing(0),
                }
            },
            optionStyle: {
                display: 'inline-flex',
            },
            //Thomas 28/06/21
            textColor: {
                // change to theme color
                color: theme.palette.primary.contrastText,
            },
            // thomas added
            cardContantStyle: {
                marginLeft: '0px',
                marginTop: '0px',
                paddingLeft: '0px',
                paddingTop: '0px',
                fontFamily: 'interstate',
            },
            bannerControlStyle: {
                marginRight: theme.spacing(2),
                paddingTop: '5px',
                backgroundColor: 'rgba(53,53,53,0.95)',
                borderRadius: '0.25rem',
                textAlign: 'center',
                marginLeft: 'calc(100% - 15%)',
                marginBottom: '10px',
                position: 'inherit',
                maxWidth: '13%',
                [theme.breakpoints.down('md')]: {
                    // marginRight:'90%'
                    marginRight: '10px',
                },
            },
            bannerPlayButtonStyle: {
                padding: '0px',
                borderRadius: '999px',
                width: '1.5625rem',
                height: '1.5625rem',
                color: theme.palette.primary.contrastText,
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                backgroundColor: theme.palette.primary.light,
                cursor:'pointer'
            },
            bannerPreviousNextButtonStyle: {
                width: '1.5625rem',
                height: '1.5625rem',
                color: theme.palette.primary.contrastText,
                cursor:'pointer'
            },
            bannerDivStyle: {
                display: "flex",
                marginLeft: '17%',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '12%',
                },
            },
            details: {
                display: "flex",
                flexDirection: "column"
            },
            noIcon:{
                width: '30px!important',
            }
            // end added
        }
    ))
const StyledCard = withStyles((theme) => ({
    root: {
        maxWidth: '100%!important',
        
        cursor: 'pointer',
        WebkitTransition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        '&:hover': {
            boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 12px 16px 0px rgb(0 0 0 / 14%), 0px 4px 32px 0px rgb(0 0 0 / 12%)',
            WebkitTransition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
            transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        },
        '&.noHover': {
            pointerEvents: 'none'
        },
        '&.noRadius':{
            borderRadius: 'unset!important',
        },
        [theme.breakpoints.down('md')]: {
            borderRadius: 'unset!important',
            maxWidth: '95%',
            margin: 'auto',
            marginBottom: '15px',
        }
    }
}))(Card);
const StyledCardBanner = withStyles((theme) => ({
    root: {
        maxWidth: '100%!important',
        minHeight: '120px',
        backgroundColor: theme.palette.primary.light,
        //background: rgb(0,103,102);
        background: 'linear-gradient(90deg, rgba(0,103,102,1) 0%, rgba(0,103,102,1) 35%, rgba(0,129,128,1) 100%)',
        //Thomas 28/06/21
        position: 'relative',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        minWidth: 0,
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        // 
        // border: '0 solid rgba(0,0,0,0.125)',
        borderRadius: 0,
        //boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',


    }
}))(Card);
const StyledCardMessage = withStyles((theme) => ({
    root: {
        maxWidth: '100%!important',
        minHeight: '150px',
        position: 'relative',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        borderRadius: 0,
        backgroundColor: 'inherit',
        boxShadow: 'unset',
        [theme.breakpoints.down('sm')]: {
            minHeight: '200px',
        }
    }
}))(Card);
const StyledStatisticCard = withStyles((theme) => ({
    root: {
        //marginBottom: '15px',
        minWidth: '200px',
        cursor: 'pointer',
        WebkitTransition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        '&:hover': {
            boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 12px 16px 0px rgb(0 0 0 / 14%), 0px 4px 32px 0px rgb(0 0 0 / 12%)',
            WebkitTransition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
            transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
        },
        '&.noHover': {
            pointerEvents: 'none'
        },
        [theme.breakpoints.down('md')]: {
            borderRadius: 'unset!important',
            maxWidth: '95%',
            margin: 'auto',
            marginBottom: theme.spacing(2),
        }
    }
}))(Card);
const SelectableCardHeader = withStyles((theme) => ({
    root: {
        padding: '0px!important',
        textAlign: 'left'
    },
    content: {
        padding: '10px 10px 10px 0',
    }
}))(CardHeader);
const SelectableStatisticCardHeader = withStyles((theme) => ({
    root: {
        display: 'block',
        padding: '20px!important',
        textAlign: 'center',
    },
    avatar: {
        marginRight: '0px'
    }
}))(CardHeader);
const StyledBox = withStyles((theme) => ({
    root: {
        width: '80px',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        
    }
}))(Box);
const StyledStatisticBox = withStyles((theme) => ({
    root: {
        marginRight: '0px'
    }
}))(Box);
const StyledOutcomeCard = withStyles((theme) => ({
    root: {
        //height: '100%',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        flex: 1,
        minWidth: 0,
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        border: '0 solid rgba(0,0,0,0.125)',
        borderRadius: 0,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
        cursor: 'default'
    }
}))(Card);
const StyledOutcomeCardContent = withStyles((theme) => ({
    root: {
        paddingTop: '10px',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
            minWidth: '100%',
            margin: 'unset',
        },

        '&:last-child': {
            padding: 'unset'
        },
        '& .MuiList-root': {
            padding: '0px!important'
        }
    }
}))(CardContent);
const StyledInformationCard = withStyles((theme) => ({
    root: {
        // Thomas modified
        // height:'100%',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        flex: 1,
        fontFamily: 'interstate',
        // thomas changed the color from theme
        color: theme.palette.primary.main,

        minWidth: 0,
        wordWrap: 'break-word',
        // THOMAS ADDED 
        borderTop: '1px solid',
        marginTop: theme.spacing(2),
        //end of added
        backgroundClip: 'border-box',
        border: '0 solid rgba(0,0,0,0.125)',
        borderRadius: 0,
        // Thomas commont out
        // boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
        iconStyle: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    }
}))(Card);

const StyledInfoCard = withStyles((theme) => ({
    root: {
        height:'100%',
        maxWidth:'90%',
        margin:'auto',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        flex: 1,
        minWidth: 0,
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        border: '0 solid rgba(0,0,0,0.125)',
        borderRadius: 0,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    }
}))(Card);
const StyledInfoCardContent = withStyles((theme) => ({
    root: {
        paddingTop: '10px',
        height:'100%',
        [theme.breakpoints.down("sm")]: {
            minWidth: '100%',
            margin: 'unset',
        },

        '&:last-child': {
            padding: 'unset'
        },
        '& .MuiList-root': {
            padding: '0px!important'
        }
    }
}))(CardContent);


export const SelectableInfoCard = (props: any) => {
    const classes = useStyles();
    return (
        <StyledInfoCard tabIndex={0} style={{maxWidth: props.maxWidth,}}
                           className={`${props.onClick} ? ${classes.clickable} : ''`}
                           onClick={() => props.onClick()}
        >
            <StyledInfoCardContent>
                <OutcomeList>
                    {props.children}
                </OutcomeList>
            </StyledInfoCardContent>
        </StyledInfoCard>
    )
}

export interface ISelectableCardProps {
    variant: 'alert',
    severity: 'info' | 'error' | 'conditional',

    style: React.CSSProperties
}

export const SelectableCard = (props: ISelectableCardProps & any) => {

    const classes = useStyles();
    const StyledIcon = (): JSX.Element | null => {
        if (props.icon) return React.cloneElement((props.icon as JSX.Element), {"className": classes.icon,})
        return null;
    };

    const getVariantIcon = (): ReactElement | undefined => {
        switch (props.severity) {
            case 'info':
                return <InfoOutlinedIcon
                    className={`${props.variant === 'alert' ? clsx(classes.icon, props.severity) : ''}`}/>
            case 'error':
                return <ErrorOutlineIcon
                    className={`${props.variant === 'alert' ? clsx(classes.icon, props.severity) : ''}`}/>
            case 'warning':
                return <WarningRoundedIcon
                    className={`${props.variant === 'alert' ? clsx(classes.icon, props.severity) : ''}`}/>
        }
    }

    return (
        <div onClick={() => {
            if (props.onSelected) {
                props.onSelected()
            }
        }}>
            <StyledCard elevation={3}
                        className={clsx(`
                            ${props.variant === 'alert'
                            ? clsx(classes.alert, props.severity, 'noHover')
                            : props.onSelected
                                ? ''
                                : 'noHover'}`, 
                            `${props.severity === 'error'
                            ? 'noRadius'
                            :''}`)}
                        style={props.style}
            >
                <CardMedia>
                    <StyledBox
                        className={clsx(
                            `${props.variant === 'alert'
                                ? clsx(classes.alertBox, props.severity)
                                : clsx(classes.alertBox, props.severity)
                            }`,
                            `${!props.icon && classes.noIcon
                            }`
                        )}>
                        {props.variant ? getVariantIcon() : <StyledIcon/>}
                    </StyledBox>
                </CardMedia>
                <div className={classes.details}>
                    <CardContent>
                        {props.content}
                    </CardContent>
                </div>
            </StyledCard>
        </div>
    )
}
export const SelectableCardIconContentItem = (props: any & CSSProperties) => {
    const classes = useStyles();

    const StyledIcon = (): JSX.Element | null => {
        if (props.icon) return React.cloneElement((props.icon as JSX.Element), {"className": classes.contentIcon, "style":props.style})
        return null;
    };

    return <Box display={'flex'} style={props.style}>
        <StyledIcon/>
        <Typography variant={'caption'} style={{marginBottom: '0px'}}>{props.text}</Typography>
    </Box>
}
export const SelectableStatisticCard = (props: ISelectableCardProps & any) => {

    const classes = useStyles();

    const cardClassName = `${
        props.disabled
            ? "noHover"
            : props.variant === "alert"
            ? clsx(classes.alert, props.severity, "noHover")
            : props.onSelected
                ? ""
                : "noHover"
    }`;

    return (
        <div onClick={() => {
            if (props.onSelected && !props.disabled) {
                props.onSelected()
            }
        }}>
            <StyledStatisticCard elevation={3}
                                 className={cardClassName}
                                 style={props.style}
            >
                <SelectableStatisticCardHeader className={props.disabled ? classes.disabled : ""}
                    avatar={<StyledStatisticBox>
                        {props.value}
                    </StyledStatisticBox>}
                    title={props.title}
                    subheader={props.subheader}
                    action={props.action}
                />
            </StyledStatisticCard>
        </div>
    )
}
export const SelectableCardSkeleton = (props: any) => {

    const skeletons = []

    for (let i = 0; i < props.count; i++) {
        skeletons.push(<Box key={i} display="flex" height="80px" style={{marginBottom: '10px'}}>
            <Skeleton height="100%" style={{height: '80px', width: '90px', marginRight: '10px', transform: 'unset'}}>
                <Avatar style={{width: "80px"}}/>
            </Skeleton>
            <Box width="100%">
                <Skeleton width="60%">
                    <Typography>.</Typography>
                </Skeleton>
                <Skeleton width="30%">
                    <Typography>.</Typography>
                </Skeleton>
            </Box>
        </Box>)
    }

    return (
        <div>
            {skeletons}
        </div>
    )
}
export const OutcomeCard = (props: any) => {
    const classes = useStyles();
    return (
        <StyledOutcomeCard tabIndex={0} style={{width: props.width,}}
                           className={`${props.onClick} ? ${classes.clickable} : ''`}
                           onClick={() => {
                               props.onClick && props.onClick()
                           }}
        >
            <StyledOutcomeCardContent>
                <OutcomeList>
                    {props.children}
                </OutcomeList>
            </StyledOutcomeCardContent>
        </StyledOutcomeCard>
    )
}
export const SkeletonCard = (props: any) => {
    const skeletons = []

    for (let i = 0; i < props.count; i++) {
        skeletons.push(<Box key={i} display="flex" height="80px" style={{marginBottom: '10px'}}>
            <Skeleton height="100%" style={{height: '80px', width: '90px', marginRight: '10px', transform: 'unset'}}>
                <Avatar style={{width: "80px"}}/>
            </Skeleton>
            <Box width="100%">
                <Skeleton width="60%">
                    <Typography>.</Typography>
                </Skeleton>
                <Skeleton width="30%">
                    <Typography>.</Typography>
                </Skeleton>
            </Box>
        </Box>)
    }

    return (
        <div>
            {skeletons}
        </div>
    )
}
export const BannerCard = (props: any) => {


    const [activeIndex, setActiveIndex] = useState(0)
    const [elementIn, setElementIn] = useState(true);

    // thomas added the State
    const [stopTransitionTimeout, setStopTransitionTimeout] = useState(false);
    const transitionDuration = 10000;
    const [bannerTimeInterval, setBannerTimeInterval] = useState<any>()
    const [slidDirection, setSlidDirection] = useState(true);
    const classes = useStyles();
    let theme = useTheme();
    // end added

// Thomas added the funciton for banner control
    const handleKeyStop = (e?: any) => {
        if (e.keyCode === 13) {
            setSlidDirection(true);
            setStopTransitionTimeout(!stopTransitionTimeout);

        }
    }
    const handleStop = () => {
        setSlidDirection(true);
        setStopTransitionTimeout(!stopTransitionTimeout);
    }
    const handleButtonPre = (e?: any) => {
        if (e.keyCode === 13) {
            setSlidDirection(false);
            setTimeout(() => {
                setElementIn(false);
                setTimeout(() => {
                    setElementIn(true)
                    activeIndex == 0 ? setActiveIndex(props.children.length - 1) : setActiveIndex(activeIndex - 1)
                    setSlidDirection(true);
                }, 700);
            }, 1000)
        }
    }
    const handlePre = () => {
        setSlidDirection(false);
        setTimeout(() => {
            setElementIn(false);
            setTimeout(() => {
                setElementIn(true)
                activeIndex == 0 ? setActiveIndex(props.children.length - 1) : setActiveIndex(activeIndex - 1)
                setSlidDirection(true);
            }, 700);
        }, 1000)
    }
    const handleButtonNext = (e?: any) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                setElementIn(false);
                setTimeout(() => {
                    setElementIn(true)
                    setActiveIndex((activeIndex + 1) % props.children.length)
                }, 700);
            }, 1000)
        }
    }
    const handleNext = () => {
        setTimeout(() => {
            setElementIn(false);
            setTimeout(() => {
                setElementIn(true)
                setActiveIndex((activeIndex + 1) % props.children.length)
            }, 700);
        }, 1000)
    }

    useEffect(() => {
        if (stopTransitionTimeout) {
            clearInterval(bannerTimeInterval)
        } else {
            let timeInterval = setInterval(() => {
                setElementIn(false);
            }, transitionDuration)
            setBannerTimeInterval(timeInterval)
        }
    }, [stopTransitionTimeout])
    // changed the 500 to 700
    useEffect(() => {
        if (!elementIn) {
            setTimeout(() => {
                setElementIn(true)
                setActiveIndex((activeIndex + 1) % props.children.length)
            }, 700);
        }
    }, [elementIn])
// end add

    return (
        <Hidden smDown>
            <StyledCardBanner>
                <StyledContainer>
                    {/* thomas changed in 1/07/21*/}
                    {props.children && slidDirection ?
                        <Slide in={elementIn} direction={elementIn ? 'left' : 'right'} timeout={500}>
                            <div>{props.children[activeIndex]}</div>
                        </Slide> :
                        <Slide in={elementIn} direction={elementIn ? 'right' : 'left'} timeout={500}>
                            <div>{props.children[activeIndex]}</div>
                        </Slide>}
                    {/* thomas added the function  */}

                    <Grid
                        direction="row"
                        justify="space-between"
                        alignItems="stretch"
                        className={classes.bannerControlStyle}
                        style={{gridArea: 'right'}}>
                        {/* <Grid item lg={2} className={classes.bannerControlStyle} > */}
                        <div className={classes.bannerDivStyle}>
                            <div tabIndex={0} onKeyDown={handleButtonPre}><AngleLeft
                                className={classes.bannerPreviousNextButtonStyle} onClick={handlePre}/></div>
                            <div tabIndex={0} onKeyDown={handleKeyStop}>{stopTransitionTimeout ?
                                <PlayCircle className={classes.bannerPlayButtonStyle} onClick={handleStop}/>
                                : <PauseCircle className={classes.bannerPlayButtonStyle} onClick={handleStop}/>}</div>
                            <div tabIndex={0} onKeyDown={handleButtonNext}><AngleRight
                                className={classes.bannerPreviousNextButtonStyle} onClick={handleNext}/></div>
                        </div>
                        {/* </Grid> */}
                    </Grid>
                    {/* end added */}
                </StyledContainer>
            </StyledCardBanner>
        </Hidden>
    )
}
export const MessageCard = (props: any) => {
    const classes = useStyles();
    const StyledIcon = (): JSX.Element | null => {
        if (props.icon) return React.cloneElement((props.icon as JSX.Element), {"className": classes.messageIcon,})
        return null;
    };
    return (
        <StyledCardMessage>
            <Grid style={{height: '100px', marginLeft: '10px'}}
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
            >
                {/* thomas added the lg and changed the xs 1 to 2 and xs 11 to 10 
                    added the md
                */}
                <Grid item lg={1} md={1} xs={2}>
                    <Avatar className={classes.messageAvatar}>
                        <StyledIcon/>
                    </Avatar></Grid>
                <Grid item lg={11} xs={10} md={11}>
                    <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        alignItems="stretch"
                    >
                        <Grid item xs={12}>
                            <OutcomeDetailsListItem
                                textVariant={'h4'}
                                text={props.header}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <OutcomeDetailsListItem
                                text={props.text}
                                textVariant={'subtitle1'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledCardMessage>
    )
}
export const InformationCard = (props: any) => {
    const classes = useStyles();

    return (
        <StyledInformationCard className={classes.alertBox}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={2}>
                    <Avatar className={classes.informationAvatar}>
                        <CarSideSolidInformation className={classes.textColor}/>
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h5" component="h2">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <div>&nbsp;</div>
                </Grid>
                <Grid item xs={10}>
                    {/* thomas modified */}
                    <CardContent className={classes.cardContantStyle}>
                        <Typography variant={'body2'} component="p">
                            {props.text}
                        </Typography>
                        <Grid className={classes.options}>
                            <Grid className={classes.optionStyle} item xs={12}>
                                <Grid item xs={1}>
                                    <DollarSignSolid/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant={'caption'} component="p">
                                        {props.informationOption[0]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.optionStyle} item xs={12}>
                                <Grid item xs={1}>
                                    <ClockSolid/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant={'caption'} component="p">
                                        {props.informationOption[1]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </StyledInformationCard>
    );
}
