import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { getSiteLocations, setPrimaryLocation } from "../data/api";
import { ValidatedForm } from "../components/core/forms/ValidatedForm";
import nyrstar1 from "../assets/images/nyrstar1.jpg";
import { Simulate } from "react-dom/test-utils";
import { OutcomeIdentifier, QueueChoiceVm } from "../common/client";
import { Questionnaire } from "../components/core/QuestionSlider/Questionnaire";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      // Thomas changed teh 60 to 100
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        width: "64px",
        height: "64px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
    image: {},
    selectField: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    selectFieldOne: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    selectFieldtwo: {
      marginTop: "15px",
      marginBottom: "5px",
      "& .MuiFormHelperText-root": {
        fontSize: "0.9rem!Important",
      },

      "& .MuiInputLabel-shrink": {
        transform: "Translate(0,-5.5px) scale (0.75)!important",
      },

      "& .MuiInputLabel-formControl": {
        top: "0px",
        left: "11px!important",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
      },

      fontSize: "1rem",
      lineHeight: " 1rem",
      letterSpacing: "-.01875rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiFormLabel-root": {
          fontSize: "1rem!Important",
        },
        "& .MuiOutlinedInput-root ": {
          fontSize: "1rem!Important",
        },
      },
    },
    error: {
      color: "red",
      paddingLeft: "14px",
      fontSize: "0.9rem!important",
      letterSpacing: "0.0075rem",
    },
  })
);

const PrimaryLocation = (props: any) => {
  const BLANKID = "00000000-0000-0000-0000-000000000000";
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();
  const [allLocations, setAllLocations] = useState<any>([]);
  const [site, setSite] = useState<string>("");
  const [allSites, setAllSites] = useState<any>([]);
  const [building, setBuilding] = useState<string>("");
  const [allOutcomes, setAllOutcomes] = useState<QueueChoiceVm[]>([]);
  const [allBuildings, setAllBuildings] = useState<any[]>([
    { id: BLANKID, name: "Select Site first" },
  ]);
  const [room, setRoom] = useState<string>("");
  const [allRooms, setAllRooms] = useState<any[]>([
    { id: BLANKID, name: "Select Building first" },
  ]);

  const [formErrors, setFormErrors] = useState({
    building: {
      valid: true,
      errorMessage: "",
    },
    room: {
      valid: true,
      errorMessage: "",
    },
    site: {
      valid: true,
      errorMessage: "",
    },
  });

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (building.length === 0) {
      errors.building.valid = false;
      errors.building.errorMessage = "Building is required";
      valid = false;
    } else {
      errors.building.valid = true;
      errors.building.errorMessage = "";
    }

    if (room.length === 0) {
      errors.room.valid = false;
      errors.room.errorMessage = "Room is required";
      valid = false;
    } else {
      errors.room.valid = true;
      errors.room.errorMessage = "";
    }

    if (site.length === 0) {
      errors.site.valid = false;
      errors.site.errorMessage = "Site is required";
      valid = false;
    } else {
      errors.site.valid = true;
      errors.site.errorMessage = "";
    }

    setFormErrors(errors);
    return valid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      savePrimaryLocation();
    } else {
      setLoading(false);
    }
  };

  const savePrimaryLocation = () => {
    if (room !== BLANKID) {
      setPrimaryLocation(room, props.userDetails.token)
        .then((res) => {
          props.onSavePrimaryLocation(room);
        })
        .catch((err) => {});
    } else if (building !== BLANKID) {
      setPrimaryLocation(building, props.userDetails.token)
        .then((res) => {
          props.onSavePrimaryLocation(building);
        })
        .catch((err) => {});
    } else {
      setPrimaryLocation(site, props.userDetails.token)
        .then((res) => {
          props.onSavePrimaryLocation(site);
        })
        .catch((err) => {});
    }
  };

  const handleSiteChange = (event: any) => {
    setSite(event.target.value as string);
    var buildings = allLocations.filter(
      (item: any) => item.outcomeParentId === (event.target.value as string)
    );
    buildings.length > 0
      ? setAllBuildings(buildings)
      : setAllBuildings([{ id: BLANKID, name: "No Buildings for this site" }]);
  };

  const handleBuildingChange = (event: any) => {
    setBuilding(event.target.value as string);
    var rooms = allLocations.filter(
      (item: any) => item.outcomeParentId === (event.target.value as string)
    );
    rooms.length > 0
      ? setAllRooms(rooms)
      : setAllRooms([{ id: BLANKID, name: "No Rooms for this Building" }]);
  };

  const handleRoomChange = (event: any) => {
    setRoom(event.target.value as string);
  };

  const LoadFunction = () => {
    getSiteLocations(props.userDetails.token).then((res) => {
      var loc: QueueChoiceVm[];
      var allloc = res;
      res = filterQuestionnaires(res);
      if (props.visitorOrContractor === 1) {
        var sites = filterOther(res);
        setAllSites(
          sites.filter((item: QueueChoiceVm) => item.outcomeParentId === null)
        );
        loc = sites;
        setAllLocations(sites);
      } else if (props.visitorOrContractor === 2) {
        sites = filterVisiting(res);
        setAllSites(
          sites.filter((item: QueueChoiceVm) => item.outcomeParentId === null)
        );
        loc = sites;
        setAllLocations(sites);
      } else {
        var list = filterVisiting(res);
        list = filterDontKnow(list);
        setAllLocations(list);
        loc = list;
        setAllSites(
          list.filter((item: QueueChoiceVm) => item.outcomeParentId === null)
        );
      }

      setBaseSite(loc, allloc);
      setLoading(false);
    });
  };

  const filterQuestionnaires = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Covid_Questionnaire
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !==
        OutcomeIdentifier.Port_Pirie_Covid_Questionnaire
    );
    return data;
  };

  const filterVisiting = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Visiting
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_visiting
    );
    return data;
  };

  const filterOther = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Other
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_Other
    );
    return data;
  };

  const filterDontKnow = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_I_Do_Not_Know
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_I_Do_Not_Know
    );
    return data;
  };

  const setBaseSite = (loc: QueueChoiceVm[], res: QueueChoiceVm[]) => {
    var siteId: string;
    var room = loc.find(
      (item: QueueChoiceVm) => item.id === props.location.toLowerCase()
    );
    if (room) {
      if (room?.outcomeParentId !== null) {
        var building = loc.find(
          (item: QueueChoiceVm) => item.id === room!.outcomeParentId
        );
        if (building?.outcomeParentId !== null) {
          var site = loc.find(
            (item: QueueChoiceVm) => item.id === building!.outcomeParentId
          );
          setSite(site!.id);
          siteId = site!.id;
        } else {
          setSite(building!.id);
          siteId = building!.id;
        }
      } else {
        setSite(room!.id);
        siteId = room!.id;
      }
    } else {
      var questionnaire = res.find(
        (item: QueueChoiceVm) => item.id === props.location.toLowerCase()
      );

      if (
        questionnaire &&
        questionnaire.outcomeIdentifier ===
          OutcomeIdentifier.Hobart_Covid_Questionnaire
      ) {
        setSite(questionnaire.outcomeParentId!);
        siteId = questionnaire.outcomeParentId!;
      } else if (
        questionnaire &&
        questionnaire.outcomeIdentifier ===
          OutcomeIdentifier.Port_Pirie_Covid_Questionnaire
      ) {
        setSite(questionnaire.outcomeParentId!);
        siteId = questionnaire.outcomeParentId!;
      }
    }
    var buildings = loc.filter(
      (item: QueueChoiceVm) => item.outcomeParentId === siteId
    );
    buildings.length > 0
      ? setAllBuildings(buildings)
      : setAllBuildings([{ id: BLANKID, name: "No Buildings for this site" }]);
  };

  useEffect(() => {
    LoadFunction();
  }, []);

  return (
    <div>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading && (
        <>
          <Container
            component="main"
            maxWidth="sm"
            style={{ marginBottom: "60px", marginTop: "50px" }}
          >
            <div className={classes.paper}>
              <img className={classes.image} src={nyrstar1} alt="Site Logo" />
              <Typography className={classes.heading}>
                User's Primary Location
              </Typography>

              <ValidatedForm>
                <Divider
                  variant="middle"
                  style={{ marginTop: "10px", marginBottom: "30px" }}
                />

                <FormControl fullWidth>
                  <div id={"building"}>
                    <InputLabel
                      className={classes.selectField}
                      error={!formErrors.site.valid}
                      id="building-label"
                    >
                      <div
                        style={{
                          left: "16px",
                          position: "absolute",
                          width: "500px",
                          top: "-7px",
                        }}
                      >
                        Site *
                      </div>
                    </InputLabel>
                    <Select
                      error={!formErrors.site.valid}
                      className={classes.selectFieldOne}
                      variant="outlined"
                      fullWidth
                      id="Site-select"
                      value={site}
                      label="Site *"
                      onChange={handleSiteChange}
                      disabled={loading}
                    >
                      {allBuildings.length > 0 ? (
                        allSites.map((element: any) => {
                          return (
                            <MenuItem value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}
                        >
                          Site not found
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText className={classes.error}>
                      {!formErrors.site.valid
                        ? formErrors.site.errorMessage
                        : ""}
                    </FormHelperText>
                  </div>
                </FormControl>

                <FormControl fullWidth>
                  <div id={"building"}>
                    <InputLabel
                      className={classes.selectFieldOne}
                      error={!formErrors.building.valid}
                      id="building-label"
                    >
                      <div
                        style={{
                          left: "16px",
                          position: "absolute",
                          width: "500px",
                          top: "-7px",
                        }}
                      >
                        Building *
                      </div>
                    </InputLabel>
                    <Select
                      error={!formErrors.building.valid}
                      className={classes.selectFieldOne}
                      variant="outlined"
                      fullWidth
                      id="building-select"
                      value={building}
                      label="building *"
                      onChange={handleBuildingChange}
                      disabled={loading}
                    >
                      {allBuildings.length > 0 ? (
                        allBuildings.map((element: any) => {
                          return (
                            <MenuItem value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}
                        >
                          buildings not found
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText className={classes.error}>
                      {!formErrors.building.valid
                        ? formErrors.building.errorMessage
                        : ""}
                    </FormHelperText>
                  </div>
                </FormControl>

                <FormControl fullWidth>
                  <div id={"room"}>
                    <InputLabel
                      className={classes.selectFieldtwo}
                      error={!formErrors.room.valid}
                      id="room-label"
                    >
                      <div
                        style={{
                          left: "16px",
                          position: "absolute",
                          width: "500px",
                          top: "-7px",
                        }}
                      >
                        Room *
                      </div>
                    </InputLabel>
                    <Select
                      error={!formErrors.room.valid}
                      className={classes.selectFieldtwo}
                      variant="outlined"
                      fullWidth
                      id="room-select"
                      value={room}
                      label="room *"
                      onChange={handleRoomChange}
                      disabled={loading}
                    >
                      {allBuildings.length > 0 ? (
                        allRooms.map((element: any) => {
                          return (
                            <MenuItem value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}
                        >
                          room not found
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText className={classes.error}>
                      {!formErrors.room.valid
                        ? formErrors.room.errorMessage
                        : ""}
                    </FormHelperText>
                  </div>
                </FormControl>

                <br />
                <Button
                  style={{ marginTop: "30px" }}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    setLoading(true);
                    onSubmit();
                  }}
                >
                  {loading && <CircularProgress style={{ color: "white" }} />}
                  {!loading && "Save"}
                </Button>
              </ValidatedForm>
            </div>
            <Divider variant="middle" />
          </Container>
        </>
      )}
    </div>
  );
};

export default PrimaryLocation;
