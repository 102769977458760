import { createContext, useContext } from "react";
import { FormControl, useControlValue } from "@react-typed-forms/core";
import { FCheckbox } from "../../../common/formComponents/FCheckbox";
import { FSwitch } from "../../../common/formComponents/FSwitch";

export const HelpModeContext = createContext<FormControl<boolean> | null>(null);

export function useHelpMode() {
  const ctrl = useContext(HelpModeContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return ctrl ? useControlValue(ctrl) : false;
}

export function HelpMode() {
  const ctrl = useContext(HelpModeContext);
  if (!ctrl) {
    throw "Must be inside a HelpModeContext.Provider";
  }
  return <FSwitch state={ctrl} label="Show all help/call scripting text" />;
}
