import React, { ReactElement, ReactNode } from "react";
import Box from "@material-ui/core/Box";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  PersonViewModel,
  QuestionAdminViewModel,
  QuestionChoiceViewModel,
  QuestionType,
} from "../../../common/client";
import {
  QuestionnaireAnswers,
  RenderQuestionAnswerProps,
} from "../Questionnaire/QuestionnaireAnswers";
import {
  AnswersState,
  GroupAnswerValue,
  isGroupElementVisible,
  questionAnswerText,
  RepeaterAnswerValue,
  selectedChoiceNestedValue,
  useSortedNestedQuestions,
} from "../Questionnaire";
import { format } from "date-fns";

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fafafa",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  title: {
    textAlign: "center",
    fontSize: 24,
  },
  line: {
    fontSize: 12,
  },
  imageLiL: {
    height: "50",
    width: "50",
    float: "left",
  },
  imageCFLC: {
    height: "40",
    width: "60",
    float: "right",
  },
  blueText: {
    color: "#005A96",
  },
  grouped: {
    marginLeft: "20",
  },
  nested: {
    border: 1,
    marginLeft: 10,
    marginBottom: 5,
  },
  topLevel: {
    marginBottom: 20,
  },
  topLevelHeading: {
    fontWeight: 900,
  },
});

export function GenerateQuestionnairePDF({
  questions,
  answers,
  title,
  person,
  completedAt,
}: {
  questions: QuestionAdminViewModel[];
  answers: AnswersState;
  title: string;
  person: PersonViewModel;
  completedAt: string | undefined;
}) {

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.section}>
          <span>
            <Text>{title}</Text>
          </span>
          <Text> </Text>
          {completedAt && (
            <Text>
              Date Completed:{" "}
              {format(new Date(completedAt!), "dd-MM-yyyy HH:mm ")}
            </Text>
          )}
          <Text> </Text>
       
          <View>
            <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"First Name"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.firstName}</span>
            </Text>
            <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"Last Name"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.lastName}</span>
            </Text>
            <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"Phone"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.contactNumber}</span>
            </Text>
            <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"Department"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.department!.name}</span>
            </Text>
            {/* <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"Address"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.address}</span>
            </Text> */}
            {/* <Text style={pdfStyles.line}>
              <span style={pdfStyles.topLevelHeading}>{"DOB"}</span>:{" "}
              <span style={pdfStyles.blueText}>{person.shortDateOfBirth}</span>
            </Text> */}
            <Text> </Text>
          </View>

          <Box>
            <QuestionnaireAnswers
              answers={answers}
              questions={questions}
              RenderQuestion={RenderQuestionPDF}
            />
          </Box>
        </View>
      </Page>
    </Document>
  );
}

function RenderQuestionPDF({
  choice,
  question: q,
  value,
  helperText,
  isNested,
}: RenderQuestionAnswerProps & {
  isNested?: boolean;
}): ReactElement {
  const nested = useSortedNestedQuestions(q);
  const topLevelStyle = !isNested ? pdfStyles.topLevel : undefined;
  switch (q.type) {
    case QuestionType.Grouped:
      return (
        <View style={topLevelStyle}>
          <div>
            <Text style={pdfStyles.line}>{q.heading}:</Text>
          </div>
          <View style={pdfStyles.grouped}>{renderNested(value ?? {})}</View>
        </View>
      );
    case QuestionType.Repeater:
      const repeaterValue: RepeaterAnswerValue = value ?? [];
      return (
        <View style={topLevelStyle}>
          <div>
            <Text style={pdfStyles.line}>{q.heading}:</Text>
          </div>
          <View style={pdfStyles.grouped}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              {nested.map((nq) => (
                <View style={{ width: `${100 / nested.length}%`, border: 1 }}>
                  <Text style={pdfStyles.line}>{nq.heading}</Text>
                </View>
              ))}
            </View>
            {repeaterValue.map((rowValue) => (
              <View style={{ display: "flex", flexDirection: "row" }}>
                {nested.map((nq) => (
                  <View style={{ width: `${100 / nested.length}%`, border: 1 }}>
                    {questionAnswerText(
                      nq,
                      selectedChoiceNestedValue(nq, rowValue),
                      rowValue[nq.id],
                      repeaterView,
                      (text) => (
                        <Text style={pdfStyles.line}>{text}</Text>
                      )
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      );
  }
  return (
    <View style={topLevelStyle}>
      <Text style={pdfStyles.line}>
        <span style={!isNested ? pdfStyles.topLevelHeading : undefined}>
          {q.heading}
        </span>
        {"\n\n"}
        <span style={!isNested ? pdfStyles.topLevelHeading : undefined}>
          {q.helpText}
        </span> :{" "}
        <span style={pdfStyles.blueText}>
          {questionAnswerText(q, choice, value, repeaterView, (text) => text)}
        </span>
      </Text>
    </View>
  );

  function repeaterView(vals: ReactNode[]) {
    return (
      <View>
        {vals.map((n, i) => (
          <div style={{ borderTop: i == 0 ? undefined : 1 }}>
            <Text style={pdfStyles.line}>{n}</Text>
          </div>
        ))}
      </View>
    );
  }

  function renderNested(value: GroupAnswerValue) {
    return nested
      .filter((nq) => isGroupElementVisible(q, value, nq))
      .map((nq) => (
        <RenderQuestionPDF
          question={nq}
          value={value[nq.id]}
          choice={selectedChoiceNestedValue(nq, value)}
          isNested={true}
        />
      ));
  }
}
