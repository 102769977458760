import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SelectableCard } from "./Card";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Tooltip } from "@material-ui/core";
import { UserTeamPermissionSolid } from "../Icons/Icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *:not(:last-child)": {
			marginBottom: theme.spacing(1),
		},
	},
	auditAlert: {
		margin: "10px!important",
		color: theme.DoH.color.conditional,
		maxWidth: "unset",
		maxHeight: "unset",
		fontSize: "1.5rem!important",
	},
}));

interface MemberProps {
	id: string;
	name: string;
	selected: boolean;
	canManageTeamMembers?: boolean;
	canViewTeam?: boolean;
}

type MemberListProps = {
	members?: MemberProps[];
	icon?: any;
	title?: string;
	onSelected?: (id: string, selected?: boolean) => void;
	subheader?: React.ReactNode;
	selectedMembers?: string[];
};

const randomColor = () => {
	const hex = Math.floor(Math.random() * 0xffffff);
	return "#" + hex.toString(16);
};

const membersCompareTo = (a: any, b: any) => {
	if (a.name === b.name) {
		return 0;
	} else if (a.name > b.name) {
		return 1;
	} else {
		return -1;
	}
};

const getMemberInitial = (memberName: string) => {
	if (memberName.length === 0) return "";
	const name = memberName.split(" ");
	// assume only return first and last name?
	// or perpahs return firstName and lastName instead of name
	if (name.length > 2) {
		return name[0][0] + name[name.length - 1][0];
	}
	return name.map((n) => n[0]).join("");
};

const MemberList = ({
	members,
	icon,
	title,
	onSelected,
	subheader,
	selectedMembers,
}: MemberListProps) => {
	const classes = useStyles();

	// hacky way to refresh the card

	if (!members || members.length === 0) {
		return (
			<SelectableCard
				id={"teamNotFound"}
				title={`No ${title} Selected / Found.`}
				icon={icon}
			/>
		);
	}

	return (
		<div className={classes.root}>
			{members.sort(membersCompareTo).map((member) => (
				<SelectableCard
					action={
						<>
							{member.canManageTeamMembers && (
								<Tooltip title="Team Manager" aria-label="modified">
									<span>
										<UserTeamPermissionSolid className={classes.auditAlert} />
									</span>
								</Tooltip>
							)}
							{member.canViewTeam && !member.canManageTeamMembers && (
								<Tooltip title="Team Viewer" aria-label="modified">
									<VisibilityIcon className={classes.auditAlert} />
								</Tooltip>
							)}
						</>
					}
					key={member.id}
					id={member.id}
					title={member.name ? member.name : (member as any).firstName + " " + (member as any).lastName}
					avatar={member.name ? getMemberInitial(member.name) : (member as any).firstName.charAt(0).toUpperCase() + (member as any).lastName.charAt(0).toUpperCase()}
					avatarColor={randomColor()}
					onSelected={onSelected}
					variant="selectable"
					checked={selectedMembers?.includes(member.id)}
					subheader={subheader}
				/>
			))}
		</div>
	);
};

export default MemberList;
