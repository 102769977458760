import React from "react";

import nyrstar1 from "../assets/images/nyrstar1.png";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      // Thomas changed teh 60 to 100
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        width: "64px",
        height: "64px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem!important",
        // added the center
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.3rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
        textAlign: "center",
      },
    },
    image: {},
   
  }));

export const AccessDenied = () => {
    const classes = useStyles();

    return (
        <Container
          component="main"
          maxWidth="sm"
          style={{ marginBottom: "60px", marginTop: "50px" }}
        >
          <div className={classes.paper}>
            <img className={classes.image} src={nyrstar1} alt="Site Logo" />
            </div>
            <Divider variant="middle" />
            <div className={classes.paper}>
            <Typography className={classes.heading}>
            You do not have access to this page. If you belive this is in error please contact your administrator.
            </Typography>
       
        
          </div>
          <Divider variant="middle" />
        </Container>
    );

}