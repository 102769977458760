import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    ButtonGroup,
    Collapse,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles, Typography
} from "@material-ui/core";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import {QuestionItemTextField} from "./QuestionItemTextField";

const useStyles = makeStyles((theme) => ({
    box: {
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: '0px',
            left: '0px',
            width: '100%'
        },
        display: "flex!important",
        textAlign: 'center',
        maxWidth: '660px',//Default width overriden by props.maxWidth
        margin: "auto",
    },
    btnActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    btnStartIcon: {
        width: '35px',
        height: '35px'
    }
}));

export const RadioButtonsWithAdditionalInfoTextField = (props: any) => {

    const classes = useStyles();
    const [selectedRadioButton, setSelectedRadioButton] = useState<any>();

    const textFieldRef = useRef<any>(null);
    const [additionalInfoRequired, setAdditionalInfoRequired] = React.useState(false);
    const [additionalInformation, setAdditionalInformation] = useState('')
    const [radioButtons, setRadioButtons] = useState<any>()

    useEffect(() => {
        setRadioButtons(props.radioButtons)
    }, [props.radioButtons])

    useEffect(() => {

        if (radioButtons) {
            let index = radioButtons!.findIndex((radioButton: any) => radioButton.selected)

            if (index != -1) {

                setAdditionalInformation(radioButtons[index].additionalInformation)
                setAdditionalInfoRequired(radioButtons[index].additionalInfoRequired)
                setSelectedRadioButton(radioButtons[index])
            }
        }

    }, [radioButtons])

    return (
        <>
            {radioButtons?.length > 0 && radioButtons.map((radioButton: any, i: number) => {
                return (
                    <div key={i}>
                        <ListItem
                            button
                            className={`${radioButton.selected ? classes.btnActive : ''}`}
                            onClick={() => {
                                
                                setSelectedRadioButton(radioButton);

                                setRadioButtons(radioButtons.map((rBtn: any) => (rBtn.id != radioButton.id ? {
                                        ...rBtn,
                                        selected: false,
                                        additionalInformation: null
                                    } : radioButton
                                )))

                                if (radioButton.additionalInfoRequired) {
                                    setAdditionalInfoRequired(true)
                                } 
                                else {
                                    textFieldRef?.current.clearTextField()
                                    setAdditionalInfoRequired(false)
                                    if (props.onSelected) {
                                        props.onSelected(radioButton)
                                    }
                                }
                            }}>
                            <ListItemIcon>
                                {
                                    radioButton.selected || selectedRadioButton?.text === radioButton.text ?
                                        <RadioButtonCheckedOutlinedIcon
                                            className={`${classes.btnStartIcon}  ${radioButton.selected ? 'selected' : ''}`}/>
                                        :
                                        <RadioButtonUncheckedOutlinedIcon
                                            className={`${classes.btnStartIcon}  ${radioButton.selected ? 'selected' : ''}`}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={radioButton.text}
                            />
                        </ListItem>
                        <Divider/>
                    </div>
                )
            })}
            <Collapse in={additionalInfoRequired} onExited={() => {

            }}>
                <ListItem>
                    <QuestionItemTextField ref={textFieldRef} value={additionalInformation} id={'additionalInfo'}
                                           label={'Additional Information'} name={''}/>
                </ListItem>
                <Divider/>
                <ButtonGroup variant={'contained'} fullWidth>
                    <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.preventDefault();
                        if (textFieldRef?.current.validate()) {
                            props.onSelected({
                                ...selectedRadioButton,
                                additionalInformation: textFieldRef?.current.text()
                            })
                        }
                    }}>
                        <Typography>
                            Next
                        </Typography>

                    </Button>
                </ButtonGroup>
            </Collapse>
        </>
    )


}