import { createMuiTheme } from "@material-ui/core/styles";
//import './themeModule';
declare module "@material-ui/core/styles/createMuiTheme" {
  export interface Theme {
    DoH: {
      color: {
        info: string;
        warning: string;
        error: string;
        pass: string;
        fail: string;
        exemption: string;
        conditional: string;
      };
      DayPassCard: {
        marginBottom: string;
      };
      CardHeader: {
        color: string;
        fontSize: string;
      };
      Metric: {
        fontSize: string;
      };
      MetricTitle: {
        fontFamily: string;
      };
      SearchBar: {
        width: string;
      };
      AppBar: {
        padding: string;
      };
      paper: {
        welcome: {
          root: {};
          elevation1: {};
        };
      };
      AdminPortal: {
        DrawerMenu: {
          ListItemIcon: {
            root: {};
          };
        };
      };
      TreeView: {
        root: {};
        header: {};
        TreeItem: {
          root: {};
          content: {};
          expanded: {};
          selected: {};
          group: {};
          label: {};
          labelRoot: {};
          labelIcon: {};
          labelText: {};
        };
      };
      TimeLine: {};
      TimelineItem: {};
      TimelineOppositeContent: {
        location: {};
      };
      TimelineDot: {};
    };
  }
  // allow configuration using `createMuiTheme`
  export interface ThemeOptions {
    DoH?: {
      color?: {
        info?: string;
        warning?: string;
        error?: string;
        pass?: string;
        fail?: string;
        exemption?: string;
        conditional?: string;
      };
      DayPassCard?: {
        marginBottom: string;
      };
      CardHeader?: {
        color: string;
        fontSize: string;
      };
      Metric?: {
        fontSize: string;
      };
      SearchBar?: {
        width: string;
      };
      AppBar?: {
        padding: string;
      };
      MetricTitle?: {
        fontFamily: string;
      };
      paper?: {
        welcome?: {
          root?: {};
          elevation1?: {};
        };
      };
      AdminPortal?: {
        DrawerMenu?: {
          ListItemIcon?: {
            root?: {};
          };
        };
      };
      TreeView?: {
        root?: {};
        header?: {};
        TreeItem?: {
          root?: {};
          content?: {};
          expanded?: {};
          selected?: {};
          group?: {};
          label?: {};
          labelRoot?: {};
          labelIcon?: {};
          labelText?: {};
        };
      };
      TimeLine?: {};
      TimelineItem?: {};
      TimelineOppositeContent?: {
        location?: {};
      };
      TimelineDot?: {};
    };
  }
}

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00aeef",
      light: "#00aeef",
      dark: "#00aeef",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#231f20",
    },
  },
  typography: {
    
    //fontSize: '1.15rem',
    //htmlFontSize: '0.8em',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 400,
      
    },
    h2: {
      fontSize: 12,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    fontFamily: ["Arial"].join(","),
  },
});
theme.DoH = {
  TimeLine: {},
  TimelineItem: {},
  DayPassCard: {
    marginBottom: "15px",
  },
  color: {
    info: "#2196f3",
    warning: "#FC4C1F",
    error: "#9A2102",
    pass: "#92b200",
    fail: "#9A2102",
    exemption: "#02529A",
    conditional: "#FC4C1F",
  },
  CardHeader: {
    color: "black",
    fontSize: "1.5rem",
  },
  Metric: {
    fontSize: "1.5rem",
  },
  SearchBar: {
    width: "30%",
  },
  AppBar: {
    padding: "5px",
  },
  MetricTitle: {
    fontFamily: ["Arial"].join(","),
  },
  paper: {
    welcome: {
      root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "10px",
        cursor: "pointer",
        marginTop: "15px",
      },
      elevation1: {
        boxShadow: "none",
      },
    },
  },
  AdminPortal: {
    DrawerMenu: {
      ListItemIcon: {
        root: {
          fontSize: "2.5rem!important",
        },
      },
    },
  },
  TreeView: {
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
    header: {
      padding: "0 0 5px 10px",
      borderBottom: "1px inset",
      marginBottom: "10px",
    },
    TreeItem: {
      root: {
        color: theme.palette.text.primary,
        padding: "10px 0 10px 0",
        // '&:hover > $content': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // '&:focus > $content, &$selected > $content': {
        //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        //     color: 'var(--tree-view-color)',
        // },
        // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        //     backgroundColor: 'transparent',
        // },
      },
      content: {
        color: theme.palette.text.primary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
      group: {
        marginLeft: 0,
        "& $content": {
          paddingLeft: theme.spacing(2),
        },
      },
      labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
      },
      labelIcon: {
        paddingRight: theme.spacing(0),
        color: theme.palette.primary.main,
        fontSize: "1.5rem!Important",
      },
      label: {
        fontWeight: "inherit",
        color: "inherit",
      },
      labelText: {
        fontWeight: "inherit",
        flexGrow: 1,
        //color: '#000000',
        fontSize: "1rem!Important",
      },
      expanded: {},
      selected: {
        // color: theme.palette.primary.contrastText,
        // backgroundColor:theme.palette.primary.light
      },
    },
  },
  TimelineOppositeContent: {
    location: {
      flex: "unset!important",
    },
  },
  TimelineDot: {
    borderStyle: "unset",
    marginTop: "0px!important",
  },
};
theme.typography.body1 = {
  fontSize: "1.3rem",
  ineHeight: " 1.4375rem",
  letterSpacing: "-.01875rem",
  fontWeight: 600,
  color: "#353535", //TODO chnage to theme definition
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
  fontFamily: ["Arial"].join(","),
};
theme.typography.body2 = {
  fontSize: "1.3rem !important",
  ineHeight: " 1.4375rem",
  letterSpacing: "-.01875rem",
  fontWeight: 500,
  fontFamily: ["Arial"].join(","),
};
theme.typography.h3 = {
  color: theme.palette.primary.contrastText + "!important",
  fontSize: "2rem !important",
  fontFamily: ["Arial"].join(","),
};
theme.typography.h4 = {
  color: theme.palette.primary.contrastText + "!important",
  fontSize: "1.50rem !important",
  fontFamily: ["Arial"].join(",") + "!important",
  "& a": {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    borderBottom: "1px solid #fff",
  },
  "& a:hover": {
    borderBottom: "3px solid #fff",
  },
};
theme.typography.h5 = {
  color: theme.palette.primary.light + "!important",
  fontSize: "1.30rem !important",
  fontWeight: 500,
  fontFamily: ["Arial"].join(","),
};
theme.typography.h6 = {
  //color: theme.palette.primary.contrastText + "!important",
  fontSize: "1rem !important",
  fontWeight: 400,
  fontFamily: [
    "-apple-system,BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoj",
    "Arial"
  ].join(","),
};
theme.typography.subtitle1 = {
  color: "black" + "!important",
  fontSize: "1rem !important",
  fontWeight: 400,
  fontFamily: [
    "-apple-system,BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoj",
    "Arial"
  ].join(","),
};
theme.typography.caption = {
  color: "inherit",
  fontSize: ".75rem !important",
  fontWeight: 500,
  letterSpacing: "-.0075rem",
  marginTop: "0",
  marginBottom: "1rem",
  lineHeight: "150%",
  fontFamily: [
    "-apple-system,BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji",
    "Arial"
  ].join(","),
  "& a": {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    borderBottom: "1px solid #fff",
  },
  "& a:hover": {
    borderBottom: "3px solid #fff",
  },
};
theme.overrides = {
  ...theme.overrides,
  MuiListItemIcon: {
    //...theme.MuiListItemIcon,
    root: {
      //...theme.root,
      color: theme.palette.primary.main,
      maxWidth: "unset",
      minWidth: "40px",
      fontSize: "3.5rem!important",
      marginRight: "10px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "4.5rem!important",
        marginRight: "10px",
      },
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.dark,
      },
      "& .selected": {
        color: theme.palette.primary.main,
      },
      "& .selected:hover": {},
    },
  },
  MuiListItem: {
    //...theme.MuiListItem,
    root: {},
    gutters: {
      padding: "1em 0 1em 1em",
    },
  },
  MuiListItemText: {
    //...theme.MuiListItemText,
    root: {
      fontWeight: "bolder",
      //fontSize: theme.typography.body1,
      //Thomas 28/06/21
      // color:'#133650',
      color: "#353535",
      //lineHeight: 1.5,
    },
  },
  MuiButton: {
    root: {
      
      padding: 15,
      borderRadius: 0,
      "&:focus": {
        outline: "none !important",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light + "!important",
        ["@media (min-width:780px)"]: {
          backgroundColor: theme.palette.primary.dark + "!important",
        },
      },
      borderColor: "unset!important",
      border: "2px solid !important",
      backgroundColor: theme.palette.primary.light + "!important",
      color: theme.palette.primary.contrastText + "!important",
      boxShadow:
          "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)!important",
    },
    startIcon: {
      fontSize: "2rem!important",
    },
  },
  MuiButtonBase:{
    root:{
      marginBottom: '0px !important',
    }
  },
  MuiIconButton: {
    root: {
      "&:focus": {
        outline: "none !important",
      },
    },
  },
  MuiSvgIcon: {
    root: {
      //Thomas 28/06/21
      maxWidth: "63px",
      maxHeight: "63px",
      //maxWidth: '33px',
      //maxHeight: '24px',
      fontSize: "inherit",
      // Thomas 28/06/21
      //color:'#027e8c',
    },
  },
  MuiAccordion: {
    root: {
      "&:before": {
        backgroundColor: "unset",
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow:
          "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 1px 1px 0px 0px rgba(0,0,0,0.12)",
      //boxShadow:'none'
    },
    root: {
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
      // textAlign:'center',
      //padding: '10px',
      //marginTop: '15px'
    },
  },
  MuiAvatar: {
    root: {
      margin: theme.spacing(1),
      width: "60px",
      height: "60px",
    },
    colorDefault: {
      // Thomas 28/06/21
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  MuiTypography:{
    root:{
      '&.New':{
        color:theme.DoH.color.conditional
      },
      '&.OnHold':{
        color:theme.DoH.color.fail
      },
      '&.InProgress':{
        color:theme.DoH.color.exemption
      },
      '&.Closed':{
        color:theme.DoH.color.pass
      }
    },
  },
};


export const DoHScreeningTheme = theme;
