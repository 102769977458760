import React from 'react'
import {withStyles} from "@material-ui/core/styles";
import {Box, IconButton, Tooltip} from "@material-ui/core";

const StyledIconButton = withStyles((theme) => ({
    root: {
        fontSize: '3rem',
        padding: '5px!important',
        color: theme.palette.primary.light
    },
    label:{
        '& a':{
            color: theme.palette.primary.light + '!important'
        }
    }
    
}))(IconButton);

// @ts-ignore
export const AppBarIconButton = ({children, title, ...props}) => (
    <Tooltip title={title}>
        <StyledIconButton {...props}>
            {children}
        </StyledIconButton>
    </Tooltip>

)