import { FormControl, useControlStateVersion } from "@react-typed-forms/core";
import { TextField } from "@material-ui/core";
import { ReactElement } from "react";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";

export type FAutocompleteProps<T> = {
  query: FormControl<string>;
  state: FormControl<T | undefined>;
  label?: string;
} & Omit<AutocompleteProps<T, false, false, false>, "renderInput">;

export function FSelectAutocomplete(
  props: FAutocompleteProps<string>
): ReactElement;

export function FSelectAutocomplete<T>(
  props: FAutocompleteProps<T> & { getOptionLabel: (t: T) => string }
): ReactElement;

export function FSelectAutocomplete({
  state,
  query,
  label,
  getOptionLabel,
  ...others
}: FAutocompleteProps<any>) {
  useControlStateVersion(state);
  useControlStateVersion(query);
  return (
    <Autocomplete<any, false, false, false>
      {...others}
      onChange={(event: any, newValue: any, reason) => {
        if (reason === "select-option") {
          state.setValue(newValue);
          query.setValue(getOptionLabel?.(newValue) ?? newValue.toString());
        }
      }}
      inputValue={query.value || ""}
      onInputChange={(_, val, reason) => {
        if (reason === "input" || reason === "clear") {
          query.setValue(val);
        }
      }}
      getOptionLabel={getOptionLabel}
      renderInput={(p) => {
        return (
          <TextField
            {...p}
            label={label}
            ref={(e) => (state.element = e)}
            error={Boolean(state.error)}
            helperText={state.error}
            variant="outlined"
          />
        );
      }}
    />
  );
}
