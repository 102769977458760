import React, { useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PhoneIcon from "@material-ui/icons/Phone";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CommentIcon from "@material-ui/icons/Comment";
import {
  AuditTimeLine,
  CommentsTimeLine,
  QuestionTimeLine,
  SkeletonTimeLine,
} from "../../../../components/muiCore/TimeLine";
import * as api from "../../../../data/api";
import { Context } from "../../../../state/Store";
import { useSignalR } from "../../../utils/signalr";
import { HubConnection } from "@microsoft/signalr";
import { QueueWorkItemFiles } from "./QueueWorkItemFiles";
import {
  QuesitonnaireResponseListViewModel,
  QuestionnaireClient,
  QuestionnaireResponseViewModel,
  QuestionnairesClient,
  QuestionResponseViewModel,
  QueueClient,
  QueueWorkItemViewModel,
} from "../../../../common/client";
import { Button, Menu, MenuItem } from "@material-ui/core";
import {
  Page,
  PDFDownloadLink,
  View,
  Text,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Questionnaire } from "../../../../components/core/QuestionSlider/Questionnaire";
import { IQuestion } from "../../../../models/Question";
import { useApiClient } from "../../../../common/apiclients";
import { Question } from "../../../../components/core/QuestionSlider/QuestionItem";
import { GenerateQuestionnairePDF } from "../../../../components/core/PDFGenerator/GeneratePDF";
import {
  QueueWorkItemQuestions,
  QueueWorkItemResponses,
} from "./QueueWorkItemResponses";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ overflowY: "hidden" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 0 0 24px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",

    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    "&.MuiBox-root": {
      padding: "24px 0 0 24px",
    },
  },
  speedDial: {
    //position: "absolute",
    //bottom: theme.spacing(2),
    //right: theme.spacing(2),
  },
}));

export const QueueWorkItemTabs = (props: {
  queueId: string;
  queueWorkItem: QueueWorkItemViewModel;
  onEdit: (questionnaireResponseId: string) => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  //GLOBAL STATE
  const { state, dispatch } = useContext(Context);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [notes, setNotes] = React.useState([]);
  const [auditEvents, setAuditEvents] = React.useState([]);

  const [updateWorkItemNotes, setUpdateWorkItemNotes] = useState(false);
  const [srQWI, setsrQWI] = useState<any>();
  const [questionResponses, setQuestionResponses] = React.useState<
    QuestionResponseViewModel[]
  >([]);

  // const handleSelection(id: string) = (id: string) => {
  //   setSelectedQuestionnaireId(id);
  // };

  const hubConn = useSignalR(
    "/api/hub/queue",
    state.user?.token,
    (conn: HubConnection) => {
      conn.on("queueitemnote-updated", (workItem: any) => {
        if (workItem) {
          if (workItem.id == props.queueWorkItem.id) {
            setsrQWI(workItem);
            setUpdateWorkItemNotes(true);
          }
        }
      });
    }
  );

  useEffect(() => {
    setLoading(true);
  }, [props.queueWorkItem]);

  useEffect(() => {
    if (props.queueWorkItem) {
      setLoading(true);

      switch (value) {
        case 0:
          // api
          //   .getQueueWorkItemNotes(
          //     props.queueId,
          //     props.queueWorkItem.workItem.id!,
          //     state.user?.token
          //   )
          //   .then((notes: any) => {
          //     setNotes(notes);
          //     setAuditEvents([]);
          //     setQuestionResponses([]);
          //   });
          break;
        case 1:
          break;
        case 2:
          // api
          //   .getQueueWorkItemAuditEvents(
          //     props.queueId,
          //     props.queueWorkItem.workItem.id!,
          //     state.user?.token
          //   )
          //   .then((auditEvents: any) => {
          //     setNotes([]);
          //     setQuestionResponses([]);
          //     setAuditEvents(auditEvents);
          //   });
          break;
      }
    }
  }, [value, props.queueWorkItem]);

  useEffect(() => {
    if (notes) {
      setLoading(false);
    }
  }, [notes]);
  useEffect(() => {
    if (auditEvents) {
      setLoading(false);
    }
  }, [auditEvents]);
  useEffect(() => {
    if (questionResponses) {
      setLoading(false);
    }
  }, [questionResponses]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (updateWorkItemNotes && srQWI) {
      if (updateWorkItemNotes && srQWI) {
        setNotes(srQWI.notes);
        setUpdateWorkItemNotes(false);
      }
    }
  }, [updateWorkItemNotes]);

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          {/* <Tab icon={<CommentIcon />} label="Notes" {...a11yProps(0)} /> */}
          <Tab
            icon={<QuestionAnswerIcon />}
            label="Questions"
            {...a11yProps(0)}
          />
          {/* <Tab icon={<ListAltIcon />} label="Audit" {...a11yProps(2)} /> */}
          <Tab icon={<ListAltIcon />} label="Documents" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
          {loading ? (
            <SkeletonTimeLine count={5} />
          ) : (
            notes.length > 0 && <CommentsTimeLine comments={notes} />
          )}
        </TabPanel> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <QueueWorkItemQuestions
            showing={value === 0}
            queueWorkItem={props.queueWorkItem}
            queueId={props.queueId}
            onEdit={props.onEdit}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          {loading ? (
            <SkeletonTimeLine count={5} />
          ) : (
            auditEvents.length > 0 && (
              <AuditTimeLine auditEvents={auditEvents} />
            )
          )}
        </TabPanel> */}
        <TabPanel value={value} index={1} dir={theme.direction}> 
          <QueueWorkItemFiles
            queueId={props.queueId}
            workItemId={props.queueWorkItem.workItem.id!}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
