import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FileUpload } from "../File/FileUpload";
import { FormControl } from "@react-typed-forms/core";
import { QueueClient, WorkItemFileViewModel, ApiException, QuestionnaireForwardingViewModel, } from "../../../common/client";
import { useApiClient } from "../../../common/apiclients";
import { Divider, makeStyles, ThemeProvider } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { CommonBTN } from "../Buttons/CommonBTN";

type ItemFileModel = WorkItemFileViewModel & {
    action?: boolean;
  };

const useStyles = makeStyles((theme) => ({
    text: {
        paddingLeft: "40px", 
        paddingRight: "40px" 
    },
    
}));

const QuestionItemUploadFile = ({
  control,
  readonly,
  questionnaireInfo
}: {
  control: FormControl<string | undefined>;
  readonly?: boolean;
  questionnaireInfo?: QuestionnaireForwardingViewModel;
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const queueClient = useApiClient(QueueClient);
  const [uploadFiles, setUploadFiles] = useState<ItemFileModel[]>();
  const queueId = questionnaireInfo ? questionnaireInfo!.queueId : "";
  const workItemId = questionnaireInfo ? questionnaireInfo!.queueWorkItemId : "";

  console.log(questionnaireInfo)

  const sendFiles = (files: File[]) => {
    var fileNames = control.value ? control.value : "";
    files.forEach((file: File) => {
      fileNames = fileNames + file.name + ", ";
    });
    control.setValue(fileNames);
    uploadFileList(files);
  };

  const removeFile = (fileName: string) => {
    setFiles(files.filter((x: File) => x.name !== fileName));
  };

  function uploadFileList(files?: File[] | null) {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      uploadOne(f);
    }

    async function uploadOne(f: File) {
      const uploadingFile: ItemFileModel = {
        action: true,
        filename: f.name,
        length: f.size,
        global: false,
        queueId: queueId,
      };
      setUploadFiles((uploadFiles) => [...(uploadFiles ?? []), uploadingFile]);
      try {
        const newFile = await queueClient.addFile(
          queueId,
          workItemId,
          f.name,
          false,
          f
        );
        updateFile(uploadingFile, () => newFile);
      } catch (e) {
        if (ApiException.isApiException(e)) {
          if (e.status === 413) {
            console.log("File too large");
          }
        }
        console.log(e);
      }
    }
  }

  function updateFile(
    f: WorkItemFileViewModel,
    update: (f: WorkItemFileViewModel) => WorkItemFileViewModel
  ) {
   // setFiles((uploadFiles) => uploadFiles?.map((fi) => (fi === f ? update(f) : fi)));
  }

  return (
    <div>
      <FileUpload
        title="Drag and Drop File or Click Inside this Box to Selete a File"
        uploadFile={(x) => setFiles([...files, x])}
      />
      {files.length > 0 && (
        <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
          <div style={{ display: "inline" }}>
            <h3 style={{ display: "inline" }}>
              Files to be uploaded:{" "}
              <h6 style={{ display: "inline" }}>(click name to remove file)</h6>
            </h3>
          </div>
          {files.map((file: File) => (
            <div onClick={() => removeFile(file.name)}>
              <h3>{file.name}</h3>

              <Divider />
            </div>
          ))}
          <div style={{ margin: "10px", marginLeft: "10px" }}>
            <CommonBTN
              maxWidth="100%"
              BtnText="Upload Files"
              onClick={() => sendFiles(files)}
            />
          </div>
        </div>
      )}
      {control.value && (
        <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
            <Divider />
          <h3>Files Successfully Uploaded:</h3>
          {control.value?.split(",").map((fileName: string) => (
            <h4>{fileName}</h4>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionItemUploadFile;
