import { createRenderer } from "@react-typed-forms/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { TextField } from "@material-ui/core";
import React from "react";

export const FTextField = createRenderer<
  string | undefined | null,
  TextFieldProps
>(({ state, helperText, ...props }, { errorText, value, ...formProps }) => (
  <TextField
    {...formProps}
    variant="outlined"
    value={value ? value : ""}
    error={Boolean(errorText)}
    {...props}
    helperText={errorText ?? helperText}
  />
));

export const FDateField = createRenderer<
  string | undefined | null,
  TextFieldProps
>(({ state, helperText, ...props }, { errorText, value, ...formProps }) => (
  <TextField
    {...formProps}
    type="date"
    variant="outlined"
    value={!value ? "" : value}
    error={Boolean(errorText)}
    {...props}
    helperText={errorText ?? helperText}
    InputLabelProps={{ shrink: true }}
    inputProps={{max: "9999-12-12"}}
    onChange={(e) => {
      const val = e.target.value;
      state.setValue(!val ? undefined : val);
    }}
  />
));

export const FSelectField = createRenderer<
  string | undefined | null,
  TextFieldProps
>(({ state, helperText, ...props }, { errorText, value, ...formProps }) => (
  <TextField
    {...formProps}
    variant="outlined"
    select
    value={value ? value : ""}
    error={Boolean(errorText)}
    {...props}
    helperText={errorText ?? helperText}
    SelectProps={{ displayEmpty: true }}
    InputLabelProps={{ shrink: true }}
  />
));
