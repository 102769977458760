import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Question } from "./QuestionItem";
import {
  QuestionChoice,
  QuestionChoiceViewModel,
} from "../../../common/client";
import { QuestionItemNavProps } from "./Questionnaire";
import { questionVisibleForQueue } from "../Questionnaire";

export const QuestionItemStepper = ({
  questions: qs,
  controls,
  navigate,
  queueId,
  settings,
  questionnaireInfo,
  currentQuestion: question,
  ...props
}: QuestionItemNavProps) => {
  const questions = useMemo(
    () => qs.filter((q) => questionVisibleForQueue(q, queueId)),
    [qs, queueId]
  );
  const currentIndex = questions.indexOf(question);

  function getPreviousQuestionIndex() {
    return currentIndex - 1;
  }
  function getNextQuestionIndex(selectedChoice: QuestionChoiceViewModel) {
    let nextQuestionIndex = -1;
    if (question.responseMappings) {
      if (selectedChoice.id) {
        //find nextQuestionId of question response mappings matching choice Id
        const mapping = question.responseMappings.find(
          (m) => m.choiceId === selectedChoice.id
        );
        const nextQuestionId = mapping?.nextQuestionId;
        if (nextQuestionId) {
          //get question index matching these id
          nextQuestionIndex = questions.findIndex(
            (question) => question.id === nextQuestionId
          );
          if (nextQuestionIndex >= 0) {
            return nextQuestionIndex;
          }
        }
      }
    }

    return nextQuestionIndex;
  }
  const onChoiceSelected = (direction: "left" | "right") => {
    const selectedChoiceId = controls.fields[question.id].fields.choiceId.value;
    const nextQuestionIndex =
      direction === "left"
        ? getPreviousQuestionIndex()
        : getNextQuestionIndex(
            question.choices.find((c) => c.id === selectedChoiceId)!
          );

    navigate(
      nextQuestionIndex >= 0 ? questions[nextQuestionIndex] : undefined,
      direction === "right"
    );
  };

  return (
    <Box
      display="flex"
      my={2}
      style={{
        maxWidth: props.maxWidth ?? "100%",
        ...props.style,
        margin: "auto",
      }}
      justifyContent="stretch"
    >
      <Box width="30%">
        <Stepper activeStep={currentIndex} nonLinear orientation="vertical">
          {questions.map((q, i) => (
            <Step key={q.id} completed={false}>
              <StepButton
                color="inherit"
                onClick={() => navigate(questions[i], true)}
              >
                <StepLabel error={props.invalidQuestions?.includes(q.id)}>
                  <Typography variant={"subtitle2"}>{q.heading}</Typography>
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box width="70%" padding={"0 10px"} margin={"0 auto"}>
        <Question
          readonly={false}
          question={question}
          queueId={queueId}
          settings={settings}
          questionnaireInfo={questionnaireInfo}
          control={controls.fields[question.id]}
          currentIndex={currentIndex}
          onBack={() => {
            onChoiceSelected("left");
          }}
          onResult={() => {
            onChoiceSelected("right");
          }}
        />
      </Box>
    </Box>
  );
};
