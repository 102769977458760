import { useApiClient } from "../../../../common/apiclients";
import {
  BasicQuestionnaireViewModel,
  QuestionnairesClient,
} from "../../../../common/client";
import { useEffect, useState } from "react";
import { Link } from "@material-ui/core";
import { questionnaireHref } from "../../Routes";

export function QuestionnaireList() {
  const client = useApiClient(QuestionnairesClient);
  const [questionnaires, setQuestionnaires] =
    useState<BasicQuestionnaireViewModel[]>();

  useEffect(() => {
    loadQuestionnaires();
  }, []);

  return questionnaires ? showQuestionnaires(questionnaires) : <div />;

  function showQuestionnaires(list: BasicQuestionnaireViewModel[]) {
    return (
      <ul>
        {list.map((q) => (
          <li>
            <Link href={questionnaireHref(q.id)}>{q.name}</Link>
          </li>
        ))}
      </ul>
    );
  }

  async function loadQuestionnaires() {
    setQuestionnaires(await client.getQuestionnaires());
  }
}
