import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

export const AppBar = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "10px",
    marginBottom: "10px",
    minHeight: "60px",
  },
}))(Paper);
export const AppBarDivider = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2),
  },
}))(Divider);

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    //paper:theme.DoH.TreeView.paper,
    header: theme.DoH.TreeView.header,
    root: theme.DoH.TreeView.TreeItem.root,
    content: theme.DoH.TreeView.TreeItem.content,
    group: theme.DoH.TreeView.TreeItem.group,
    expanded: {},
    selected: theme.DoH.TreeView.TreeItem.selected,
    label: theme.DoH.TreeView.TreeItem.label,
    labelRoot: theme.DoH.TreeView.TreeItem.labelRoot,
    labelIcon: theme.DoH.TreeView.TreeItem.labelIcon,
    labelText: theme.DoH.TreeView.TreeItem.labelText,
   
      ".MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "45px",
        height: "45px!important",
        //min-height: "50px!important"
      }
    ,
  })
);

const useGeneralCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: "5px 5px 5px 10px",
      borderBottom: "1px inset",
      marginBottom: "10px",
    },
    accordionHeader: {
      padding: "5px 5px 5px 10px",
      borderBottom: "1px inset",
      marginBottom: "10px",
      marginLeft: "-15px",
    },
   
    root: {
      // same as day pass card
      height: 80,
      flexGrow: 1,
      maxWidth: 500,
    },
    content: {
      alignSelf: "center",
      textAlign: "center",
      marginTop: "12px",
    },
  })
);

interface IGeneralCard {
  header: string;
  content: string;
}

export const GeneralCard = ({ header, content }: IGeneralCard) => {
  const classes = useGeneralCardStyles();

  return (
    <>
      <Paper elevation={2} className={classes.root}>
        <Typography variant="body1" className={classes.header}>
          {header}
        </Typography>
        <Typography variant="body2" className={classes.content}>
          {content}
        </Typography>
      </Paper>
    </>
  );
};

export const CbxTree = (props: any) => {
  const classes = useTreeItemStyles();
  const [checked, setChecked] = useState<string[]>(
    props.checked ? props.checked : []
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const [nodes, setNodes] = useState([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const accordion = props.accordion ? true: false;
  const flatten = (data: any) =>
    // @ts-ignore
    // prettier-ignore
    data.reduce((arr: any,{ id,name,parentLocationId, regionId,accessible,deletedAt,children = [],}) =>
        arr.concat([{id,name,parentLocationId,regionId,accessible,deletedAt,},],flatten(children)),[]);

  const flattenGeneric = (data: any) =>
    // @ts-ignore
    // prettier-ignore
    data.reduce(
      (arr: any, { id, name, parentId, children = [] }: any) =>
        arr.concat(
          [
            {
              id,
              name,
              parentId,
            },
          ],
          children?flattenGeneric(children):[]
        ),
      []
    );

  const genericListToTree = (arr: any = []) => {
    let map: any = {},
      node: any,
      res: any = [],
      i: number;

    for (i = 0; i < arr.length; i++) {
      arr[i] = { ...arr[i], uniqueId: i };
      let index = arr.findIndex((a: any) => a.id === arr[i].id);
      // @ts-ignore
      map[index] = arr[i].id;

      let hasChildren =
        arr.filter((a: any) => a.parentId === arr[i].id).length > 0;
      // @ts-ignore
      if (hasChildren) {
        arr[index].children = [];
      }
    }
    for (i = 0; i < arr.length; i += 1) {
      const className = classes.labelText;

      const { id: value, name: label, children } = arr[i];
      node = { value, label, children, className };
      // @ts-ignore
      if (arr[i].parentId) {
        // @ts-ignore
        let index: number = arr.findIndex((a: any) => a.id === arr[i].parentId);

        // if (index === -1) {
        //     index = arr.findIndex((a: any) => a.id === arr[i].regionId)
        // }

        if(arr[index])
          arr[index].children.push(node);
        
      } else {
        res.push(node);
      }
    }

    return res;
  };

  const listToTree = (arr: any = []) => {
    let node: any,
      res: any = [],
      i: number;

    for (i = 0; i < arr.length; i++) {
      let hasChildren =
        arr.filter(
          (a: any) =>
            (a.parentLocationId === arr[i].id || a.regionId === arr[i].id) &&
            a.deletedAt === null
        ).length > 0;

      if (hasChildren) {
        arr[i].children = [];
      }
    }
    for (i = 0; i < arr.length; i++) {
      let className = classes.labelText;
      const { id: value, name: label, children } = arr[i];

      node = { value, label, children, className };
      // @ts-ignore
      if (
        (arr[i].parentLocationId || arr[i].regionId) &&
        arr[i].regionId != "00000000-0000-0000-0000-000000000000"
      ) {
        // @ts-ignore
        let index: number = arr.findIndex(
          (a: any) => a.id === arr[i].parentLocationId
        );

        if (index === -1) {
          index = arr.findIndex((a: any) => a.id === arr[i].regionId);
        }

        if (arr[i].accessible && !arr[i].deletedAt) {
          arr[index].children.push(node);
        }
      } else {
        res.push(node);
      }
    }
    return res;
  };

  useEffect(() => {
    if (props.nodes) {
      if (props.generic) {
        setNodes(genericListToTree(flattenGeneric(props.nodes)));
      } else {
        // sort the location
        setNodes(
          listToTree(
            flatten(props.nodes).sort(function (a: any, b: any) {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) {
                return -1;
              }
              if (x > y) {
                return 1;
              }
              return 0;
            })
          )
        );
      }
    }
  }, [props.nodes]);

  useEffect(() => {
    if (isLoaded) {
      props.onChecked(checked);
    } else {
      setIsLoaded(true);
    }
  }, [checked]);

  const icons = {
    check: (
      <span>
        <CheckBoxIcon className={classes.labelIcon} />
      </span>
    ),
    uncheck: (
      <span>
        <CheckBoxOutlineBlankIcon className={classes.labelIcon} />
      </span>
    ),
    halfCheck: (
      <span>
        <CheckBoxIcon className={clsx(classes.labelIcon)} />
      </span>
    ),
    expandClose: (
      <span>
        <ChevronRightIcon className={classes.labelIcon} />
      </span>
    ),
    expandOpen: (
      <span>
        <KeyboardArrowDownIcon className={classes.labelIcon} />
      </span>
    ),
    expandAll: (
      <span
        className="rct-icon rct-icon-expand-all"
        style={{ display: "none" }}
      />
    ),
    collapseAll: (
      <span
        className="rct-icon rct-icon-collapse-all"
        style={{ display: "none" }}
      />
    ),
    parentClose: (
      <span
        className="rct-icon rct-icon-parent-close"
        style={{ display: "none", paddingRight: "0px" }}
      />
    ),
    parentOpen: (
      <span
        className="rct-icon rct-icon-parent-open"
        style={{ display: "none", paddingRight: "0px" }}
      />
    ),
    leaf: (
      <span
        className="rct-icon rct-icon-leaf"
        style={{ display: "none", paddingRight: "0px" }}
      />
    ),
  };

  return (
    <>
    {!accordion ? (
      <>
      {nodes.length === 0 ? (
        <GeneralCard header={props.header} content={props.notFoundContent} />
      ) : (
        <Paper elevation={2} className={classes.root}>
          <Typography className={classes.header}>{props.header}</Typography>
          <CheckboxTree
            nodes={props.nodes}
            checkModel={"all"}
            checked={checked}
            expanded={expanded}
            disabled={!!props.disabled}
            onCheck={(selected) => {
              if (!props.multiSelect) {
                let difference = selected.filter((x) => !checked.includes(x));
                setChecked(difference);
              } else {
                setChecked(selected);
              }
            }}
            onExpand={setExpanded}
            icons={icons}
          />
        </Paper>
      )}
      </>
      ) : (
        <>
         {nodes.length === 0 ? (
        <GeneralCard header={props.header} content={props.notFoundContent} />
      ) : (
        <Paper elevation={2} className={classes.root}>
          <Accordion style={{marginBottom: "-10px"}} >
            <div style={{height: "50px"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes[".MuiAccordionSummary-root.Mui-expanded"]}>
              <Typography className={classes.header} ><div style={{marginLeft:"-15px"}}>{props.header}</div></Typography>
            </AccordionSummary>
            </div>
            <AccordionDetails style={{marginTop:"0px"}}>
              <div style={{marginLeft:"-15px"}}>
              <CheckboxTree
                nodes={props.nodes}
                checkModel={"all"}
                checked={checked}
                expanded={expanded}
                disabled={!!props.disabled}
                onCheck={(selected) => {
                  if (!props.multiSelect) {
                   let difference = selected.filter((x) => !checked.includes(x));
                    setChecked(difference);
                  } else {
                    setChecked(selected);
                  }
                }}
                onExpand={setExpanded}
                icons={icons}
              />
              </div>
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
        </>
      )}
    </>
  );
};

export default CbxTree;
