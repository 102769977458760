import { Satellite } from "@material-ui/icons";
import StateModel from "./State.model";
import {IDispatchAction} from "./Store";

export const Reducer = (state: StateModel, action: IDispatchAction) => {
    
    switch (action.type){
        case 'SET_USERINFO':
            if(action.payload?.token && action.payload?.rememberMe)
            {
                localStorage.setItem('user:authtoken', action.payload?.token);
            }
            return {
                ...state,
                user:action.payload,
            };
        case 'SET_TOKEN':
            localStorage.setItem('user:authtoken', action.payload?.token);
            return {
                ...state,
                user: action.payload,
            };
        case 'LOGOUT_USER':
            localStorage.removeItem('user:authtoken');
            localStorage.removeItem('user:locations');
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};