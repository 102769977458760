import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import appBanner from "../assets/images/banner_covid.svg";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const questionStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "10px",
        fontSize: "18px",
      },
    },
  })
);

const sharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    neg_mr_15: {
      marginRight: "-15px",
    },
    ml_15: {
      marginLeft: "15px",
    },
  })
);

const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerPlaceHolder: {
      [theme.breakpoints.down("md")]: {
        height: "50px",
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //flex: 'none',
      display: "flex",
      minWidth: "100vw",
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        top: 0,
        zIndex: 20,
      },
    },

    paper: {
      //width:'100vw',
      minWidth: "100vw",
      backgroundImage: `url(${appBanner})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      [theme.breakpoints.down("sm")]: {
        //paddingTop:'40px',
        height: "50px",
      },
    },
    tasGovLogo: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      width: "128px",
      filter: "invert(100%)",
      margin: "10px 0 10px 10px",
      float: "right",
    },
    mgAuto: {
      margin: "auto",
    },
    img: {
      height: "5.5rem",
    },
    heading: {
      opacity: "80%",
      color: theme.palette.primary.contrastText,
      WebkitFontSmoothing: "antialiased",
      fontWeight: 500,
      position: "relative",
      marginTop: "2px!Important",
      "& p": {
        [theme.breakpoints.up("md")]: {
          fontSize: "2.5rem!important",
          paddingTop: "50px",
        },
      },
      "& span": {
        [theme.breakpoints.down("sm")]: {
          //bottom: '-50px',
          //left: '10px',
          //position: 'absolute',

          fontSize: "2rem!important",
        },

        [theme.breakpoints.up("md")]: {
          marginLeft: "40px",
        },
      },
    },
    drawerMenuIcon: {
      padding: "unset!important",
      fontSize: "2.5rem",
      color: theme.palette.primary.contrastText,
      paddingLeft: "15px!important",
      paddingTop: "5px!important",
      opacity: " 80%",
      "&:focus": {
        outline: "none !important",
      },
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    version: {
      fontSize: "0.8em!important",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    text: {
      textAlign: "justify",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem!important",
        paddingBottom: "10px",
      },
    },
    noBg: {
      backgroundColor: "unset",
    },
    noHover: {
      "&:hover": {
        backgroundColor: "inherit",
        cursor: "inherit",
      },
    },
    alignItemsLeft: {
      alignItems: "flex-start!important",
    },
    noBorder: {
      border: "none",
    },
    noPadding: {
      padding: 0,
    },
    mt_15: {
      marginTop: "15px",
    },
    mb_15: {
      marginBottom: "15px",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      width: "60px",
      height: "60px",
      // Thomas added
      [theme.breakpoints.down('sm')]: {
        width: "35px",
        height: "35px",
      }
    },
    heading2: {
      textAlign: "left",
      paddingBottom: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem!important",
        paddingBottom: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.2rem!important",
        fontWeight: "600!important",
        lineHeight: " 1.4375rem",
        letterSpacing: "-.01875rem",
      },
    },
    icon: {
      color: theme.palette.primary.contrastText,
      maxWidth: "unset",
      maxHeight: "unset",
      [theme.breakpoints.up("sm")]: {
        fontSize: "3rem!important",
        margin: "10pxImportant",
      },
      fontSize: "3rem!important",
      margin: "10pxImportant",
    },
    tasLogo: {
      fontSize: "4.2em!important",
      marginTop: "15px",
    },
    bgc: {
      backgroundColor: theme.palette.primary.main,
    },
    welcome: {
      fontSize: "1.5em!important",
    },
    innerHeading: {
      fontSize: "1.2rem!important",
      fontWeight: "bolder",
      paddingTop: "5px",
    },
    lowerLatin: {
      listStyleType: "lower-latin",
      margin: "0px",
      "& li::marker": {
        fontWeight: "bolder",
      },
    },
    iconSmall: {
      color: theme.palette.primary.main,
      marginBottom: "4px",
      marginRight: "4px",
    },
  })
);

const dayPassStyles = makeStyles((theme) => ({
  card: {
    border: "none",
    //padding:'1rem',
    borderRadius: 0,
    boxShadow: " 0 0 8px 0 rgba(0,0,0,0.3)",
    backgroundColor: "#35353",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      marginBottom: "70px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
  },
  cardContent: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      margin: "unset",
    },
    "&:last-child": {
      padding: "unset",
    },
    "& .MuiList-root": {
      padding: "0px!important",
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    boxShadow:
      "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
    minHeight: "70px",
    fontWeight: 550,
    fontSize: theme.typography.fontSize,
    padding: "0.5em 0 0.5em 0.25em",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 0 0.5em 0.25em",
      // position: 'fixed',
      // top:0,
      // zIndex:20
    },
    "& span": {},
  },
  exemption: {
    backgroundColor: theme.DoH.color.exemption,
  },
  failed: {
    backgroundColor: theme.DoH.color.fail,
  },
  conditional: {
    backgroundColor: theme.DoH.color.conditional,
  },
  cardHeaderText: {
    fontSize: "22px!important",
    color: theme.palette.primary.contrastText,
  },
  cardListItem: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    //boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    minHeight: "70px",
    fontWeight: 550,

    //fontSize: theme.typography.fontSize,
    //padding: '0.5em 0 0.5em 0.25em',
    display: "block",
    textAlign: "center",
    justifyContent: "center",
    // [theme.breakpoints.down("sm")]: {
    //     padding: '0.5em 0 0.5em 0.25em'
    //     // position: 'fixed',
    //     // top:0,
    //     // zIndex:20
    // }
  },
  cardListItemAlt: {
    //boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    minHeight: "70px",
    fontWeight: 550,
    textAlign: "justify",
    //fontSize: theme.typography.fontSize,
    //padding: '0.5em 0 0.5em 0.25em',
    display: "block",
    // [theme.breakpoints.down("sm")]: {
    //     padding: '0.5em 0 0.5em 0.25em'
    //     // position: 'fixed',
    //     // top:0,
    //     // zIndex:20
    // }
  },
  cardListItemText: {
    fontSize: "22px!important",
    textTransform: "uppercase",
  },
  textLeft: {
    textAlign: "justify",
  },
  cardListItemTextAlt: {
    fontSize: "16px!important",
    padding: "10px",
    textTransform: "unset",
  },
  icon: {
    // fontSize: '4rem!important',
    margin: "10pxImportant",
    color: theme.palette.primary.contrastText,
    maxWidth: "unset",
    maxHeight: "unset",
    fontSize: "6rem!important",
    //minWidth: '40px',
    [theme.breakpoints.up("sm")]: {
      // fontSize: '10rem!important',
      margin: "10pxImportant",
      fontWeight: 600,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: "60px",
    height: "60px",
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem!important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem!important",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#fcfcfc",
    marginTop: "15px",
    transition: "background-color 0.5s ease",
    //boxShadow: '0 0 8px 0 rgba(0,0,0,0.3)',
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  box: {
    [theme.breakpoints.down("sm")]: {
     // position: "fixed",
      //bottom: "0px",
      left: "0px",
      width: "100%",
      margin: "unset",
      maxWidth: "unset!important",
    },
    display: "flex!important",
    textAlign: "center",
    maxWidth: "660px", //Default width overriden by props.maxWidth
    margin: "10px auto",
  },
}));
export const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      //borderBottom: 0,
      //TODO: Still an issue here. Expanded is too large with this and without it's non-existent
      borderBottom: "1px solid rgba(0, 0, 0, .12)",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
export const AccordionSummary = withStyles({
  root: {
    //backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },

    paddingLeft: "25px!important",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  expandIcon: {
    fontSize: "2em",
  },
})(MuiAccordionSummary);
export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0px",
    display: "block",
  },
}))(MuiAccordionDetails);
export const Dialog = withStyles({})(MuiDialog);
export const DialogContent = withStyles({
  root: {
    padding: "0px",
  },
  dividers: {
    padding: "0px",
  },
})(MuiDialogContent);
export const DialogActions = withStyles({})(MuiDialogActions);
export const DialogTitle = withStyles({})(MuiDialogTitle);

export { useStyles, dayPassStyles, questionStyles, headerStyles, sharedStyles };
