import React, { useRef, useState } from "react";
import { Drawer } from "../../../../components/core/Manager/SubComponents/Drawer";
import ManagerForm from "../../../../components/core/Manager/SubComponents/ManagerForm";
import { Manager } from "../../../../components/core/Manager/Manager";
import InputDialog from "../../../../components/core/dialogs/InputDialog";
import { useSnackbar } from "notistack";

export const QueueAccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  // text in search bar
  const [searchBarText, setSearchBarText] = useState<string>("");
  // current items being displayed on the bottom right section => objects
  const [currentItems, setCurrentMembers] = useState<any[]>([]);
  // selected items on the bottom right section, a subset of currentItems
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  // selected nodes from the check box tree component => id
  const [selectedNode, setSelectedNode] = useState<any>();
  // teams => array of objects
  const [teams, setTeams] = useState<any[]>([]);

  const [editTeamNameOpen, setEditTeamNameOpen] = useState<boolean>(false);

  // team manager form
  const formDrawer = useRef<any>(null);

  let inputDialogData = {
    value: selectedNode
      ? teams.filter((x: any) => x.id === selectedNode)[0].name
      : "",
    title: "Edit",
    inputPlaceholder: "Team Name",
    open: editTeamNameOpen,
    onClose: () => {},
    onSubmit: () => {},
  };

  let AppBarComponents;
  let appBarRightComponents;
  let disableToolBar =
    !selectedNode ||
    !teams.find((x: any) => x.id === selectedNode).canManageTeamMembers;

  function onAddTeamFormSubmit() {}

  function treeDataMapper(teams: any) {
    return [];
  }

  function matchQuery(currentItems: any[]) {
    return [];
  }

  function onResultSelect() {}

  function onCheckBoxChecked() {}

  function onAdd() {}

  function onRemove() {}

  function onItemSearch(): Promise<any> {
    return Promise.resolve();
  }

  return (
    <>
      <Drawer
        ref={formDrawer}
        toolbar={true}
        anchor={"right"}
        title="Add a new team"
      >
        <ManagerForm onSucceed={onAddTeamFormSubmit} />
      </Drawer>
      <InputDialog {...inputDialogData} />
      <Manager
        name="Queues"
        appbarExtraComponents={AppBarComponents}
        appbarRightComponents={appBarRightComponents}
        disableToolBar={disableToolBar}
        treeData={treeDataMapper(teams)}
        currentItems={matchQuery(currentItems)}
        selectedItems={selectedItems}
        onResultSelect={onResultSelect}
        onSearchBarSearch={setSearchBarText}
        onCheckBoxChecked={onCheckBoxChecked}
        onAdd={onAdd}
        onRemove={onRemove}
        onItemSearch={onItemSearch}
      />
    </>
  );
};
