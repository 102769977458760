import { useSnackbar } from "notistack";
import React, { useState, useContext, useEffect } from "react";
import { Manager } from "../../../../components/core/Manager/Manager";
import { Context } from "../../../../state/Store";
import { useApiClient } from "../../../../common/apiclients";
import { PersonViewModel, QueueClient } from "../../../../common/client";
import { searchStaffMembers } from "../../../../data/api";

const QueueManager = () => {
  const { state } = useContext(Context);

  const queueClient = useApiClient(QueueClient);

  const [queues, setQueues] = useState<any[]>([]);
  const [currentQueue, setCurrentQueue] = useState<any>();
  const [currentItems, setCurrentItems] = useState<PersonViewModel[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    queueClient.getAllQueues().then((r) => {setQueues(r)});
  }, []);

  const queueDataMapper = (queues: any[]) => {
    return queues.map((x: any) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  };

  const onRemoveHandler = () => {
    const promises: Promise<any>[] = [];
    selectedItems.forEach((x: any) =>
      promises.push(
        queueClient
          .removeQueueMember(currentQueue, x)
          .then(() => enqueueSnackbar("Member removed", { variant: "success" }))
          .catch(() =>
            enqueueSnackbar("Failed to remove member", { variant: "error" })
          )
      )
    );

    Promise.all(promises).then(() => {
      queueClient.getQueueMembers(currentQueue).then((x) => {
        setCurrentItems(x);
        setSelectedItems([]);
      });
    });
  };

  const onResultSelectHandler = (result: any) => {
    if (selectedItems.includes(result)) {
      setSelectedItems([...selectedItems].filter((x: any) => x !== result));
    } else {
      setSelectedItems([...selectedItems, result]);
    }
  };

  const onCheckBoxCheckedHandler = (id: any[]) => {
    if (id[0]) {
      setCurrentQueue(id[0]);
      setSelectedItems([]);
      queueClient.getQueueMembers(id[0]).then((x) => setCurrentItems(x));
      // uncheck
    } else {
      setCurrentQueue(undefined);
      setCurrentItems([]);
      setSelectedItems([]);
    }
  };

  const onAddMemberHandler = (members: any[]) => {
    const promises: Promise<any>[] = [];
    members.forEach((x: any) =>
      promises.push(
        queueClient
          .addQueueMember(currentQueue, x.id)
          .then(() => enqueueSnackbar("Member added", { variant: "success" }))
          .catch(() =>
            enqueueSnackbar("Failed to add member", { variant: "error" })
          )
      )
    );

    Promise.all(promises).then(() => {
      queueClient.getQueueMembers(currentQueue).then((x) => {
        setCurrentItems(x);
      });
    });
  };

  const matchQuery = (currentArr: any[]) => {
    const matcher = (name: string, query: string) => {
      if (!query || query === "") return true;

      // remove space in name
      const newName = name.replace(/\s/g, "").toLowerCase();
      const newQuery = query.replace(/\s/g, "").toLowerCase();
      return newName.includes(newQuery);
    };

    return currentArr.filter((x: any) => matcher(x.name, searchQuery));
  };

  const onItemSearchHandler = (query: string) => {
    return searchStaffMembers(query, state!.user!.token);
  };

  return (
    <Manager
      name="Roles"
      treeData={queueDataMapper(queues)}
      onCheckBoxChecked={onCheckBoxCheckedHandler}
      currentItems={matchQuery(currentItems)}
      onResultSelect={onResultSelectHandler}
      selectedItems={selectedItems}
      onSearchBarSearch={setSearchQuery}
      onAdd={onAddMemberHandler}
      onRemove={onRemoveHandler}
      onItemSearch={onItemSearchHandler}
      disableToolBar={!currentQueue}
    />
  );
};

export default QueueManager;
