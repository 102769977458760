import { useEffect, useState } from "react";
import XLSX from "xlsx";
import {
  QueueClient,
  TeamViewModel,
  TeamClient,
} from "../../../../common/client";
import { useApiClient } from "../../../../common/apiclients";
import {
  Backdrop,
  CircularProgress,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export const UploadCSV = () => {
  const [csvFile, setCsvFile] = useState<File>();
  const [csvArray, setCsvArray] = useState<any>();
  const [missingData, setMissingData] = useState<string>("");
  const [uploadsSaved, setUploadsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const queueClient = useApiClient(QueueClient);
  const classes = useStyles();

  const upload = () => {
    setCsvArray(null)
    const file = csvFile;
    const reader = new FileReader();
    setLoading(true);
    reader.onload = async function (e) {
      const data = await file!.arrayBuffer();
      const workbook = XLSX.read(data);
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_csv(ws);

      processCSV(dataParse);
    };

    if (csvFile !== undefined) {
      reader.readAsText(file!);
    }
  };

  const processCSV = (str: any, delimiter = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    if (JSON.stringify(headers) !== JSON.stringify(['Date', 'Site', 'Building', 'Room', 'First Name', 'Last Name', 'Mobile Phone', 'Email Address', 'Department', 'Time In', 'Time Out', 'Activity Attended'])){
      setMissingData("Wrong Excel File");
      setLoading(false);
    } else {
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const newArray = rows.map((row: string) => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      const eachObject = headers.reduce(
        (obj: any, header: string, i: number) => {
          obj[header] = values[i];
          return obj;
        },
        {}
      );
      return eachObject;
    });

    setCsvArray(newArray.filter((x: any) => x.Date !== ""));
    filterAndSendData(newArray.filter((x: any) => x.Date !== ""));
  }
  };

  const filterAndSendData = (csvArray: any) => {
    if (csvArray !== undefined) {
      var data = csvArray.map((x: any) => {
        x["First Name"] = x["First Name"] === "" ? "Unknown" : x["First Name"];
        x["Last Name"] = x["Last Name"] === "" ? "Unknown" : x["Last Name"];
        x["Mobile Phone"] =
          x["Mobile Phone"] === "" ? "Unknown" : x["Mobile Phone"];

       

          if (x["Time In"].length > 0 && !x["Time In"].includes(":")) {
            var minutes = x["Time In"].slice(-2);
            var hours = x["Time In"].slice(0, -2);
            x["Time In"] = hours + ":" + minutes;
          }

          if (x["Time Out"].length > 0 && !x["Time Out"].includes(":")) {
            var minutes = x["Time Out"].slice(-2);
            var hours = x["Time Out"].slice(0, -2);
            x["Time Out"] = hours + ":" + minutes;
          }

          if (x['Time In'].split(":").length === 2 ) {
            x['Time In'] = x["Time In"] + ":00";
          }
          
  
          if (x['Time Out'].split(":").length === 2 ) {
            x["Time Out"] = x["Time Out"] + ":00";
          }

        if (x["Time In"] === "") {
          x["Time In"] = "00:00:01";
        }

        if (x["Time Out"] === "") {
          const [hours, minutes, seconds] = x["Time In"].split(":");
          if (Number(hours) < 23) {
            x["Time Out"] = `${Number(hours) + 1}:${minutes}:${seconds}`;
          } else {
            x["Time Out"] = "23:59:59";
          }
        }

        if (x.Department === "") {
          x.Department = "Visitor";
        }

      

        return {
          Site: x.Site,
          Building: x.Building,
          Room: x.Room,
          FirstName: x["First Name"],
          LastName: x["Last Name"],
          Department: x.Department,
          TimeIn: x["Time In"],
          TimeOut: x["Time Out"],
          ContactNumber: x["Mobile Phone"],
          Date: x.Date,
        };
      });
      queueClient.uploadManualCheckIns(data).then((res) => {
        setCsvFile(undefined);
        setLoading(false);
        setUploadsSaved(true);
      });
    }
  };

  return (
    <>
      <Paper elevation={2}>
        <div
          style={{
            marginBottom: "15px",
            paddingTop: "10px",
            paddingLeft: "10px",
          }}
        >
          Select File to Upload
        </div>
        <form
          id="csv-form"
          style={{ paddingLeft: "10px", paddingBottom: "10px" }}
        >
          <input
            type="file"
            accept=".xlsx"
            id="csvFile"
            onChange={(e) => {
              setCsvFile(e.target.files![0]);
            }}
          ></input>
          <br />
          <button
            onClick={(e) => {
              e.preventDefault();
              if (csvFile) upload();
            }}
          >
            Upload
          </button>
        </form>
      </Paper>

      {!csvArray && missingData && (<Paper elevation={2}><div style={{
                marginBottom: "15px",
                marginTop: "30px",
                paddingTop: "10px",
                paddingLeft: "10px",
                paddingBottom: "10px",
              }}>{missingData}</div></Paper>)}

      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {csvArray && !missingData && uploadsSaved && (
        <>
          <Paper elevation={2}>
            <div
              style={{
                marginBottom: "15px",
                marginTop: "30px",
                paddingTop: "10px",
                paddingLeft: "10px",
                paddingBottom: "10px",
              }}
            >
              Uploaded Data
            </div>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {Object.keys(csvArray![0]).map((key) => (
                      <TableCell align="right">{key}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {csvArray!.map((item: any) => (
                    <TableRow key={item.contact_phone}>
                      {Object.values(item).map((val: any) => (
                        <TableCell align="right">{val}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
};
